import { TDropdownAlignment } from '../Select.types';

export const findNextActiveOption = (
    index: number | null,
    optionsDisabled: boolean[],
    direction: 'up' | 'down',
): number | null => {
    if (optionsDisabled.every((optionDisaibled) => optionDisaibled)) return null;
    if (direction === 'down') {
        if (index === null) return optionsDisabled.findIndex((optionDisaibled) => !optionDisaibled);
        if (index < 0 || index > optionsDisabled.length) return null;
        if (index === optionsDisabled.length - 1) return index;

        const nextIndex: number = optionsDisabled.findIndex((optionDisaibled, ind) => index < ind && !optionDisaibled);
        if (nextIndex === -1) return index;
        return nextIndex;
    } else {
        if (index === null) return optionsDisabled.findLastIndex((optionDisaibled) => !optionDisaibled);
        if (index < 0 || index > optionsDisabled.length) return null;
        if (index === 0) return index;

        const nextIndex: number = optionsDisabled.findLastIndex(
            (optionDisaibled, ind) => index > ind && !optionDisaibled,
        );
        if (nextIndex === -1) return index;
        return nextIndex;
    }
};

export const calculateDropdownPosition = (
    selectBtnRectangle: DOMRect,
    selectBtnPaddingRight: number,
    windowInnerWidth: number,
    dropdownWidth: number = 0,
    dropdownAlignment?: TDropdownAlignment,
    iconBtnView?: boolean,
): { width: number; x: number } => {
    const { width: selectWidth, right, left } = selectBtnRectangle;

    const width: number = dropdownWidth || selectWidth;

    let isLeftAlignment: boolean = true;

    if (dropdownAlignment === 'auto') {
        const rightSpace: number = windowInnerWidth - right;
        isLeftAlignment = rightSpace > dropdownWidth && rightSpace > selectWidth;
    }

    let x: number =
        dropdownAlignment === 'right' || !isLeftAlignment
            ? left - dropdownWidth + selectWidth - (iconBtnView ? selectBtnPaddingRight : 0)
            : left;

    return { width, x };
};

type TGetDrobdownY = {
    selectBtnRectangle: DOMRect;
    innerHeight: number;
    drobdownHeight: number;
    iconBtnView: boolean;
};

export const getDrobdownTop = ({
    selectBtnRectangle,
    innerHeight,
    drobdownHeight,
    iconBtnView,
}: TGetDrobdownY): {
    top: number;
    isDownDirection: boolean;
} => {
    const coorY = innerHeight - (selectBtnRectangle?.bottom || 0) - (iconBtnView ? 0 : 4);
    const isDownDirection: boolean = coorY > drobdownHeight - 8;
    const drobdownY: number = isDownDirection
        ? coorY
        : coorY + drobdownHeight + (iconBtnView ? 0 : 8) + selectBtnRectangle.height;
    return { top: drobdownY, isDownDirection: isDownDirection };
};
