import { EdgeInstanceImpl } from '../../../models/bpm/bpm-model-impl';
import { EdgeArrow } from '../../../models/edge-arrow';
import { EdgeStyle } from '../../../models/edge-style';
import { MxCell } from '../../../mxgraph';
import {
    EDGE_DEFAULT_FONT_COLOR,
    EDGE_DEFAULT_STROKE_COLOR,
    LABEL_DEFAULT_FONT_COLOR,
    OBJECT_DEFAULT_FILL_COLOR,
} from '../../../mxgraph/bpmgraph.constants';
import { ComplexSymbolManager } from '../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { LocalesService } from '../../../services/LocalesService';
import { isSystemStyle } from '../../../services/SymbolsService';
import { getStyleValueByStyleKey } from '../../../utils/css.utils';
import messages from '../FormatPanel.messages';
import type { Symbol } from '@/serverapi/api';
import { Colors } from './format.utils.constants';

export const getEqualValue = (
    valueArr: (string | undefined)[] | (EdgeStyle | undefined)[] | (EdgeArrow | undefined)[] | boolean[],
): string | EdgeStyle | EdgeArrow | boolean | undefined => {
    const isEqualValue: boolean = valueArr.every((value) => value === valueArr[0]);

    return isEqualValue ? valueArr[0] : undefined;
};

export const getFullColorHex = (colors: (string | undefined)[]): (string | undefined)[] => {
    return colors.map((color) => {
        if (color?.length === 4 && color.match(/#[a-fA-F0-9]{3}\b/)) {
            return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
        }

        return color;
    });
};

export const getElementsFontColor = (elements: MxCell[]): string | undefined => {
    const colors: (string | undefined)[] = elements
        .map((cell) => {
            if (cell?.isEdge()) {
                return getStyleValueByStyleKey('fontColor', cell.getStyle()) || EDGE_DEFAULT_FONT_COLOR;
            }
            if (cell.getValue()?.type === 'object' || cell.getValue()?.type === 'label') {
                return (
                    getStyleValueByStyleKey('fontColor', ComplexSymbolManager.getCellForEdit(cell)?.getStyle()) ||
                    LABEL_DEFAULT_FONT_COLOR
                );
            }
            if (cell.getValue()?.type === 'shape') {
                return (
                    getStyleValueByStyleKey('fontColor', ComplexSymbolManager.getCellForEdit(cell)?.getStyle()) ||
                    LABEL_DEFAULT_FONT_COLOR
                );
            }
            return getStyleValueByStyleKey('fontColor', cell.getStyle());
        })
        .map((color) => color?.toLowerCase());

    return getEqualValue(getFullColorHex(colors)) as string | undefined;
};

export const getElementsFillingColor = (elements: MxCell[]): string | undefined => {
    const colors: (string | undefined)[] = elements
        .map((cell) => {
            return cell.isEdge()
                ? getStyleValueByStyleKey('strokeColor', cell.getStyle()) || EDGE_DEFAULT_STROKE_COLOR
                : (() => {
                      const color: string | undefined =
                          getStyleValueByStyleKey(
                              'fillColor',
                              (ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell).getStyle(),
                          ) || getFillColorFromSymbol(ComplexSymbolManager.getComplexSymbolInstance(cell)?.getSymbol());

                      return color === 'none' || color === 'default' ? OBJECT_DEFAULT_FILL_COLOR : color;
                  })();
        })
        .map((color) => color?.toLowerCase());

    return getEqualValue(getFullColorHex(colors)) as string | undefined;
};

export const getEdgesType = (cells: MxCell[]): string | undefined => {
    const edgeInstances: EdgeInstanceImpl[] = cells.map((edge) => edge.getValue());
    const edgeTypeIds: (string | undefined)[] = edgeInstances.map((edgeInstance) => edgeInstance.edgeTypeId);

    return getEqualValue(edgeTypeIds) as string | undefined;
};

type TLinesTypeCell = {
    edgeStyle: string | undefined;
    curved: string | undefined;
    isEdge: boolean;
};

export const getLinesType = (cells: MxCell[]): EdgeStyle | undefined => {
    const styles: TLinesTypeCell[] = cells.map((cell) => ({
        edgeStyle: getStyleValueByStyleKey('edgeStyle', cell?.getStyle()),
        curved: getStyleValueByStyleKey('curved', cell?.getStyle()),
        isEdge: cell.isEdge(),
    }));
    const styleNames: (EdgeStyle | undefined)[] = styles.map((style) => {
        if (!style.isEdge) return undefined;
        if (style.edgeStyle === undefined) return EdgeStyle.straight;
        if (style.edgeStyle === 'elbowEdgeStyle') return EdgeStyle.simple;
        if (style.edgeStyle === 'isometricEdgeStyle') return EdgeStyle.isometric;
        if (style.edgeStyle === 'orthogonalEdgeStyle' && style.curved === '1') return EdgeStyle.curved;
        if (style.edgeStyle === 'orthogonalEdgeStyle') return EdgeStyle.orthogonal;
        if (style.edgeStyle === 'entityRelationEdgeStyle') return EdgeStyle.entityRelation;

        return undefined;
    });

    return getEqualValue(styleNames) as EdgeStyle | undefined;
};

export const getEdgesArrow = (cells: MxCell[], arrow: 'startArrow' | 'endArrow'): EdgeArrow | undefined => {
    const edgeArrows: (EdgeArrow | undefined)[] = cells.map((cell) => {
        const isEdge: boolean = cell.isEdge();
        if (!isEdge) return undefined;
        const edgeArrow: string | undefined = getStyleValueByStyleKey(arrow, cell?.getStyle());
        if (!edgeArrow) {
            return arrow === 'endArrow' ? EdgeArrow.classic : EdgeArrow.none;
        }

        return EdgeArrow[edgeArrow];
    });

    return getEqualValue(edgeArrows) as EdgeArrow | undefined;
};

export const getCellsDirection = (cells: MxCell[]): boolean | undefined => {
    const directions: boolean[] = cells.map((cell) => {
        const horizontal: string | undefined = getStyleValueByStyleKey('horizontal', cell?.getStyle());

        return horizontal === 'false' ? false : true;
    });

    return getEqualValue(directions) as boolean | undefined;
};

export const filterSelectedCells = (selectedCells: MxCell[]) =>
    selectedCells.filter((cell) => !isSystemStyle(cell.style) && ComplexSymbolManager.isCellStyleEditable(cell));

export const getFormatBtnTooltip = (isDisabled: boolean, isReadMode: boolean, tooltip: string): string => {
    const intl = LocalesService.useIntl();
    if (isReadMode) return intl.formatMessage(messages.isReadMode);

    return isDisabled ? intl.formatMessage(messages.selectEdgeOrObject) : tooltip;
};

export const getFillColorFromSymbol = (symbol: Symbol | undefined) => {
    const fillColor: string =
        symbol?.graphical?.split('<fillcolor color=')?.[1]?.split('/')?.[0]?.trim()?.slice(1, -1) || '';

    const hexColor: string = Object.keys(Colors).includes(fillColor) ? Colors[fillColor] : fillColor;

    if (hexColor?.length === 7 && hexColor.match(/#[a-fA-F0-9]{6}\b/)) {
        return hexColor;
    } else return undefined;
};
