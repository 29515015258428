import { useIntl } from 'react-intl';
import React from 'react';
import messages from '../../FormatPanel.messages';
import { useDispatch, useSelector } from 'react-redux';
import theme from './FontBlock.scss';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { LabelStyle } from '../../../../models/labelStyle';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { fontFamilyOptions, fontSizeOptions } from '../../../../utils/configuration';
import { getGeneralMenuButtonsState } from '../../../../selectors/generalMenu.selectors';
import { ButtonEditLabelState } from '../../../../models/buttonEditLabelState';
import icRight from '@/resources/icons/ic-ribbon-text-right.svg';
import icLeft from '@/resources/icons/ic-ribbon-text-left.svg';
import icCenter from '@/resources/icons/ic-ribbon-text-center.svg';
import { Alignment } from '../../../../models/alignment';
import { MxConstants } from '../../../../mxgraph';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { TToolbarBtn } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.types';
import { getFormatBtnTooltip } from '../../utils/format.utils';
import { NodeId } from '../../../../serverapi/api';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';

type TFontBlock = { disabled?: boolean; modelId: NodeId };

export const FontBlock = (props: TFontBlock) => {
    const { disabled, modelId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeById(modelId));

    const buttonEditLabelState: ButtonEditLabelState = useSelector(getGeneralMenuButtonsState);
    const onChange = (labelStyle: LabelStyle) => (value: string) => {
        dispatch(generalMenuLabelStyleChangeAction(labelStyle, value, false));
    };

    const onChangeAlign = (value: any, isActive: boolean) => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.alignmentStyle, value, isActive));
    };

    const buttons: TToolbarBtn[] = [
        {
            icon: icLeft,
            dataTest: 'object-toolbar_text-aligne_left',
            onClick: () => onChangeAlign(MxConstants.ALIGN_LEFT, buttonEditLabelState.alignment === Alignment.Left),
            isActive: buttonEditLabelState.alignment === Alignment.Left,
            disabled: disabled || isReadMode,
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.textLeft)),
        },
        {
            icon: icCenter,
            dataTest: 'object-toolbar_text-aligne_center',
            onClick: () =>
                onChangeAlign(MxConstants.ALIGN_CENTER, buttonEditLabelState.alignment === Alignment.CenterVert),
            isActive: buttonEditLabelState.alignment === Alignment.CenterVert,
            disabled: disabled || isReadMode,
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.textCenter)),
        },
        {
            icon: icRight,
            dataTest: 'object-toolbar_text-aligne_right',
            onClick: () => onChangeAlign(MxConstants.ALIGN_RIGHT, buttonEditLabelState.alignment === Alignment.Right),
            isActive: buttonEditLabelState.alignment === Alignment.Right,
            disabled: disabled || isReadMode,
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.textRight)),
        },
    ];

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.font)}</div>
            <div className={theme.fontContainer}>
                <div className={theme.fontFamilyContainer}>
                    <Select
                        onChange={onChange(LabelStyle.fontFamily)}
                        value={buttonEditLabelState.fontFamily}
                        disabled={!!disabled || isReadMode}
                        placeholder=""
                        originalTheme
                        data-test="object-toolbar_font-family-select"
                        tooltip={getFormatBtnTooltip(!!disabled, isReadMode, '')}
                    >
                        {fontFamilyOptions.map((fontFamily) => {
                            return <Select.Option value={fontFamily.value} label={fontFamily.value} />;
                        })}
                    </Select>
                </div>
                <div className={theme.fontSizeContainer}>
                    <Select
                        onChange={onChange(LabelStyle.fontSize)}
                        value={buttonEditLabelState.fontSize}
                        disabled={!!disabled || isReadMode}
                        placeholder=""
                        originalTheme
                        data-test="object-toolbar_font-size-select"
                        tooltip={getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.fontSize))}
                    >
                        {fontSizeOptions.map((fontFamily) => {
                            return <Select.Option value={fontFamily.value} label={fontFamily.value} />;
                        })}
                    </Select>
                </div>
            </div>
            <div className={theme.alignContainer}>
                <ButtonGroup buttons={buttons} />
            </div>
        </div>
    );
};
