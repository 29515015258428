import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IWorkspaceTabItemModelTypeTemplateEditParams, TWorkspaceTab } from '@/models/tab.types';
import { WorkspaceTabDataSelectors } from '@/selectors/workspaceTabData.selectors';
import { InternationalString, ModelTemplateDTO, ModelType } from '@/serverapi/api';
import theme from './ModelTemplatesTab.scss';
import messages from './ModelTemplatesTab.messages';
import { useIntl } from 'react-intl';
import { PresetSelectors } from '@/selectors/preset.selectors';
import { TPreset } from '@/models/preset.types';
import { LocalesService } from '@/services/LocalesService';
import { Form, Input } from 'antd';
import { InputId } from '@/modules/InputId/InputId.component';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { FooterButtons } from '@/modules/Footer/FooterButtons.component';
import { createButtonsCompositeDataTestId } from '../../util/createButtonsCompositeDataTestId.utils';
import { TButtonProps } from '@/modules/UIKit/components/Button/Button.types';
import { workspaceRemoveTabByNodeId } from '@/actions/tabs.actions';
import { submitModelType } from '@/actions/presetSettings/presetSettingsModelType.actions';
import { TSubmitModelTypePayload } from '@/actions/presetSettings/presetSettingsModelType.actions.types';
import { editTemplateEditModelType } from '@/actions/workspaceTab/editModelTypeWorkspaceTab.actions';

type TModelTemplateEditorProps = {
    tab: TWorkspaceTab;
};

export const ModelTemplateEditor = (props: TModelTemplateEditorProps) => {
    const params = props.tab.params as IWorkspaceTabItemModelTypeTemplateEditParams;
    const { serverId, presetId, modelTypeId, templateId } = params;
    const intl = useIntl();
    const [generalForm] = Form.useForm();
    const dispatch = useDispatch();
    const modelType: ModelType = useSelector(
        WorkspaceTabDataSelectors.getEditModelType(presetId, serverId, modelTypeId),
    );
    const preset: TPreset = useSelector(PresetSelectors.byId({ presetId, serverId }));
    const template: ModelTemplateDTO | undefined = modelType.templates?.find((template) => template.id === templateId);
    const [hiddenTemplate, setHiddenTemplate] = useState<boolean | undefined>(template?.hiddenTemplate);
    const [name, setName] = useState<InternationalString>(template?.name || {});

    const buttons: TButtonProps[] = [
        {
            children: intl.formatMessage(messages.cancel),
            onClick: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
            dataTest: 'model-template-editor_cancel-btn',
            size: 'large',
        },
        {
            children: intl.formatMessage(messages.save),
            visualStyle: 'primary',
            disabled: false,
            onClick: () => {
                generalForm
                    .validateFields()
                    .then(() => {
                        const templates: ModelTemplateDTO[] =
                            modelType.templates?.map((templ) => {
                                if (templ.id === template?.id) {
                                    return { ...templ, name, hiddenTemplate };
                                }
                                return templ;
                            }) || [];

                        const payload: TSubmitModelTypePayload = {
                            serverId,
                            modelTypes: [{ ...modelType, templates }],
                            presetId: preset.id,
                            createMode: false,
                            needTabClose: true,
                            tabNodeId: props.tab.nodeId,
                        };
                        dispatch(submitModelType(payload));
                        dispatch(
                            editTemplateEditModelType({
                                modelTypeId,
                                serverId,
                                presetId: preset.id,
                                template: { ...template, name, hiddenTemplate } as ModelTemplateDTO,
                            }),
                        );
                    })
                    .catch(() => undefined);
            },
            dataTest: 'model-template-editor_save-btn',
            size: 'large',
        },
    ];

    return (
        <div className={theme.container}>
            {modelType && template ? (
                <>
                    <span className={theme.navigationTitle}>{`${preset.name} > ${intl.formatMessage(
                        messages.modelType,
                    )} > ${modelType.name} > ${intl.formatMessage(
                        messages.modelTemplate,
                    )} > ${LocalesService.internationalStringToString(template.name)}`}</span>
                    <div className={theme.formWrapper}>
                        <Form autoComplete='off' form={generalForm} layout="vertical">
                            <div className={theme.form}>
                                <MultiLangInputDialog
                                    placeholder={intl.formatMessage(messages.name)}
                                    multiLangValue={name}
                                    onChange={setName}
                                    label={intl.formatMessage(messages.name)}
                                    mainInputName="name"
                                    mainInputClassName={theme.input}
                                    generalForm={generalForm}
                                    required
                                    autoFocus
                                />
                                <InputId
                                    disabled
                                    value={template.id}
                                    required
                                    mainInputName="GUID"
                                    mainInputClassName={theme.input}
                                />
                                <Form.Item
                                    className={theme.formItem}
                                    label={intl.formatMessage(messages.createdBy)}
                                    name="createdBy"
                                    initialValue={template.createdBy}
                                    required
                                >
                                    <Input className={theme.input} disabled />
                                </Form.Item>

                                <Form.Item name="hiddenTemplate">
                                    <Checkbox
                                        status={!hiddenTemplate}
                                        onChange={() => {
                                            setHiddenTemplate(!hiddenTemplate);
                                        }}
                                    >
                                        {intl.formatMessage(messages.hiddenTemplate)}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Form>
                        <FooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'model-template-editor')} />
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
