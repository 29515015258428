// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonGroup__container__FNcFl{border-radius:4px;border:1px solid var(--ButtonGroupBorder);background-color:var(--ButtonGroupnBackground);display:-webkit-box;display:-ms-flexbox;display:flex}.ButtonGroup__container__FNcFl button+button{margin-left:0}.ButtonGroup__normal__rrsjI{height:32px}.ButtonGroup__normal__rrsjI button{width:32px !important}.ButtonGroup__small__tzB6U{height:30px}.ButtonGroup__small__tzB6U button{width:30px !important}.ButtonGroup__container__FNcFl.ButtonGroup__mainMenuBtn___kBJ8{border:none !important}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/ButtonGroup/ButtonGroup.scss"],"names":[],"mappings":"AAAA,+BAII,iBAAA,CACA,yCAAA,CACA,8CAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CANA,6CACI,aAAA,CAQR,4BACI,WAAA,CACA,mCACI,qBAAA,CAIR,2BACI,WAAA,CACA,kCACI,qBAAA,CAIR,+DACI,sBAAA","sourcesContent":[".container {\r\n    button + button {\r\n        margin-left: 0;\r\n    }\r\n    border-radius: 4px;\r\n    border: 1px solid var(--ButtonGroupBorder);\r\n    background-color: var(--ButtonGroupnBackground);\r\n    display: flex;\r\n}\r\n\r\n.normal {\r\n    height: 32px;\r\n    button {\r\n        width: 32px !important;\r\n    }\r\n}\r\n\r\n.small {\r\n    height: 30px;\r\n    button {\r\n        width: 30px !important;\r\n    }\r\n}\r\n\r\n.container.mainMenuBtn {\r\n    border: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ButtonGroup__container__FNcFl",
	"normal": "ButtonGroup__normal__rrsjI",
	"small": "ButtonGroup__small__tzB6U",
	"mainMenuBtn": "ButtonGroup__mainMenuBtn___kBJ8"
};
export default ___CSS_LOADER_EXPORT___;
