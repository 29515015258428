import { InputNumber, Menu, Modal, Select, Table } from 'antd';
import { SelectInfo } from 'rc-menu/lib/interface';
import { ColumnProps } from 'antd/es/table';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
    AttributeRole,
    AttributeRoleRoleEnum,
    ModelTypeSymbolRole,
    ModelTypeSymbolRoleRoleEnum,
} from '../../../../../serverapi/api';
import messages from '../../../messages/SimulationSetupDialog.messages';
import theme from './SimulationSetupDialog.scss';
import cx from 'classnames';
import { TSimulationSetupDialogProps } from './SimulationSetupDialog.types';
import { shouldCallModalSubmitHandler } from '../../../../../services/utils/ModalHelper';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

enum TabId {
    MODELS = 'MODELS',
    ATTRIBUTES = 'ATTRIBUTES',
    // RESULTS = 'RESULTS',
    // ALGORITHM = 'ALGORITHM',
}

enum AttributeRoleEnum {
    LIMIT_SLOTS = 'LIMIT_SLOTS',
    PROCESSING_TIME = 'PROCESSING_TIME',
    PAUSE_TIME = 'PAUSE_TIME',
}

const SimulationSetupDialogComponent = (props: TSimulationSetupDialogProps) => {
    const { intl, onSubmit, onClose, modelTypes, simulationSettings, attributeTypes } = props;

    const missingRoles = Object.keys(AttributeRoleEnum).filter(
        (key) => !simulationSettings.attributeRoles?.find((item) => item.role === key),
    );

    const defaultAttributeRoles: AttributeRole[] = [
        ...missingRoles.map((role: AttributeRoleEnum) => ({
            attributeTypeId: '',
            role,
        })),
    ];
    if (simulationSettings.attributeRoles?.length) {
        defaultAttributeRoles.unshift(...simulationSettings.attributeRoles);
    }

    const [tabId, setTabId] = useState(TabId.MODELS);
    const [modelTypeSymbolRoles, setModelTypeSymbolRoles] = useState<Array<ModelTypeSymbolRole>>(
        simulationSettings?.modelTypeSymbolRoles || [],
    );
    const [attributeRoles, setAttributeRoles] = useState<AttributeRole[]>(defaultAttributeRoles);
    const [expandedRows, setExpandedRows] = useState<Array<string>>([]);
    const [decompositionDepth, setDecompositionDepth] = useState<number>(simulationSettings?.decompositionDepth);

    const handleSelect = (param: SelectInfo) => {
        if (param.key !== tabId) {
            setTabId(param.key as TabId);
        }
    };

    const handleSelectAttributeType = (rowIndex: number) => (selectedAttributeType: AttributeRoleRoleEnum) => {
        setAttributeRoles(
            attributeRoles.map((role, roleIndex) =>
                roleIndex === rowIndex ? { ...role, attributeTypeId: selectedAttributeType } : role,
            ),
        );
    };

    const setSymbolRole = (type, symbolRole) => {
        if (!type.isModel) {
            return (
                <Checkbox
                    status={
                        !!modelTypeSymbolRoles.find(
                            (t) => t.modelTypeId === type.modelId && t.symbolId === type.id && t.role === symbolRole,
                        )
                    }
                    onChange={(checked: boolean) => {
                        const roles = modelTypeSymbolRoles.filter(
                            (r) => !(r.symbolId === type.id && r.modelTypeId === type.modelId),
                        );
                        if (checked) {
                            const role = {
                                modelTypeId: type.modelId,
                                role: symbolRole as ModelTypeSymbolRoleRoleEnum,
                                symbolId: type.id,
                            };

                            roles.push(role);
                            setModelTypeSymbolRoles(roles);
                        } else {
                            setModelTypeSymbolRoles(roles);
                        }
                    }}
                />
            );
        }

        return undefined;
    };
    const columns: Array<ColumnProps<any>> = [
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'name',
            width: '50%',
        },
        {
            title: intl.formatMessage(messages.event),
            render: (type) => setSymbolRole(type, 'EVENT'),
            width: 60,
        },
        {
            title: intl.formatMessage(messages.action),
            render: (type) => setSymbolRole(type, 'ACTION'),
            width: 65,
        },
        {
            title: intl.formatMessage(messages.and),
            render: (type) => setSymbolRole(type, 'AND'),
            width: 45,
        },
        {
            title: intl.formatMessage(messages.xor),
            render: (type) => setSymbolRole(type, 'XOR'),
            width: 75,
        },
        {
            title: intl.formatMessage(messages.resource),
            render: (type) => setSymbolRole(type, 'RESOURCE'),
            width: 63,
        },
    ];

    const attributeColumns: Array<ColumnProps<AttributeRole>> = [
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'role',
            width: '50%',
            key: 'role',
            render: (value: AttributeRoleRoleEnum) => intl.formatMessage(messages[value]),
        },
        {
            title: intl.formatMessage(messages.attribute),
            dataIndex: 'attributeTypeId',
            width: '50%',
            key: 'attributeTypeId',
            render: (attributeTypeId: string, record: AttributeRole, rowIndex: number) => (
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    defaultValue={attributeTypeId}
                    onChange={handleSelectAttributeType(rowIndex)}
                    filterOption
                >
                    <Select.Option key={-1} value="">
                        &nbsp;
                    </Select.Option>
                    {attributeTypes.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
    ];

    const renderInnerTabs = () => {
        switch (tabId) {
            case TabId.MODELS: {
                return (
                    <div>
                        <Table
                            expandedRowKeys={expandedRows}
                            onExpand={(expanded, record) => {
                                if (expanded) {
                                    setExpandedRows([...expandedRows, record.id + record.modelId]);
                                } else {
                                    setExpandedRows(expandedRows.filter((e) => e !== record.id + record.modelId));
                                }
                            }}
                            rowKey={(record) => record.id + record.modelId}
                            columns={columns}
                            dataSource={modelTypes.map((t, index) => ({
                                ...t,
                                key: index,
                                children: t.symbols.map((s) => ({
                                    ...s,
                                    modelId: t.id,
                                })),
                                isModel: true,
                            }))}
                            className={theme.table}
                            bordered
                            pagination={false}
                            scroll={{ y: 304 }}
                        />
                        <div className={theme.decompositionDepth}>
                            <label>{intl.formatMessage(messages.decompositionDepth)}</label>
                            <InputNumber value={decompositionDepth} onChange={setDecompositionDepth as any} />
                        </div>
                    </div>
                );
            }
            case TabId.ATTRIBUTES: {
                return (
                    <div>
                        <Table
                            rowKey={(record, rowIndex) => `${rowIndex}`}
                            columns={attributeColumns}
                            dataSource={attributeRoles}
                            className={cx(theme.table, theme.attributesTable)}
                            bordered
                            pagination={false}
                        />
                    </div>
                );
            }
            // case TabId.RESULTS: {
            //     return <div />;
            // }
            default:
                return null;
        }
    };

    const renderMenu = (): JSX.Element[] =>
        Object.keys(TabId).map((key) => (
            <Menu.Item key={key} data-test={`simulation_setup_tab-${key}`}>
                {props.intl.formatMessage(messages[key])}
            </Menu.Item>
        ));

    const submit = () => {
        onSubmit({
            attributeRoles: attributeRoles.filter((item) => !!item.attributeTypeId),
            modelTypeSymbolRoles,
            decompositionDepth,
            algorithm: 'SIMPLE',
        });
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'simulation_setup_cancel-button',
                },
                {
                    key: 'ok',
                    onClick: submit,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                    dataTest: 'simulation_setup_save-button',
                },
            ]}
        />
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    submit();
                }
            }}
        >
            <Modal
                width={800}
                open
                bodyStyle={{ height: '480px' }}
                footer={footer}
                onOk={submit}
                onCancel={onClose}
                className={theme.setupModal}
                title={intl.formatMessage(messages.simulationSetupTitle)}
            >
                <div>
                    <Menu defaultSelectedKeys={[TabId.MODELS]} mode="horizontal" onSelect={handleSelect}>
                        {renderMenu()}
                    </Menu>
                </div>
                <div>{renderInnerTabs()}</div>
            </Modal>
        </div>
    );
};

const IntlComponent = injectIntl(SimulationSetupDialogComponent);

export { IntlComponent as SimulationSetupDialogComponent };
