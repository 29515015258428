import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../messages/CommonToolbar.messages';
import classnames from 'classnames';
import menuItemTheme from '../items/MenuItem.scss';
import { NodeId } from '../../../../serverapi/api';
import { EditorMode } from '../../../../models/editorMode';
import icReadMode from '../../../../resources/icons/ic-read-mode.svg';
import icEditMode from '../../../../resources/icons/ic-edit-mode.svg';
import icGridBtn from '../../../../resources/icons/toolbar/dashboard-grid-button.svg';
import theme from './DashboardEditorToolbar.scss';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { dashboardEditorModeChanged } from '@/actions/dashboard.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TDashboardEditorToolbarProps = {
    compact?: boolean | undefined;
    id: NodeId;
};

export const DashboardEditorToolbar: FC<TDashboardEditorToolbarProps> = (props) => {
    const activeWorkspaceTab = useSelector(TabsSelectors.getActiveTab);
    const isActiveScheme: boolean | undefined = !!useSelector(TabsSelectors.getActiveTabId);
    const dispatch = useDispatch();
    const intl = useIntl();
    const editorMode = activeWorkspaceTab ? activeWorkspaceTab.mode : undefined;
    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: props.compact,
        [menuItemTheme.toolbar_disabled]:
            activeWorkspaceTab === undefined ||
            (activeWorkspaceTab && activeWorkspaceTab.type === 'HomePageTab') ||
            activeWorkspaceTab.type === 'ContentLoadingPageTab',
    });
    const groupClassName = classnames(menuItemTheme.group, 'group');
    const isReadMode = activeWorkspaceTab && activeWorkspaceTab.mode === EditorMode.Read;
    const editModeTooltipeTitle = isReadMode
        ? intl.formatMessage(messages.toEditMode)
        : intl.formatMessage(messages.toReadMode);

    const gridTooltipe = isReadMode
        ? intl.formatMessage(messages.onlyInEditModeAvailable)
        : intl.formatMessage(messages.dashboardGridTooltip);

    const handleModeChange = () => {
        dispatch(dashboardEditorModeChanged(editorMode === EditorMode.Edit ? EditorMode.Read : EditorMode.Edit));
    };

    const handleGridButton = () => {
        dispatch(openDialog(DialogType.DASHBOARD_CELLS_COUNT_DIALOG, { nodeId: activeWorkspaceTab?.nodeId }));
    };

    return (
        <div className={className}>
            <div className={groupClassName}>
                <div className={props.compact ? theme.modeGroup_compact : theme.modeGroup_full}>
                    <Button
                        onClick={handleModeChange}
                        dataTest="dashboard-toolbar_edit-model-button"
                        visualStyle={{type: "text"}}
                        size="large"
                        tooltip={isActiveScheme ? { title: editModeTooltipeTitle, nowrap: true } : ''}
                        icon={{...(editorMode === EditorMode.Edit ? icEditMode : icReadMode), width: 35, height: 35}}
                    />
                </div>
            </div>

            <div className={menuItemTheme.divider} />

            <div className={menuItemTheme.group}>
                <span className={props.compact ? theme.gridButton_compact : theme.modeGroup_full}>
                    <Button
                        disabled={editorMode === EditorMode.Read}
                        onClick={handleGridButton}
                        dataTest="dashboard-toolbar_edit-grid-button"
                        visualStyle={{type: "text"}}
                        size="small"
                        tooltip={gridTooltipe}
                        icon={icGridBtn}
                    >
                        {intl.formatMessage(messages.dashboardGrid)}
                    </Button>
                </span>
            </div>
        </div>
    );
};
