import { useIntl } from 'react-intl';
import { MxCell } from '../../../../mxgraph';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../FormatPanel.messages';
import edgeNames from '@/modules/AdminTools/Methodology/components/Presets/EdgeType/EdgeType.messages';
import theme from './edgeStyles.scss';
import React, { useMemo } from 'react';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { TEdgeTypeSelectorState } from '../../../../models/edgeTypeSelectorState.types';
import {
    getGeneralMenuButtonsState,
    getGeneralMenuEdgeTypeSelectorState,
} from '../../../../selectors/generalMenu.selectors';
import icStartArrow from '@/resources/icons/toolbar/ic-start-line.svg';
import icEndArrow from '@/resources/icons/toolbar/ic-end-line.svg';
import {
    ICompactableSelectItemValue,
    TCompactableSelectItemType,
} from '../../../MainMenu/components/CompactableSelect/CompactableSelect.types';
import { changeEdgesStyle } from '../../../../actions/editor.actions';
import { EdgesForChangeSelectors } from '../../../../selectors/edgesForChangeType.selectors';
import { EdgeInstanceImpl } from '../../../../models/bpm/bpm-model-impl';
import { EditorMode } from '../../../../models/editorMode';
import { Icon } from '../../../UIKit';
import {
    PROP_KEY_EDGE_END_ARROW,
    PROP_KEY_EDGE_START_ARROW,
} from '../../../../models/properties/accessible-properties';
import { EdgeArrow, edgeArrowTypeNames } from '../../../../models/edge-arrow';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { ButtonEditLabelState } from '../../../../models/buttonEditLabelState';

type TEdgeStartArrowBlock = {
    isEntityEditable: boolean;
    selectedEdges: MxCell[];
    arrow: 'startArrow' | 'endArrow';
    isReadMode: boolean;
};

export const EdgeArrowBlock = (props: TEdgeStartArrowBlock) => {
    const { selectedEdges, isEntityEditable, arrow, isReadMode } = props;
    const isStartArrow: boolean = arrow === PROP_KEY_EDGE_START_ARROW;
    const intl = useIntl();
    const locale = useSelector(getCurrentLocale);
    const dispatch = useDispatch();
    const edgeTypeSelectorState: TEdgeTypeSelectorState = useSelector(getGeneralMenuEdgeTypeSelectorState);
    const buttonEditLabelState: ButtonEditLabelState = useSelector(getGeneralMenuButtonsState);
    const selectedEdgeInstances: EdgeInstanceImpl[] = selectedEdges.map((edge) => edge.getValue());
    const hasAvailableTypes: boolean = edgeTypeSelectorState.availableTypes.length >= 1;
    const tooltipMessage: string = useSelector(
        EdgesForChangeSelectors.getTooltipMessage(selectedEdgeInstances, hasAvailableTypes, EditorMode.Edit),
    );
    const isEdgesBlocked: boolean = !!tooltipMessage;
    const edgeEditDisabled: boolean = !isEntityEditable;

    const items = useMemo(
        () =>
            edgeArrowTypeNames(locale, intl).map(
                (item) =>
                    ({
                        type: TCompactableSelectItemType.Value,
                        value: item.id,
                        label: item.value[locale],
                        disabled: false,
                    } as ICompactableSelectItemValue<string>),
            ),
        [locale],
    );

    const disabled: boolean = edgeEditDisabled || isEdgesBlocked || !hasAvailableTypes || isReadMode;
    const edgesArrow: EdgeArrow | undefined = buttonEditLabelState[arrow];

    const edgeArrowValue: JSX.Element = (
        <div className={theme.valueContainer}>
            <Icon spriteSymbol={isStartArrow ? icStartArrow : icEndArrow} />
            <span className={theme.typeContainer}>
                {edgesArrow !== undefined ? intl.formatMessage(edgeNames[EdgeArrow[edgesArrow]]) : ''}
            </span>
        </div>
    );
    const handleChangeEdgeStyle = (styleKey: string) => (styleValue: string) => {
        const cellIds: string[] = selectedEdges.map((edge) => edge.id);
        dispatch(changeEdgesStyle([styleKey], [styleValue], cellIds));
    };

    const getTooltip = (selectedEdgesLength: number, isReadMode: boolean): string => {
        if (isReadMode) return intl.formatMessage(messages.isReadMode);
        const createTooltipMessage = (edgeDefinitionWarning?: string): string =>
            (edgeEditDisabled && intl.formatMessage(messages.selectEdge)) ||
            (isEdgesBlocked && edgeDefinitionWarning) ||
            '';

        const edgeTolltip: string =
            createTooltipMessage(
                isStartArrow
                    ? intl.formatMessage(messages.edgeDefinitionStartArrowWarning)
                    : intl.formatMessage(messages.edgeDefinitionEndArrowWarning),
            ) || '';

        return selectedEdgesLength ? edgeTolltip : intl.formatMessage(messages.selectEdge);
    };

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>
                {isStartArrow ? intl.formatMessage(messages.startArrow) : intl.formatMessage(messages.endArrow)}
            </div>
            <Select
                onChange={handleChangeEdgeStyle(isStartArrow ? PROP_KEY_EDGE_START_ARROW : PROP_KEY_EDGE_END_ARROW)}
                value={edgeArrowValue}
                disabled={disabled}
                placeholder=""
                originalTheme
                data-test={isStartArrow ? 'format-toolbar_edge-start-arrow' : 'format-toolbar_edge-end-arrow'}
                tooltip={getTooltip(selectedEdges.length, isReadMode)}
            >
                {items.map((item) => {
                    return <Select.Option value={item.value} label={item.label} />;
                })}
            </Select>
        </div>
    );
};
