import React from 'react';
import { TToolbarBtn } from './ButtonGroup.types';
import { ToolbarBtn } from './ToolbarBtn.component';
import theme from './ButtonGroup.scss';
import classnames from 'classnames';

type TButtonGroup = {
    buttons: TToolbarBtn[];
    size?: 'small' | 'normal';
    isMainMenuBtn?: boolean;
};

export const ButtonGroup = (props: TButtonGroup) => {
    const { buttons, size = 'normal', isMainMenuBtn } = props;
    const buttonGroupClass: string = classnames(theme.container, {
        [theme.normal]: size === 'normal',
        [theme.small]: size === 'small',
        [theme.mainMenuBtn]: isMainMenuBtn,
    });

    return (
        <div className={buttonGroupClass}>
            {buttons.map((buttonProps) => {
                return <ToolbarBtn {...buttonProps} />;
            })}
        </div>
    );
};
