import { FILE_ICON_TYPES } from '@/modules/Tree/TreeItemIcons/treeItemIcons';
import type { FileNodeDTO } from '../serverapi/api';

export const imageExtensions: (string | undefined)[] = ['jpg', 'jpeg', 'svg', 'bpm', 'gif', 'png'];

export const isImageExtension = (ext?: string) => imageExtensions.includes(ext?.toLowerCase());

export const isImageFile = (file: File): boolean => isImageExtension(getExtension(file.name));

export const isImageFileNode = (file?: FileNodeDTO): boolean => isImageExtension(file?.extension);

export const getExtension = (name?: string): string | undefined => {
    const nameAndExtension = name?.split('.');

    return nameAndExtension && nameAndExtension.length >= 2 ? nameAndExtension.pop() : undefined;
};

export const getFileExtension = (file: FileNodeDTO): string | undefined => file?.extension || getExtension(file?.name);

export const getFileIconType = (extension: string): FILE_ICON_TYPES => {
    const ex = extension.toLocaleLowerCase().trim();

    if (['doc', 'docx', 'docm', 'dot', 'dotx'].includes(ex)) {
        return FILE_ICON_TYPES.WORD_FILE;
    }

    if (['xls', 'xlsx', 'xlt', 'xltx'].includes(ex)) {
        return FILE_ICON_TYPES.EXCEL_FILE;
    }

    if (isImageExtension(ex)) {
        return FILE_ICON_TYPES.IMG_FILE;
    }

    if (['txt'].includes(ex)) {
        return FILE_ICON_TYPES.TEXT_FILE;
    }

    return FILE_ICON_TYPES.FILE;
};

export const getFileIcon = (extension: string): string => FILE_ICON_TYPES[getFileIconType(extension)];