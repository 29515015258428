import type { AttributeValue } from '../../../../serverapi/api';
import type { ApiBundle } from '../../../../services/api/api-bundle';
import type { TEditorProps } from '../../Editor.types';
import * as React from 'react';
import theme from './Editor.scss';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { SymbolsService } from '../../../../services/SymbolsService';
import { instancesBPMMxGraphMap } from '../../../../mxgraph/bpm-mxgraph-instance-map';
import { injectIntl } from 'react-intl';
import { BPMPSDDiagram } from '../../../../mxgraph/psdDiagram/BPMPSDDiagramm';
import { FileDrop } from 'react-file-drop';
import { DefaultGraph } from '../../../../mxgraph/DefaultGraph';
import electron, { remote } from '../../../../electron';
import { MxCell, MxEvent } from '../../../../mxgraph/mxgraph';
import { isUndefined } from 'is-what';
import { ObjectDefinitionImpl } from '../../../../models/bpm/bpm-model-impl';
import { changes } from '../../../../utils/object';
import { Dictionary, filter, isEqual, reduce } from 'lodash-es';
import { EditorMode } from '../../../../models/editorMode';
import { BPMN2Diagram } from '../../../../mxgraph/bpmnDiagram/BPMN2Diagramm';
import { WhiteboardGraph } from '../../../../mxgraph/WhiteboardGraph';
import { PSDGraph } from '../../../../mxgraph/psdDiagram/PSDGraph';
import SplitPane from 'react-split-pane';
import { CommentsPanelContainer } from '../../../Comments/containers/CommentsPanel.container';
import { ModelTypes } from '../../../../models/ModelTypes';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { LOCK_TIMEOUT } from '../../../../utils/consts';
import { addElementsToGraph } from '../../../../mxgraph/util/BpmMxEditorUtils';
import { GridTypeOnCanvas } from '../../../MainMenu/components/GraphGeneralToolbar/GridTypeOnCanvasConstant';
import { Search } from '../Search/Search.component';
import { ImageMaker } from '../../../ImageMaker/ImageMaker';
import { getSearchableCells, viewCellsActive } from '../../../../services/bll/SearchByModelBllService';
import { getStore } from '../../../../store';
import { checkInvisibleEdgeTypes } from '../../../../actions/edgeType.actions';
import { ComplexSymbolManager } from '../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { deleteAction } from '../../../../actions/editor.actions';
import { nodeService } from '../../../../services/NodeService';
import { SequenceGraph } from '../../../../mxgraph/SequenceGraph/SequenceGraph';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import {
    DEFAULT_RIGHT_PANEL_SIZE,
    MAX_RIGHT_PANEL_SIZE,
    MIN_RIGHT_PANEL_SIZE,
} from '@/modules/Comments/utils/commentsPanelSizes';
import PSDModelGraph from '@/mxgraph/GridGraph/PSD/PSDModelGraph.class';
import EPCModelGraph from '@/mxgraph/GridGraph/EPC/EPCModelGraph.class';
import { FormatPanel } from '../../../FormatPanel/components/FormatPanel/FormatPanel.component';

const symbolsService = new SymbolsService();

type TEditorState = {
    inited: boolean;
    searchView: boolean;
};

class Editor extends React.Component<TEditorProps> {
    state: TEditorState = {
        inited: false,
        searchView: false,
    };

    private graphRef: HTMLDivElement;
    private graph: BPMMxGraph;
    public containerElement: HTMLElement;
    private canvas: HTMLCanvasElement;
    private intervalID: number = 0;
    private lockIntervalID: number = 0;

    private defaultRightPanelSize: number | undefined = DEFAULT_RIGHT_PANEL_SIZE;
    private maxRightPanelSize: number = MAX_RIGHT_PANEL_SIZE;
    private minRightPanelSize: number = MIN_RIGHT_PANEL_SIZE;

    componentDidMount() {
        const {
            model: { nodeId },
        } = this.props;

        if (LocalStorageDaoService.getCommentsTabSize()) {
            this.defaultRightPanelSize = LocalStorageDaoService.getCommentsTabSize();
        }

        const commonParams = {
            id: nodeId,
            mode: this.props.mode,
            modelType: this.props.modelType,
            container: this.graphRef,
        };

        switch (this.props.modelType?.id) {
            case ModelTypes.MIND_MAP:
                this.graph = new WhiteboardGraph(commonParams);
                break;
            case ModelTypes.PSD_MODEL: {
                const { layout: gridLayout } = this.props.model;

                this.graph = new PSDModelGraph({
                    ...commonParams,
                    gridLayout,
                });
                break;
            }
            case ModelTypes.EPC_MODEL: {
                const { layout: gridLayout } = this.props.model;

                this.graph = new EPCModelGraph({
                    ...commonParams,
                    gridLayout,
                });
                break;
            }
            case ModelTypes.PSD_CHART: //deprecated
                this.graph = new PSDGraph(commonParams);
                break;
            case ModelTypes.SEQUENCE_DIAGRAM:
                this.graph = new SequenceGraph({
                    ...commonParams,
                    intersectableCellTypes: ['object'],
                    handleCheckInvisibleEdgeTypes: this.handleCheckInvisibleEdgeTypes,
                    handleCleanupInvisibleEdges: this.handleCleanupInvisibleEdges,
                });
                break;
            default:
                this.graph = new DefaultGraph({
                    ...commonParams,
                    intersectableCellTypes: ['object'],
                    handleCheckInvisibleEdgeTypes: this.handleCheckInvisibleEdgeTypes,
                    handleCleanupInvisibleEdges: this.handleCleanupInvisibleEdges,
                });
                break;
        }

        // используется для получения картинок через Cef или Selenium
        globalThis.imageMaker = new ImageMaker(this.graph);

        this.graph.setIntl(this.props.intl);
        this.graph.bpmMxGraphContext = {
            api: {} as ApiBundle,
            serverId: this.props.serverId,
            objectDefinitionCopyPasteContext: {},
        };

        instancesBPMMxGraphMap.set(nodeId, this.graph);

        this.initSymbols();

        this.softRender(this.props);
        this.initGraphAccordingMetaInfo();

        if (electron) {
            const mainWindow = remote.getCurrentWindow();
            mainWindow.on('close', () => {
                this.props.onUnlock();
                this.props.onChangeModel({
                    forceSaveHistory: true,
                    attempts: 0,
                    forceSave: true,
                    showNotification: false,
                });
            });
        }

        let lastTimeOk: boolean = true;
        let isEqualId: boolean = false;

        if (this.props.saveTimeOut) {
            this.intervalID = Number(
                setInterval(() => {
                    isEqualId = compareNodeIds(this.props.activeKey, this.graph.id);
                    if (this.props.mode !== EditorMode.Read && (isEqualId || lastTimeOk)) {
                        this.props.onChangeModel({
                            forceSaveHistory: false,
                            attempts: 1,
                            forceSave: false,
                            showNotification: false,
                        });
                    }
                    lastTimeOk = isEqualId;
                }, this.props.saveTimeOut),
            );
            this.props.onAddAutoSaveModelIntervalId(this.intervalID);
        }
        this.lockIntervalID = Number(
            setInterval(() => this.props.mode !== EditorMode.Read && this.props.onLock(), LOCK_TIMEOUT),
        );

        const elements = this.props.model?.elements || [];

        addElementsToGraph(this.graph, this.props.graph, elements, this.props.serverUrl);

        this.props.onInit(
            {
                // todo: use this.props.model.nodeId
                id: this.props.id,
                serverId: this.props.serverId,
                repositoryId: this.props.model.nodeId.repositoryId,
            },
            this.graph,
        );

        this.props.updateAllCellsOverlays(this.graph.id);

        const { graph } = this;
        const { onZoomIn, onZoomOut } = this.props;

        if (onZoomIn && onZoomOut) {
            MxEvent.addMouseWheelListener((evt, up) => {
                if (graph.isZoomWheelEvent(evt)) {
                    let source = MxEvent.getSource(evt);

                    while (source != null) {
                        if (source === graph.container) {
                            graph.tooltipHandler.hideTooltip();

                            up ? onZoomIn() : onZoomOut();
                            MxEvent.consume(evt);

                            return false;
                        }

                        source = source.parentNode;
                    }
                }

                return true;
            }, this.graph.container);
        }

        this.updateUndoRedoState();

        this.graphRef.addEventListener('dragenter', this.dragEnterHandler);
        this.graphRef.addEventListener('drop', this.dropHandler);

        // На случай если элемент из дерева перенесен не напрямую, а через окно выбора символа
        this.graphRef.addEventListener('dragleave', this.dropHandler);

        this.setState({ inited: true });
    }

    shouldComponentUpdate(nextProps: TEditorProps, nextState: TEditorState) {
        this.softRender(nextProps);

        if (!nextProps.params || !this.props.params) {
            return false;
        }

        // после открытия новой вкладки через диалоговое окно (например создание модели)
        // возвращаем модели, на которой было открыто диалоговое окно, возможность реагировать на hotkeys
        if (nextProps.activeKey.id !== nextProps.id) {
            const { keyHandler } = this.graph;
            if (keyHandler) keyHandler.setEnabled(true);

            return false;
        }

        const diffActions = changes(nextProps.actions, this.props.actions);

        if (diffActions.undo) {
            this.graph.undoManager.undo();
            this.props.onActionChange('undo', false);
        }
        if (diffActions.redo) {
            this.graph.undoManager.redo();
            this.props.onActionChange('redo', false);
        }

        if (diffActions.zoomFit) {
            this.graph.fit(0, false, 50, true, false, false);
            let scale = Math.round(this.graph.view.scale * 100);
            if (scale < 25) {
                scale = 25;
            }
            if (scale > 200) {
                scale = 200;
            }
            this.props.onActionChange('zoomFit', false);
            this.props.onParamsChange('zoomLevel', scale);
        }

        if (diffActions.focusSearch) {
            this.setState({ searchView: diffActions.focusSearch });
        }

        const diffParams = changes(nextProps.params, this.props.params);
        if (diffParams.zoomLevel) {
            this.graph.zoomTo((diffParams.zoomLevel as number) / 100);
        }

        const addedFilters = changes(nextProps.filters, this.props.filters);
        const removedFilters = changes(this.props.filters, nextProps.filters);

        if (Object.keys(removedFilters).length || Object.keys(addedFilters).length) {
            const activeObjectDefinitions = reduce(
                this.props.objectDefinitions,
                (result: ObjectDefinitionImpl[], objectDef: ObjectDefinitionImpl) => {
                    const objDefinitionFlags = reduce(
                        objectDef.attributes,
                        (attributesResult: Dictionary<string>, attributeValue: AttributeValue) => ({
                            ...attributesResult,
                            [this.props.attributeTypes.find((a) => a.id === attributeValue.typeId)?.name || '']:
                                attributeValue.value,
                        }),
                        {},
                    );
                    const flagDiffs = changes(nextProps.filters, objDefinitionFlags);

                    if (!Object.keys(flagDiffs).length) {
                        return [...result, objectDef];
                    }

                    return result;
                },
                [],
            );

            const activeCells = reduce(
                activeObjectDefinitions,
                (result: MxCell[], objectDef: ObjectDefinitionImpl) => [
                    ...result,
                    // todo: не знаю где это используется но надо проверить что работает после рефакторинга objectDefinitionService
                    ...(nodeService().findCellIdsByNodeId(objectDef.nodeId).get(this.graph.id) || []).map(
                        (cellId: string) => this.graph.getModel().getCell(cellId),
                    ),
                ],
                [],
            );

            const inactiveCells = filter(
                this.graph.getModel().cells,
                (cell: MxCell) => cell.getValue()?.type === 'object' && activeCells.indexOf(cell) === -1,
            );

            this.graph.setCellsInactive(inactiveCells);
            this.graph.setCellsActive(activeCells);
        }

        const nodeFilterChanged =
            nextProps.params.nodeFilterBase !== this.props.params.nodeFilterBase ||
            nextProps.params.nodeFilterInput !== this.props.params.nodeFilterInput ||
            nextProps.params.nodeFilterOutput !== this.props.params.nodeFilterOutput;

        if (nodeFilterChanged) {
            this.graph.nodeFilter.setFilter(
                nextProps.params.nodeFilterBase,
                nextProps.params.nodeFilterInput,
                nextProps.params.nodeFilterOutput,
            );
        }

        /* BPM-6909
          блокировка обработчика событий нажатий клавиш для АКТИВНОГО ГРАФА
          срабатывает при открытии диалогового(модального) окна свойств объекта
          P.s. для неактивных графов обработчики продолжают срабатывать
        */
        if (nextProps.hasVisibleDialogs !== this.props.hasVisibleDialogs) {
            this.graph.keyHandler.setEnabled(!nextProps.hasVisibleDialogs);
        }

        if (nextProps.activeKey.id !== nextProps.id) {
            return false;
        }

        if (!isEqual(nextProps.commentsEnabledSchemesIds, this.props.commentsEnabledSchemesIds)) {
            return true;
        }

        if (nextProps.isFormatPanelOpen !== this.props.isFormatPanelOpen) {
            return true;
        }

        if (this.props.mode !== nextProps.mode) {
            return true;
        }

        if (this.state.searchView !== nextState.searchView) {
            return true;
        }

        if (!this.state.inited && nextState.inited) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        this.props.onDeleteAutoSaveModelIntervalId(this.intervalID);
        clearInterval(this.intervalID);
        clearInterval(this.lockIntervalID);
        if (this.graphRef) {
            this.graph.destroy();
            this.props.onDestroy(this.props.model.nodeId);
            instancesBPMMxGraphMap.delete(this.props.model.nodeId);

            this.graphRef.removeEventListener('dragenter', this.dragEnterHandler);
            this.graphRef.removeEventListener('drop', this.dropHandler);
            this.graphRef.removeEventListener('dragleave', this.dropHandler);
        }
    }

    handleCheckInvisibleEdgeTypes = (movedObj: MxCell, intersectObj: MxCell) => {
        getStore().dispatch(
            checkInvisibleEdgeTypes({
                movedObj,
                intersectObj,
                graph: this.graph,
            }),
        );
    };

    handleCleanupInvisibleEdges = (hiddenEdgesToDelete: MxCell[]) => {
        // временно удаляем лишь связи с дорожками
        const swimlaneEdges = hiddenEdgesToDelete.filter(
            (edge) =>
                ComplexSymbolManager.isHiddenEdgeConnectableObject(edge.source?.value, this.props.symbols) ||
                ComplexSymbolManager.isHiddenEdgeConnectableObject(edge.target?.value, this.props.symbols),
        );

        getStore().dispatch(deleteAction(swimlaneEdges, this.graph.id));
    };

    initGraphAccordingMetaInfo() {
        const metaInfoList = this.graph.modelType && this.graph.modelType.metaInfoList;

        if (metaInfoList && metaInfoList!.length > 0) {
            // TODO refactoring before merge
            const tableView = metaInfoList.filter((meta) => meta.name === 'tableView');

            if (!isUndefined(tableView[0])) {
                const tableMetaInfo = JSON.parse(tableView[0].metaInfo);
                const psdDiagram = new BPMPSDDiagram(tableMetaInfo, true, this.graph);

                this.graph.setPsdDiagramHandler(psdDiagram);
                this.graph.setExtendParentsOnAdd(false);
            } else {
                const bpmn2Diagram = new BPMN2Diagram(this.graph);

                this.graph.setBpmn2DiagramHandler(bpmn2Diagram);
            }
        }
    }

    onDrop(files: FileList) {
        const { onFileDrop } = this.props;
        if (files.length && onFileDrop) {
            onFileDrop(files[0]);
        }
    }

    getPngBaseUrl(): string {
        return this.canvas.toDataURL();
    }

    updateHandler = () => {
        this.props.onParamsChange('undoManager', {
            undo: this.graph.undoManager.canUndo(),
            redo: this.graph.undoManager.canRedo(),
        });
    };

    updateUndoRedoState() {
        this.graph.getModel().addListener(MxEvent.UNDO, this.updateHandler);
        this.graph.undoManager.addListener(MxEvent.UNDO, this.updateHandler);
        this.graph.undoManager.addListener(MxEvent.REDO, this.updateHandler);
    }

    dragEnterHandler = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target as HTMLElement;
        target.classList.add(theme.currentDraggedArea);

        const { draggedNode, creatableSymbols, onDragNode } = this.props;
        onDragNode(draggedNode, creatableSymbols);
    };

    dropHandler = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target as HTMLElement;
        target.classList.remove(theme.currentDraggedArea);
    };

    initGraphRef = (element: HTMLDivElement) => {
        this.graphRef = element;
    };

    softRender = (props: TEditorProps) => {
        this.graph.gridEnabled = props.isGridEnabled;
        const deleteAllGridClasses = () => {
            this.graphRef.classList.remove(theme.graph_withGrid);
            this.graphRef.classList.remove(theme.line);
            this.graphRef.classList.remove(theme.A4Landscape);
            this.graphRef.classList.remove(theme.A4Portrait);
            this.graphRef.classList.remove(theme.A3Landscape);
            this.graphRef.classList.remove(theme.A3Portrait);
        };
        if (props.isGridEnabled) {
            deleteAllGridClasses();

            if (props.gridType === GridTypeOnCanvas.dot) {
                this.graphRef.classList.add(theme.graph_withGrid);
                this.graphRef.style.setProperty('--canvas-dot-space', `${props.dotSpace}px`);
            }

            if (props.gridType === GridTypeOnCanvas['A4/PORTRAIT']) {
                this.graphRef.classList.add(theme.line);
                this.graphRef.classList.add(theme.A4Portrait);
            }

            if (props.gridType === GridTypeOnCanvas['A4/LANDSCAPE']) {
                this.graphRef.classList.add(theme.line);
                this.graphRef.classList.add(theme.A4Landscape);
            }

            if (props.gridType === GridTypeOnCanvas['A3/PORTRAIT']) {
                this.graphRef.classList.add(theme.line);
                this.graphRef.classList.add(theme.A3Portrait);
            }

            if (props.gridType === GridTypeOnCanvas['A3/LANDSCAPE']) {
                this.graphRef.classList.add(theme.line);
                this.graphRef.classList.add(theme.A3Landscape);
            }
        } else {
            deleteAllGridClasses();
        }
    };

    onClear = (cell: MxCell) => {
        viewCellsActive(this.graph);

        if (cell) {
            this.graph.getSelectionModel().removeCell(cell);
        }

        this.props.onActionChange('focusSearch', false);
        this.setState({ searchView: false });
    };

    initSymbols() {
        symbolsService.applyStylesToGraph(this.graph, this.props.symbols);
    }

    changeCommentsTabSizeHandler = (size: number) => {
        LocalStorageDaoService.setCommentsTabSize(size);
    };

    render() {
        const editor = (
            <div className={theme.editorPanel}>
                <FileDrop
                    onDrop={(files: any) => {
                        this.onDrop(files);
                    }}
                >
                    <div tabIndex={-1} className={theme.graph} ref={this.initGraphRef} />
                </FileDrop>
            </div>
        );
        const isActiveRightPanel = this.props.isCommentsPanelOpen || this.props.isFormatPanelOpen;

        const isShowFormatPanel: boolean = this.graph && this.props.isFormatPanelOpen;
        const isShowCommentsPanel: boolean = this.graph && this.props.isCommentsPanelOpen;

        const comments = (
            <div className={isShowCommentsPanel ? theme.commentsPanel : undefined}>
                {isShowCommentsPanel && <CommentsPanelContainer modelNodeId={this.props.model.nodeId} />}
            </div>
        );

        const formatPanel = (
            <div className={isShowFormatPanel ? theme.formatPanel : undefined}>
                {<FormatPanel modelId={this.props.model.nodeId} />}
            </div>
        );

        const isShowSearch = this.state.searchView && this.props.mode === EditorMode.Read;
        const searchableCells = this.graph && getSearchableCells(this.graph, true);

        return (
            <section
                data-test="graph-editor_container"
                className={theme.container}
                ref={(element: HTMLElement) => (this.containerElement = element)}
            >
                {isShowSearch && <Search searchableCells={searchableCells} graph={this.graph} onClear={this.onClear} />}
                {
                    // @ts-ignore
                    <SplitPane
                        split="vertical"
                        primary="second"
                        defaultSize={isActiveRightPanel ? this.defaultRightPanelSize || 250 : 0}
                        maxSize={isActiveRightPanel ? this.maxRightPanelSize || 250 : 0}
                        minSize={isActiveRightPanel ? this.minRightPanelSize || 250 : 0}
                        paneStyle={{ overflow: 'auto' }}
                        resizerClassName={isActiveRightPanel ? undefined : theme.hideResizer}
                        pane2Style={{ borderLeft: '1px solid #d9d9d9', zIndex: 1, borderRadius: '0px' }}
                        onChange={this.changeCommentsTabSizeHandler}
                    >
                        {editor}
                        {isActiveRightPanel ? (this.props.isCommentsPanelOpen ? comments : formatPanel) : null}
                    </SplitPane>
                }
            </section>
        );
    }
}

const IntlComponent = injectIntl(Editor);

export { IntlComponent as Editor };
