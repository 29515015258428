import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeItemContext, TreeItemType } from '../../models/tree';
import { TreeNode } from '@/models/tree.types';

export const LEVEL_PADDING = 9;

export const getTreeItemContext = (
    lastOpenDialogType?: DialogType,
    moreThanOneElementSelected?: boolean,
    twoModelsSelected?: boolean,
): TreeItemContext => {
    let context = TreeItemContext.Default;
    let dialogType: string | undefined = lastOpenDialogType;

    if (moreThanOneElementSelected) {
        if (twoModelsSelected) {
            context = TreeItemContext.TwoModelsSelected;
        } else {
            dialogType = DialogType.EVENT_SCRIPT_DIALOG;
        }
    }

    switch (dialogType) {
        case DialogType.OBJECT_DECOMPOSITION_CREATE:
            context = TreeItemContext.CreateDecompositionDialog;
            break;

        case DialogType.MODEL_DIALOG:
            context = TreeItemContext.CreateModelDialog;
            break;

        case DialogType.EVENT_SCRIPT_DIALOG:
            context = TreeItemContext.CreateScriptDialog;
            break;

        default: {
            return context;
        }
    }

    return context;
};

export const allowedNodeSelection = (
    selectedTreeItem: TreeNode,
    includeFilterForSelect?: TreeItemType[],
    excludeFilterForSelect?: TreeItemType[],
): boolean => {
    let allowedSelection = true;
    if (includeFilterForSelect && excludeFilterForSelect) {
        allowedSelection =
            includeFilterForSelect.includes(selectedTreeItem.type) &&
            !excludeFilterForSelect.includes(selectedTreeItem.type);
    } else {
        if (includeFilterForSelect) allowedSelection = includeFilterForSelect.includes(selectedTreeItem.type);
        if (excludeFilterForSelect) allowedSelection = !excludeFilterForSelect.includes(selectedTreeItem.type);
    }

    return allowedSelection;
};
