import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { TFormatPanel } from '../reducers/formatPanel.reducer.types';
import { NodeId } from '../serverapi/api';
import { compareNodeIds } from '../utils/nodeId.utils';

export namespace FormatPanelSelectors {
    const getState = (state: TRootState) => state.formatPanel;

    export const getOpenFormatModelIs = createSelector<TRootState, TFormatPanel, NodeId[]>(
        getState,
        (state) => state.modelsWithOpenFormatPanel,
    );

    export const isFormatPanelOpenByModelId = (modelId: NodeId) =>
        createSelector(
            getOpenFormatModelIs,
            (modelIds) => !!modelIds.find((openFormatModelId) => compareNodeIds(openFormatModelId, modelId)),
        );
}
