import { RenameObjectDialogContainer } from '../dialogs/RenameObjectDialog/RenameObjectDialog.container';
import { AboutAppDialogContainer } from '../AboutAppDialog/containers/AboutAppDialog.container';
import AddUserToGroupDialogContainers from '../AdminTools/AddUserToGroupDialog/containers/AddUserToGroupDialog.containers';
import AssignUserGroupsDialogContainer from '../AdminTools/AssignUserGroupsDialog/containers/AssignUserGroupsDialog.container';
import AssignUserLicenseDialogContainer from '../AdminTools/AssignUserLicenseDialog/containers/AssignUserLicenseDialog.container';
import { FileUploadPresetDialogContainer } from '../AdminTools/Methodology/containers/FileUploadPresetDialog.container';
import { ImportPresetDialogContainer } from '../AdminTools/Methodology/containers/ImportPresetDialog.container';
import { UploadSymbolSvgDialogContainer } from '../AdminTools/Methodology/containers/Presets/ObjectType/dialog/FileUploadSymbolSvgDialog.container';
import RemoveUserFromGroupDialogContainer from '../AdminTools/RemoveUserFromGroupDialog/containers/RemoveUserFromGroupDialog.container';
import UserAccessPermissionsDialogContainer from '../AdminTools/UserAccessPermissionsDialog/containers/UserAccessPermissionsDialog.container';
import UserDBAccessDialogContainer from '../AdminTools/UserDBAccessEditDialog/containers/UserDBAccessDialog.container';
import UserSessionsHistoryDialogContainer from '../AdminTools/UserSessionsHistoryDialog/containers/UserSessionsHistoryDialog.container';
import { AttributesDialog } from '../AttributesDialog/components/AttributesDialog.component';
import { ChoseSymbolsForDragDialogContainer } from '../ChoseSymbolForDragDialog/containers/ChoseSymbolForDragDialog.container';
import { DeleteCommentDialogContainer } from '../dialogs/DeleteCommentDialog/DeleteCommentDialog.container';
import { DeleteNodeDialogContainer } from '../dialogs/DeleteNodeDialog/DeleteNodeDialog.container';
import { ImageUploadDialogContainer } from '../FileUpload/containers/ImageUploadDialog.container';
import { RefreshPageDialogContainer } from '../dialogs/RefreshPageDialog/RefreshPageDialog.container';
import { TreeItemSelectDialogContainer } from '../dialogs/TreeItemSelectDialog/TreeItemSelectDialog.container';
import { FileUploadImportDialogContainer } from '../FileUpload/containers/FileUploadImportDialog.container';
import { FileUploadLicenseDialogContainer } from '../FileUpload/containers/FileUploadLicenseDialog.container';
import { FileUploadScriptParamDialogContainer } from '../FileUpload/containers/FileUploadScriptParamDialog.container';
import { FolderDialog } from '../FolderDialog/FolderDialog.component';
import { ImportDialogContainer } from '../ImportDialog/containers/ImportDialog/ImportDialog.container';
import { ImportRepositoryDialogContainer } from '../ImportDialog/containers/ImportDialog/ImportRepositoryDialog.container';
import { ImportRepositoryReplaceDialogContainer } from '../ImportDialog/containers/ImportDialog/ImportRepositoryReplaceDialog.container';
import { ScriptExecuteWithParamsDialogContainer } from '../MainMenu/components/ScriptExecuteDialog/ScriptExecuteDialog.container';
// import { MatrixCreateDialog } from '../Matrix/MatrixCreateDialog/MatrixCreateDialog.component';
import { MatrixSettingsDialog } from '../Matrix/MatrixEditor/MatrixSettingsDialog/MatrixSettingsDialog.component';
import { ModelDialogContainer } from '../ModelDialog/containers/ModelDialog/ModelDialog.container';
import { DatabaseCreateDialogContainer } from '../Models/containers/DatabaseCreateDialog.container';
import { ObjectDecompositonDialogContainer } from '../Models/containers/DecompositionDialog.container';
import { DecompositionsListDialogContainer } from '../Models/containers/DecompositionsListDialog.container';
import { ModelHistoryDialogContainer } from '../Models/containers/ModelHistoryDialog.container';
import { ObjectDefinitionDialogContainer } from '../Models/containers/ObjectDefinitionDialog.container';
import { EdgeManagementDialogContainer } from '../Models/containers/EdgeManagementDialog.container';
import { ObjectPropertiesDialogContainer } from '../ObjectPropertiesDialog/containers/ObjectPropertiesDialog.container';
import { PrincipalAttributeDialogContainer } from '../ObjectPropertiesDialog/containers/PrincipalAttributeDialog.container';
import { ChoosePrincipalDialogContainer } from '../Permissions/containers/ChoosePrincipalDialog.container';
import { InstancePermissionsDialogContainer } from '../Permissions/containers/InstancePermissionsDialog.container';
import { PsdTableDeleteConfirmationDialogContainer } from '../PsdTableDeleteConfirmationDialog/container/PsdTableDeleteConfirmationDialog.container';
import { ScriptDialog } from '../Script/ScriptCreateDialog/ScriptDialog.component';
import { SearchPathDialog } from '../Search/SearchPathDialog/SearchPathDialog.component';
import { ServerAuthDialogContainer } from '../ServerForm/container/ServerAuthDialog.container';
import { ServerEditDialogContainer } from '../ServerForm/container/ServerEditDialog.container';
import { SimulationDialogContainer } from '../SimulationModeling/containers/NewSimulationNodeDialog.container';
// import { WikiCreateDialog } from '../Wiki/WikiCreateDialog/WikiCreateDialog.component';
import { FileUploadImportVisioDialogContainer } from '../FileUpload/containers/FileUploadImportVisioDialog.container';
import { EditImportVisioPageHeaderDialogComponent } from '../Import/Visio/components/EditImportVisioPageHeaderDialogComponent/EditImportVisioPageHeaderDialogComponent';
import { ImportVisioErrorDialog } from '../Import/Visio/components/ImportVisioErrorDialog/ImportVisioErrorDialog';
import { SymbolTypeFloatingAttributesDialogContainer } from '../FloatingAttributes/containers/SymbolTypeFloatingAttributesDialog.container';
import { ImportVisioDBConflictDialog } from '../Import/Visio/components/ImportVisioDBConflictDialog/ImportVisioDBConflictDialog';
import { SelectObjectDefinitionContainer } from '../ModelDialog/components/SelectExistingDefinitionDialogs/containers/SelectObjectDefinitionDialog.container';
import { FileUploadDialogContainer } from '../FileUpload/containers/FileUploadDialog.container';
import CreateInvisibleEdgeDialog from '../dialogs/CreateInvisibleEdgeDialog/CreateInvisibleEdgeDialog.component';
import { WikiTableDialogContainer } from '../MainMenu/containers/WikiTableDialog.container';
import { AddUserProfileDialog } from '../AdminTools/AddUserProfile/AddUserProfileDialog.component';
import { CloneModelDialogContainer } from '../CloneModelDialog/containers/CloneModelDialog.container';
import { EdgeTypeFloatingAttributesDialogContainer } from '../FloatingAttributes/containers/EdgeTypeFloatingAttributesDialog.container';
import { ExportDialog } from '../dialogs/ExportDialog/ExportDialog.component';
import { DialogType } from './DialogRoot.constants';
import { ServerProfileSwitcherContainer } from '../ServerProfile/containers/ServerProfileSwitcherContainer';
import SpreadsheetDialog from '../SpreadsheetDialog/SpreadsheetDialog.component';
import { SelectObjectMovelMoveContainer } from '../ModelDialog/components/SelectExistingDefinitionDialogs/containers/SelectObjectModelMoveDialog.container';
import { ModelLinkDialog } from '../ModelLinkDialog/ModelLinkDialog.component';
import { CopyDialog } from '../CopyDialog/CopyDialog.component';
import { CreateCrossSymbolDialog } from '../CreateGraphSymbolDialog/CreateCrossSymbolDialog.component';
import AcceptAgreement from '../AcceptAgreement/AcceptAgreementDialog.component';
import SilaAcceptAgreement from '../SilaAcceptAgreement/SilaAcceptAgreementDialog.component';
import { SelectModelTypeDialog } from '../dialogs/SelectModelTypeDialog/SelectModelTypeDialog.component';
import { AddEventDialog } from '../dialogs/AddEventDialog/AddEventDialog.component';
import { WikiUploadDialogContainer } from '../MainMenu/containers/WikiUploadDialog.container';
import { AddScriptContext } from '../ObjectPropertiesDialog/components/ScriptContext/AddScriptContextDialog';
import { DiagramScriptDialog } from '../Script/DiagramScriptExececutionDialog/DiagramScriptDialog.component';
import { ChooseScriptDialog } from '../Script/DiagramScriptExececutionDialog/ChooseScriptDialog.component';
import { SaveSimulationParamsDialog } from '../SimulationModeling/components/SimulationModeling/Dialog/SaveDialog';
import { AddExternalSessionDialog } from '../dialogs/AddExternalSessionDialog/AddExternalSessionDialog.component';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog/ConfirmationDialog.component';
import { SelectEdgeDefinitionDialogContainer } from '../ModelDialog/components/SelectExistingDefinitionDialogs/containers/SelectEdgeDefinitionDialog.container';
import { NewDashboardDialog } from '../Dashboard/NewDashboardDialog/NewDashboardDialog.component';
import { DashboardCellsCountDialog } from '../Dashboard/DashboardCellsCountDialog/DashboardCellsCountDialog.component';
import { LoadArisFromExcelDialogContainer } from '../MainMenu/containers/LoadArisFromExcelContainer';
import { WriteCommentDialog } from '../Comments/components/WriteCommentDialog/WriteCommentDialog.component';
import { DeleteEditingCommentDialog } from '../dialogs/DeleteEditingCommentDialog/DeleteEditingCommentDialog.component';
import { SelectObjectTreeDialogContainer } from '../ModelDialog/components/SelectExistingDefinitionDialogs/containers/SelectObjectTreeDialog.container';
import { WidgetSelectTableDialog } from '../Dashboard/WidgetSettingsPanel/WidgetSelectTableDialog/WidgetSelectTableDialog.component';
import { DeleteWidgetDialog } from '../Dashboard/DeleteWidgetDialog/DeleteWidgetDialog.component';
import { EraseDeletedNodeDialogContainer } from '../dialogs/EraseDeletedNodeDialog/EraseDeletedNodeDialog.container';
import { RestoreDeletedNodeDialogContainer } from '../dialogs/RestoreDeletedNodeDialog/RestoreDeletedNodeDialog.container';
import { LinkDialog } from '../dialogs/WikiLinkDialog/WikiLinkDialog.component';
import { SelectTreeItemAddFavoriteDialog } from '../Workspace/components/SelectTreeItemAddFavoriteDialog/SelectTreeItemAddFavoriteDialog.component';
import { SelectTreeItemApprovalDialog } from '../AdminTools/ApprovalsTable/SelectTreeItemApprovalDialog/SelectTreeItemApprovalDialog.component';
import { NotificationTemplatesDialog } from '../dialogs/NotificationTemplatesDialog/NotificationTemplatesDialog.component';
import { GroupCreateDialog } from '../AdminTools/Methodology/components/Presets/Dialog/GroupCreateDialog/GroupCreateDialog.component';
import { NotificationSettingsDialog } from '../dialogs/NotificationSettingsDialog/NotificationSettingsDialog.component';
import { MessageContentDialog } from '../dialogs/MessageContentDialog/MessageContentDialog.component';
import { CheckBrowserDialog } from '../dialogs/CheckBrowserDialog/CheckBrowserDialog.component';
import { CreatePresetImageDialog } from '../AdminTools/Methodology/components/Presets/Dialog/CreatePresetImageDialog/CreatePresetImageDialog.component';
import { ScheduleScriptDialog } from '../dialogs/ScheduleScriptDialog/ScheduleScriptDialog.component';
import { SelectScriptToScheduleDialog } from '../dialogs/SelectScriptToScheduleDialog/SelectScriptToScheduleDialog.component';
import { ApprovalTemplatesDialog } from '../AdminTools/ApprovalTemplates/ApprovalTemplatesDialog/ApprovalTemplatesDialog.component';
import { PrintDialog } from '../dialogs/PrintDialog/PrintDialog.component';
import { DeleteConfirmationDialog } from '../AdminTools/Methodology/components/Presets/Dialog/DeleteConfirmationDialog/DeleteConfirmationDialog.component';
import { ImageDownloadSettingsDialog } from '../ImageSettingsDialog/ImageDownloadSettingsDialog.component';
import { MatrixHeaderRenameDialog } from '../Matrix/MatrixEditor/MatrixRenameDialog/MatrixRenameDialog.component';
import { ApprovalDialogContainer } from '../ApprovalDialog/ApprovalDialogContainer.component';
import { ApprovalDialogTemplateContainer } from '../ApprovalDialog/ApprovalDialogTemplateContainer.component';
import { MatrixCellSymbolsSettingsDialog } from '../Matrix/MatrixEditor/MatrixCellSymbolsSettingsDialog/MatrixCellSymbolsSettingsDialog.component';
import { RenameGraphCellDialogContainer } from '../dialogs/RenameObjectDialog/RenameGraphCellDialog.contaier';
import { ChangePasswordDialog } from '../UserAccountTab/Dialog/ChangePasswordDialog';
import { SelectSymbolToReplaceOnPasteDialog } from '../SelectSymbolToReplaceOnPasteDialog/SelectSymbolToReplaceOnPasteDialog';
import CopyDatabaseDialog from '../CopyDatabaseDialog/CopyDatabaseDialog.component';
import EPCSelectRowTitleDialogComponent from '../dialogs/EPCSelectRowTitleDialog/EPCSelectRowTitleDialog.component';
import { MatrixCreateDefinitionDialog } from '../Matrix/MatrixEditor/MatrixCreateDefinitionDialog/MatrixCreateDefinitionDialog.component';
import { MatrixEdgesListDialog } from '../Matrix/MatrixEditor/MatrixEdgesListDialog/MatrixEdgesListDialog.component';
import { DeleteSeveralNodesDialog } from '../dialogs/DeleteSeveralNodesDialog/DeleteSeveralNodesDialog.component';
import { DeleteSeveralNodesErrorDialog } from '../dialogs/DeleteSeveralNodesDialog/DeleteSeveralNodesErrorDialog.component';
import { CreateModelTemplateDialog } from '../CreateModelTemplateDialog/CreateModelTemplateDialog.component';
import { SelectModelToCompareDialog } from '../SelectModelToCompareDialog/SelectModelToCompareDialog';
import { DbSearchDialog } from '../Search/DbSearchDialog/DbSearchDialog.component';
import { WikiTocSettingsDialog } from '../dialogs/WikiTocSettingsDialog/WikiTocSettingsDialog.component';
import { ImageLinkDialog } from '../dialogs/WikiImageLinkDialog/WikiImageLinkDialog.component';
import { ExportRepositoryDialog } from '../dialogs/ExportRepositoryDialog/ExportRepositoryDialog.component';
import { AttributeSelectDialog } from '../Report/ReportEditor/ReportEditorSidebar/ColumnSettings/AttributeSelectDialog/AttributeSelectDialog.component';

export const dialogComponents: {} = {
    [DialogType.MODEL_HISTORY]: ModelHistoryDialogContainer,
    [DialogType.SERVER_EDIT]: ServerEditDialogContainer,
    [DialogType.AUTHORIZATION]: ServerAuthDialogContainer,
    [DialogType.PROPERTIES_DIALOG]: ObjectPropertiesDialogContainer,
    [DialogType.PRICIPAL_ATTRIBUTE_DIALOG]: PrincipalAttributeDialogContainer,
    [DialogType.DECOMPOSITIONS_LIST_DIALOG]: DecompositionsListDialogContainer,
    [DialogType.OBJECT_DECOMPOSITION_CREATE]: ObjectDecompositonDialogContainer,
    [DialogType.EDIT_SYMBOL_FLOATING_ATTRIBUTES_DIALOG]: SymbolTypeFloatingAttributesDialogContainer,
    [DialogType.EDIT_EDGE_TYPE_FLOATING_ATTRIBUTES_DIALOG]: EdgeTypeFloatingAttributesDialogContainer,
    [DialogType.DATABASE_CREATE]: DatabaseCreateDialogContainer,
    [DialogType.ATTRIBUTES_DIALOG]: AttributesDialog,
    [DialogType.OBJECT_DEFINITION_CREATE]: ObjectDefinitionDialogContainer,
    [DialogType.EDGE_MANAGEMENT_DIALOG]: EdgeManagementDialogContainer,
    [DialogType.FOLDER_DIALOG]: FolderDialog,
    [DialogType.FILE_FOLDER_DIALOG]: FolderDialog,
    [DialogType.SIMULATION_DIALOG]: SimulationDialogContainer,
    [DialogType.DASHBOARD_CREATE_DIALOG]: NewDashboardDialog,
    [DialogType.DASHBOARD_DELETE_WIDGET_DIALOG]: DeleteWidgetDialog,
    [DialogType.DASHBOARD_CELLS_COUNT_DIALOG]: DashboardCellsCountDialog,
    [DialogType.DASHBOARD_SELECT_TABLE_DIALOG]: WidgetSelectTableDialog,
    [DialogType.MODEL_DIALOG]: ModelDialogContainer,
    [DialogType.CLONE_MODEL_DIALOG]: CloneModelDialogContainer,
    [DialogType.INSTANCE_PERMISSIONS_DIALOG]: InstancePermissionsDialogContainer,
    [DialogType.CHOOSE_PRINCIPAL_DIALOG]: ChoosePrincipalDialogContainer,
    [DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG]: PsdTableDeleteConfirmationDialogContainer,
    [DialogType.CONFLICT_RESOLUTION_DIALOG]: ImportDialogContainer,
    [DialogType.IMPORT_PRESET_DIALOG]: ImportPresetDialogContainer,
    [DialogType.NEW_REPOSITORY_NAME_DIALOG]: ImportRepositoryDialogContainer,
    [DialogType.REPLACE_CONFIRMATION_DIALOG]: ImportRepositoryReplaceDialogContainer,
    [DialogType.SCRIPT_CREATE_DIALOG]: ScriptDialog,
    [DialogType.DIAGRAM_EXECUTE_SCRIPT_DIALOG]: DiagramScriptDialog,
    [DialogType.EVENT_SCRIPT_DIALOG]: ChooseScriptDialog,
    [DialogType.SCRIPT_EXECUTE_DIALOG]: ScriptExecuteWithParamsDialogContainer,
    [DialogType.SCRIPT_UPLOAD_PARAM_FILE_DIALOG]: FileUploadScriptParamDialogContainer,
    [DialogType.CHOOSE_SYMBOL_FOR_DRAG_DIALOG]: ChoseSymbolsForDragDialogContainer,
    [DialogType.LINK_DIALOG]: LinkDialog,
    [DialogType.TREE_ITEM_SELECT_DIALOG]: TreeItemSelectDialogContainer,
    [DialogType.IMAGE_UPLOAD_DIALOG]: ImageUploadDialogContainer,
    [DialogType.IMAGE_UPLOAD_DIALOG_WIKI]: WikiUploadDialogContainer,
    [DialogType.TABLE_EDIT_DIALOG_WIKI]: WikiTableDialogContainer,
    [DialogType.DELETE_NODE_DIALOG]: DeleteNodeDialogContainer,
    [DialogType.DELETE_SEVERAL_NODES_DIALOG]: DeleteSeveralNodesDialog,
    [DialogType.DELETE_SEVERAL_NODES_ERROR_DIALOG]: DeleteSeveralNodesErrorDialog,
    [DialogType.DELETE_COMMENT_DIALOG]: DeleteCommentDialogContainer,
    [DialogType.DELETE_EDITING_COMMENT_DIALOG]: DeleteEditingCommentDialog,
    [DialogType.MATRIX_SETTINGS_DIALOG]: MatrixSettingsDialog,
    [DialogType.MATRIX_CREATE_DEFINITION_DIALOG]: MatrixCreateDefinitionDialog,
    [DialogType.MATRIX_EDGES_LIST_DIALOG]: MatrixEdgesListDialog,
    [DialogType.MATRIX_CELL_SYMBOLS_SETTINGS_DIALOG]: MatrixCellSymbolsSettingsDialog,
    [DialogType.IMPORT_UPLOAD_DIALOG]: FileUploadImportDialogContainer,
    [DialogType.FILE_UPLOAD_DIALOG]: FileUploadDialogContainer,
    [DialogType.ABOUT_APP_DIALOG]: AboutAppDialogContainer,
    [DialogType.MODEL_LINK_DIALOG]: ModelLinkDialog,
    [DialogType.COPY_ITEM_DIALOG]: CopyDialog,
    [DialogType.UPLOAD_LICENSE_DIALOG]: FileUploadLicenseDialogContainer,
    [DialogType.UPLOAD_PRESET_DIALOG]: FileUploadPresetDialogContainer,
    [DialogType.UPLOAD_SYMBOL_SVG_DIALOG]: UploadSymbolSvgDialogContainer,
    [DialogType.EDIT_ASSIGN_USER_LICENSE]: AssignUserLicenseDialogContainer,
    [DialogType.EDIT_USER_FUNCTIONAL_PERMISSIONS]: UserAccessPermissionsDialogContainer,
    [DialogType.EDIT_USER_DBACCESS_PERMISSIONS]: UserDBAccessDialogContainer,
    [DialogType.EDIT_ASSIGN_USER_GROUPS]: AssignUserGroupsDialogContainer,
    [DialogType.USERS_SESSIONS_HISTORY]: UserSessionsHistoryDialogContainer,
    [DialogType.ADD_USER_TO_GROUP]: AddUserToGroupDialogContainers,
    [DialogType.ADD_USER_PROFILE]: AddUserProfileDialog,
    [DialogType.REMOVE_USER_FROM_GROUP]: RemoveUserFromGroupDialogContainer,
    [DialogType.REFRESH_PAGE_DIALOG]: RefreshPageDialogContainer,
    [DialogType.PRINT_DIALOG]: PrintDialog,
    [DialogType.SEARCH_PATH]: SearchPathDialog,
    [DialogType.UPLOAD_VISIO_FILES_DIALOG]: FileUploadImportVisioDialogContainer,
    [DialogType.EDIT_IMPORT_VISIO_PAGE_HEADER]: EditImportVisioPageHeaderDialogComponent,
    [DialogType.IMPORT_VISIO_ERROR]: ImportVisioErrorDialog,
    [DialogType.RENAME_OBJECT_DIALOG]: RenameObjectDialogContainer,
    [DialogType.RENAME_GRAPH_CELL_DIALOG]: RenameGraphCellDialogContainer,
    [DialogType.IMPORT_VISIO_DB_CONFLICT]: ImportVisioDBConflictDialog,
    [DialogType.SELECT_OBJECT_DIALOG]: SelectObjectDefinitionContainer,
    [DialogType.SELECT_EDGE_DIALOG]: SelectEdgeDefinitionDialogContainer,
    [DialogType.CHECK_OBJECT_DIALOG]: SelectObjectTreeDialogContainer,
    [DialogType.CREATE_INVISIBLE_EDGE_DIALOG]: CreateInvisibleEdgeDialog,
    [DialogType.EXPORT_DIALOG]: ExportDialog,
    [DialogType.SERVER_PROFILE_CHANGE]: ServerProfileSwitcherContainer,
    [DialogType.SPREADSHEET_DIALOG]: SpreadsheetDialog,
    [DialogType.SELECT_OBJECT_FOR_MODEL_DECOMPOSITION]: SelectObjectMovelMoveContainer,
    [DialogType.CROSS_SYMBOL_CREATE_DIALOG]: CreateCrossSymbolDialog,
    [DialogType.ACCEPT_AGREEMENT]: AcceptAgreement,
    [DialogType.ACCEPT_SILA_AGREEMENT]: SilaAcceptAgreement,
    [DialogType.SELECT_MODEL_TYPE]: SelectModelTypeDialog,
    [DialogType.EVENT_DIALOG]: AddEventDialog,
    [DialogType.SAVE_SIMULATION_PARAMS_DIALOG]: SaveSimulationParamsDialog,
    [DialogType.ADD_SCRIPT_CONTEXT]: AddScriptContext,
    [DialogType.ADD_EXTERNAL_SESSION]: AddExternalSessionDialog,
    [DialogType.CONFIRMATION]: ConfirmationDialog,
    [DialogType.APPROVAL_DIALOG]: ApprovalDialogContainer,
    [DialogType.APPROVAL_TEMPLATE_DIALOG]: ApprovalDialogTemplateContainer,
    [DialogType.LOAD_ARIS_FROM_EXCEL]: LoadArisFromExcelDialogContainer,
    [DialogType.WRITE_COMMENT_DIALOG]: WriteCommentDialog,
    [DialogType.ERASE_DELETED_NODE_DIALOG]: EraseDeletedNodeDialogContainer,
    [DialogType.RESTORE_DELETED_NODE_DIALOG]: RestoreDeletedNodeDialogContainer,
    [DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG]: SelectTreeItemAddFavoriteDialog,
    [DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG]: SelectTreeItemApprovalDialog,
    [DialogType.NOTIFICATION_TEMPLATES_DIALOG]: NotificationTemplatesDialog,
    [DialogType.METHODOLOGY_GROUP_CREATE_DIALOG]: GroupCreateDialog,
    [DialogType.NOTIFICATION_SETTINGS_DIALOG]: NotificationSettingsDialog,
    [DialogType.MESSAGE_CONTENT_DIALOG]: MessageContentDialog,
    [DialogType.CHECK_BROWSER_DIALOG]: CheckBrowserDialog,
    [DialogType.CREATE_PRESET_IMAGE_DIALOG]: CreatePresetImageDialog,
    [DialogType.SCHEDULE_SCRIPT_DIALOG]: ScheduleScriptDialog,
    [DialogType.SELECT_SCRIPT_TO_SCHEDULE_DIALOG]: SelectScriptToScheduleDialog,
    [DialogType.APPROVAL_TEMPLATE]: ApprovalTemplatesDialog,
    [DialogType.DELETE_CONFIRMATION]: DeleteConfirmationDialog,
    [DialogType.IMAGE_DOWNLOAD_SETTINGS_DIALOG]: ImageDownloadSettingsDialog,
    [DialogType.MATRIX_HEADER_RENAME_DIALOG]: MatrixHeaderRenameDialog,
    [DialogType.CHANGE_PASSWORD_DIALOG]: ChangePasswordDialog,
    [DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG]: EPCSelectRowTitleDialogComponent,
    [DialogType.SELECT_SYMBOL_TO_REPLACE_ON_PASTE_DIALOG]: SelectSymbolToReplaceOnPasteDialog,
    [DialogType.COPY_DATABASE_DIALOG]: CopyDatabaseDialog,
    [DialogType.CREATE_MODEL_TEMPLATE_DIALOG]: CreateModelTemplateDialog,
    [DialogType.SELECT_MODEL_TO_COMPARE_DIALOG]: SelectModelToCompareDialog,
    [DialogType.DB_SEARCH_DIALOG]: DbSearchDialog,
    [DialogType.TOC_SETTINGS_DIALOG]: WikiTocSettingsDialog,
    [DialogType.IMAGE_LINK_DIALOG]: ImageLinkDialog,
    [DialogType.EXPORT_REPOSITORY_DIALOG]: ExportRepositoryDialog,
    [DialogType.REPORT_SELECT_COLUMN_ATTRIBUTE_DIALOG]: AttributeSelectDialog,
};
