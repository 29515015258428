import React, { FC } from 'react';
import menuItemTheme from '../../../items/MenuItem.scss';
import { TSpriteSymbol } from '../../../../../../models/spriteSymbol.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

interface IToolbarButtonProps {
    tooltipTitle: string;
    disabled: boolean | undefined;
    icon: TSpriteSymbol;
    text?: string;
    onClick: () => void;
    dataTest?: string;
    containerDataTest?: string;
}

export const ToolbarButton: FC<IToolbarButtonProps> = (props) => {
    const { tooltipTitle, text, disabled, icon, dataTest = '', containerDataTest = '', onClick } = props;
    const containerTestAttr = containerDataTest ? { 'data-test': containerDataTest } : {};
    const testAttr = dataTest ? { 'data-test': dataTest } : {};

    return (
        <span {...containerTestAttr}>
            <span
                {...testAttr}
                className={menuItemTheme.buttonWrapper}
            >
                <Button onClick={onClick} disabled={disabled} tooltip={{title: tooltipTitle, position: "top", offset: 11, nowrap: true}} icon={icon} visualStyle={{type: "text", color: "grey"}}>
                    {text && <span className="title">{text}</span>}
                </Button>
            </span>
        </span>
    );
};