import type { TGeneralMenuItemProps, TGeneralToolbarProps } from './GeneralMenuItem.types';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { ScriptGeneralToolbarContainer } from '../../../containers/ScriptGeneralToolbar.container';
import { WikiEditorToolbarContainer } from '../../../../Wiki/containers/WikiEditorToolbar.container';
import { AdjustToolbarSizeComponent } from '../../AdjustToolbarSize/AdjustToolbarSize.component';
import { SpreadsheetEditorToolbar } from '../../SpreadsheetEditorToolbar/SpreadsheetEditorToolbar.component';
import { GraphGeneralToolbar } from '../../GraphGeneralToolbar/GraphGeneralToolbar.component';
import { DashboardEditorToolbar } from '../../DashboardEditorToolbar/DashboardEditorToolbar.component';
import { MatrixEditorToolbar } from '../../MatrixEditorToolbar/MatrixEditorToolbar.component';
import { ReportEditorToolbar } from '@/modules/Report/ReportEditor/ReportEditorToolbar/ReportEditorToolbar.component';
import { TxtFileEditorToolbar } from '@/modules/FileEditor/TxtFileEditorToolbar.component';
import { ImageFileViewerToolbar } from '@/modules/FileEditor/ImageFileViewerToolbar.component';

const toolbarComponents = {
    DEFAULT: GraphGeneralToolbar,
    Editor: GraphGeneralToolbar,
    ScriptEditor: ScriptGeneralToolbarContainer,
    WikiEditor: WikiEditorToolbarContainer,
    MatrixEditor: MatrixEditorToolbar,
    ReportEditor: ReportEditorToolbar,
    Spreadsheet: SpreadsheetEditorToolbar,
    Dashboard: DashboardEditorToolbar,
    TxtFileEditor: TxtFileEditorToolbar,
    ImageFileViewer: ImageFileViewerToolbar,
};

// TODO: Убрать наследование react компонента GeneralMenuItem
class GeneralMenuItem extends AdjustToolbarSizeComponent<TGeneralMenuItemProps> {
    getToolbar(toolbarName: string) {
        const { compact, activeKey } = this.props;
        const ToolbarComponent: React.ElementType<TGeneralToolbarProps> =
            toolbarComponents[toolbarName] || toolbarComponents.DEFAULT;

        return <ToolbarComponent compact={compact} nodeId={activeKey} />;
    }
}

const GeneralMenuItemWithIntl = injectIntl(GeneralMenuItem);

export { GeneralMenuItemWithIntl as GeneralMenuItem };
