import React from 'react';
import menuItemTheme from '../MainMenu/components/items/MenuItem.scss';
import icReadMode from '../../resources/icons/ic-read-mode.svg';
import { Button } from 'antd';
import classNames from 'classnames';
import { Icon } from '../UIKit';

export const ImageFileViewerToolbar = ({ compact }) => {
    const className = classNames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: compact,
    });

    return (
            <div className={className}>
                <div className={menuItemTheme.group}>
                    <div className={!compact ? menuItemTheme.modeGroup_full : undefined}>
                        <Button
                            className={menuItemTheme.modeButton}
                            disabled={true}
                        >
                            <Icon spriteSymbol={icReadMode} />
                        </Button>
                    </div>
                </div>
            </div>
    );
}
