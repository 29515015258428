import { useIntl } from 'react-intl';
import React from 'react';
import messages from '../../FormatPanel.messages';
import { useDispatch, useSelector } from 'react-redux';
import theme from './TextStyleAndDirection.scss';
import { LabelStyle } from '../../../../models/labelStyle';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { getGeneralMenuButtonsState } from '../../../../selectors/generalMenu.selectors';
import { ButtonEditLabelState } from '../../../../models/buttonEditLabelState';
import icBold from '@/resources/icons/ic-ribbon-text-bold.svg';
import icItalic from '@/resources/icons/ic-ribbon-text-italic.svg';
import icUnderline from '@/resources/icons/ic-ribbon-text-underline.svg';
import icVertical from '@/resources/icons/ic-ribbon-text-vertical.svg';
import icHorizontal from '@/resources/icons/ic-ribbon-text-horizontal.svg';
import { MxConstants } from '../../../../mxgraph';
import { TToolbarBtn } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.types';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { getFormatBtnTooltip } from '../../utils/format.utils';
import { NodeId } from '../../../../serverapi/api';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';

type TTextStyleAndDirectionBlock = { disabled?: boolean; modelId: NodeId };

export const TextStyleAndDirectionBlock = (props: TTextStyleAndDirectionBlock) => {
    const { disabled, modelId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const buttonEditLabelState: ButtonEditLabelState = useSelector(getGeneralMenuButtonsState);
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeById(modelId));

    const onChange = (value: any, isActive: boolean) => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.decorationStyle, value, isActive));
    };
    const onChangeDirection = (value: boolean) => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.textDirection, MxConstants.STYLE_HORIZONTAL, value));
    };
    const textStylesBtns: TToolbarBtn[] = [
        {
            icon: icBold,
            onClick: () => onChange(MxConstants.FONT_BOLD, buttonEditLabelState.isFontBoldSelected),
            isActive: buttonEditLabelState.isFontBoldSelected,
            disabled: disabled || isReadMode,
            dataTest: 'object-toolbar_font-text-bold',
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.textBold)),
        },
        {
            icon: icItalic,
            onClick: () => onChange(MxConstants.FONT_ITALIC, buttonEditLabelState.isFontItalicSelected),
            isActive: buttonEditLabelState.isFontItalicSelected,
            disabled: disabled || isReadMode,
            dataTest: 'object-toolbar_font-text-italic',
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.textItalic)),
        },
        {
            icon: icUnderline,
            onClick: () => onChange(MxConstants.FONT_UNDERLINE, buttonEditLabelState.isFontUnderlineSelected),
            isActive: buttonEditLabelState.isFontUnderlineSelected,
            disabled: disabled || isReadMode,
            dataTest: 'object-toolbar_font-text-underline',
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.textUnderline)),
        },
    ];
    const directionBtns: TToolbarBtn[] = [
        {
            icon: icHorizontal,
            onClick: () => onChangeDirection(true),
            isActive: buttonEditLabelState.horizontal === true,
            disabled: disabled || isReadMode,
            dataTest: 'object-toolbar_text-direction-horizontal',
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.horizontalText)),
        },
        {
            icon: icVertical,
            onClick: () => onChangeDirection(false),
            isActive: buttonEditLabelState.horizontal === false,
            disabled: disabled || isReadMode,
            dataTest: 'object-toolbar_text-direction-vertical',
            tooltip: getFormatBtnTooltip(!!disabled, isReadMode, intl.formatMessage(messages.verticalText)),
        },
    ];

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.textStyleAndDirection)}</div>
            <div className={theme.textStyleAndDirection}>
                <div className={theme.textStyle}>
                    <ButtonGroup buttons={textStylesBtns} />
                </div>
                <div className={theme.direction} data-test="object-toolbar_text-direction">
                    <ButtonGroup buttons={directionBtns} />
                </div>
            </div>
        </div>
    );
};
