import type { TreeNode } from '../../../../../../../models/tree.types';
import type {
    AttributeType,
    EdgeType,
    ModelEdgeDefinition,
    ModelType,
    Symbol,
} from '../../../../../../../serverapi/api';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { DefaultId } from '../../../../../../../serverapi/api';
import { useIntl } from 'react-intl';
import { ModelAddEdgeDialog } from '../Dialogs/ModelAddEdgeDialog.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import { DeleteSelected, TabHeader } from '../../Header/TabHeader.component';
import messages from '../ModelType.messages';
import edgeTypeDirectionMessages from '../../../../../../../models/edge-type-direction.messages';
import { Alert } from 'antd';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { SymbolsService } from '../../../../../../../services/SymbolsService';
import { SymbolConstants } from '../../../../../../../models/Symbols.constants';
import { DeleteButton, EditButton, RowButtons, SettingsButton } from '../../../../../Button/RowButtons';
import {
    addModelTypeEdges,
    removeModelTypeEdges,
} from '../../../../../../../actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { showNotificationByType } from '../../../../../../../actions/notification.actions';
import { NotificationType } from '../../../../../../../models/notificationType';
import { editModelTypeEdgeAttributesStyles } from '../../../../../../../actions/symbol/symbolEditor.actions';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsDoubleEdgeDefinition, getEdgeAvailableAttributeTypes, sortEdges } from '../utils/modelTypes.utils';
import { contains } from '../../util/preset.utils';
import { ModelEditEdgeDialog } from '../Dialogs/ModelEditEdgeDialog.component';
import theme from './EdgesTab.scss';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { isEqual } from 'lodash-es';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellProps } from 'react-virtualized';
import { Icon } from '../../../../../../UIKit';
import noData from '../../../../../../../resources/icons/noData.svg';
import { EllipsisText } from '../../../MethodologySettingPage/EllipsisText/EllipsisText.component';
import { TModelEdgeWithTableData } from './EdgesTab.types';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { WorkspaceTabDataSelectors } from '@/selectors/workspaceTabData.selectors';
import { EdgeTypeSelectors } from '@/selectors/edgeType.selectors';
import { TCheckboxStatus } from '@/modules/UIKit/components/Checkbox/Checkbox.types';

type TEdgesTabProps = {
    availableAttributeTypes: AttributeType[];
    modelType: ModelType;
    availableSymbols: Symbol[];
    serverId: string;
    presetId: string;
    serverNode: TreeNode;
};

const cellHeightCache: CellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 60,
});

const EdgesTab: FC<TEdgesTabProps> = (props) => {
    const { availableAttributeTypes, availableSymbols, modelType, serverId, serverNode } = props;
    const { presetId } = modelType;
    const modelTypeId = modelType.id;

    const modelTypeEdges: ModelEdgeDefinition[] = modelType.modelEdgeDefinitions;

    const intl = useIntl();
    const dispatch = useDispatch();

    const [addEdgeDialogVisible, setAddEdgeDialogVisible] = useState<boolean>(false);
    const [editEdgeDialogVisible, setEditEdgeDialogVisible] = useState<boolean>(false);
    const [selectedRowKeysState, setSelectedRowKeysState] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [editingEdgeDefinition, setEditingEdgeDefinition] = useState<ModelEdgeDefinition | undefined>();
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const [dataSource, setDataSource] = useState<TModelEdgeWithTableData[]>([]);

    const currentLocale = useSelector(getCurrentLocale);
    const edgeTypes: EdgeType[] = useSelector(EdgeTypeSelectors.listByPresetIdWithName(serverId, presetId));
    const { symbolsMap, edgeSymbolsMap } = useSelector(
        WorkspaceTabDataSelectors.getSymbolsMap(modelType, serverId, presetId, searchFilter, graph),
    );

    const getEdgeTypeById = (edgeTypeId: string): EdgeType | undefined =>
        edgeTypes.find((availableEdgeType) => availableEdgeType.id === edgeTypeId);

    const edgesForDelete: string | undefined = dataSource
        ?.filter((edge: TModelEdgeWithTableData) => selectedRowKeysState.some((key) => key === edge.id))
        ?.map((edge: TModelEdgeWithTableData) => `"${edge.edgeTypeName}"`)
        .join(', ');
    const deleteEdgesMessage = selectedRowKeysState.length ? (
        <Alert
            message={
                <>
                    <b>{`${intl.formatMessage(messages.deleteEdges)}: `}</b>
                    {edgesForDelete}
                </>
            }
        />
    ) : (
        ''
    );

    useEffect(() => {
        const modelEdges: ModelEdgeDefinition[] = modelTypeEdges.filter((type) => {
            const edgeType = edgeTypes.find((e) => e.id === type.edgeType);

            return contains([edgeType?.name || '', edgeType?.direction || ''], searchFilter);
        });

        const modelEdgesImages = modelEdges.map((edge) => {
            const { sourceSymbolId, destinationSymbolId, edgeSymbolId } = getImagesFromEdge(edge);
            const edgeType = getEdgeTypeById(edge.edgeType);
            const edgeTypeName = LocalesService.internationalStringToString(edgeType?.multilingualName, currentLocale);
            let waypoint = '';
            if (edgeType && edgeTypeDirectionMessages[edgeType.direction])
                waypoint = intl.formatMessage(edgeTypeDirectionMessages[edgeType.direction]);

            return {
                ...edge,
                sourceSymbolId,
                destinationSymbolId,
                edgeSymbolId,
                edgeTypeName,
                waypoint,
                checked: false,
            };
        });

        const sortedEdges: TModelEdgeWithTableData[] = sortEdges(modelEdgesImages, modelType);

        if (!isEqual(sortedEdges, dataSource)) setDataSource(sortedEdges);
    }, [modelTypeEdges, edgeTypes, searchFilter]);

    useEffect(() => {
        return graph?.destroy();
    }, []);

    const onDeleteEdges = useCallback((edgeDefs: ModelEdgeDefinition[]) => {
        dispatch(removeModelTypeEdges({ serverId, presetId, modelTypeId, modelEdgeDefinitions: edgeDefs }));
    }, []);

    const onEditEdge = useCallback(
        (editableEdgeDefinition: ModelEdgeDefinition) => {
            setEditEdgeDialogVisible(false);
            const newModelTypeEdges: ModelEdgeDefinition[] = modelType.modelEdgeDefinitions.map((modelTypeEdge) =>
                modelTypeEdge.id === editableEdgeDefinition.id ? editableEdgeDefinition : modelTypeEdge,
            );

            dispatch(addModelTypeEdges({ serverId, presetId, modelTypeId, modelEdgeDefinitions: newModelTypeEdges }));
        },
        [modelType.modelEdgeDefinitions],
    );

    const onAddEdges = useCallback(
        (edgeDefinitions: ModelEdgeDefinition[]) => {
            const filterEdgeDefinitions: ModelEdgeDefinition[] = edgeDefinitions.filter(
                (edgeDefinition: ModelEdgeDefinition) => {
                    const isDouble: boolean = checkIsDoubleEdgeDefinition(
                        modelType.modelEdgeDefinitions,
                        edgeDefinition,
                    );

                    if (isDouble) {
                        dispatch(showNotificationByType(NotificationType.REPEAT_EDGE_DEFINITION));

                        return false;
                    }

                    return true;
                },
            );

            if (filterEdgeDefinitions.length) {
                const newModelTypeEdges = [...modelType.modelEdgeDefinitions, ...filterEdgeDefinitions];

                dispatch(
                    addModelTypeEdges({ serverId, presetId, modelTypeId, modelEdgeDefinitions: newModelTypeEdges }),
                );
            }
        },
        [modelType],
    );

    const onOpenAttributeSettings = useCallback(
        (edge: ModelEdgeDefinition) => {
            const edgeType: EdgeType | undefined = modelType.edgeTypes.find((et) => et.id === edge.edgeType);

            if (edgeType) {
                const { definitionAttributes, instanceAttributes } = getEdgeAvailableAttributeTypes(
                    availableAttributeTypes,
                    edgeType,
                );

                dispatch(
                    editModelTypeEdgeAttributesStyles({
                        definitionAttributes,
                        instanceAttributes,
                        serverNode,
                        modelType,
                        edgeType,
                    }),
                );
            } else {
                dispatch(showNotificationByType(NotificationType.NONE_EXISTING_EDGE_DEFINITION));
            }
        },
        [modelType, availableAttributeTypes],
    );

    const converterInitialized = useCallback(
        (initialGraph: MethodologiesGraph) => {
            new SymbolsService().applyStylesToGraph(initialGraph, [
                {
                    ...SymbolConstants.ENDPOINT_SYMBOL,
                    name: DefaultId.DEFAULT_PRESET_ID,
                },
                SymbolConstants.ANY_ELEMENT,
            ]);

            if (!graph) {
                setGraph(initialGraph);
            }
        },
        [graph],
    );

    const getImagesFromEdge = (modelEdge: ModelEdgeDefinition) => {
        const edgeType = getEdgeTypeById(modelEdge.edgeType);

        const sourceSymbolId: string =
            modelEdge.source && symbolsMap.has(modelEdge.source)
                ? modelEdge.source
                : modelEdge.anySourceAllowed
                ? SymbolConstants.ANY_ELEMENT.id
                : SymbolConstants.ENDPOINT_SYMBOL.id;

        const destinationSymbolId: string =
            modelEdge.destination && symbolsMap.has(modelEdge.destination)
                ? modelEdge.destination
                : modelEdge.anyTargetAllowed
                ? SymbolConstants.ANY_ELEMENT.id
                : SymbolConstants.ENDPOINT_SYMBOL.id;

        return {
            sourceSymbolId,
            destinationSymbolId,
            edgeSymbolId: edgeType?.id || '',
        };
    };

    const onChangeHeaderCheckbox = (status: TCheckboxStatus) => {
        status ? setSelectedRowKeysState(dataSource.map((row) => row.id)) : setSelectedRowKeysState([]);
    };

    const onChangeRowCheckbox = (index: number) => {
        const newRow = dataSource[index].id;
        selectedRowKeysState.includes(newRow)
            ? setSelectedRowKeysState((old) => old.filter((row) => row !== newRow))
            : setSelectedRowKeysState((old) => [...old, newRow]);
    };

    const checkboxCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer
                columnIndex={0}
                cache={cellHeightCache}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div className={theme.measurerCell}>
                    <Checkbox
                        status={selectedRowKeysState.includes(dataSource[rowIndex].id) === true}
                        onChange={() => onChangeRowCheckbox(rowIndex)}
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            </CellMeasurer>
        );
    };

    const checkboxHeaderRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const isIndeterminate = selectedRowKeysState.length > 0 && selectedRowKeysState.length < dataSource.length;
        const isChecked = dataSource.length > 0 && selectedRowKeysState.length === dataSource.length;

        return (
            <CellMeasurer
                columnIndex={0}
                cache={cellHeightCache}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div className={theme.measurerCell}>
                    <Checkbox
                        status={isIndeterminate ? 'indeterminate' : isChecked}
                        onChange={(status: TCheckboxStatus) => onChangeHeaderCheckbox(status)}
                    />
                </div>
            </CellMeasurer>
        );
    };

    const sourceImageCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer
                columnIndex={1}
                cache={cellHeightCache}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div className={theme.measurerCell}>{symbolsMap.get(dataSource[rowIndex].sourceSymbolId)}</div>
            </CellMeasurer>
        );
    };

    const edgeImageCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer
                columnIndex={2}
                cache={cellHeightCache}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div className={theme.measurerCell}>
                    <div>
                        <EllipsisText text={dataSource[rowIndex].edgeTypeName} />
                    </div>
                    {edgeSymbolsMap.get(dataSource[rowIndex].edgeSymbolId)}
                </div>
            </CellMeasurer>
        );
    };

    const destinationImageCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer
                columnIndex={3}
                cache={cellHeightCache}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div className={theme.measurerCell}>{symbolsMap.get(dataSource[rowIndex].destinationSymbolId)}</div>
            </CellMeasurer>
        );
    };

    const waypointCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer
                columnIndex={4}
                cache={cellHeightCache}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div className={theme.measurerCell}>{dataSource[rowIndex].waypoint}</div>
            </CellMeasurer>
        );
    };

    const renderCellActionButtons = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const deleteContent = (
            <Alert
                message={
                    <>
                        <b>{`${intl.formatMessage(messages.deleteEdges)}: `}</b>
                        {`"${dataSource[rowIndex].edgeTypeName}"`}
                    </>
                }
                type="warning"
            />
        );

        return (
            <CellMeasurer
                columnIndex={4}
                cache={cellHeightCache}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div className={theme.measurerCell}>
                    <RowButtons
                        buttons={[
                            EditButton.build(() => {
                                setEditEdgeDialogVisible(true);
                                setEditingEdgeDefinition(dataSource[rowIndex]);
                            }),
                            SettingsButton.build(() => onOpenAttributeSettings(dataSource[rowIndex])),
                            DeleteButton.build(
                                () => onDeleteEdges([dataSource[rowIndex]]),
                                undefined,
                                undefined,
                                undefined,
                                intl.formatMessage(messages.deleteEdgesDialogTitle),
                                deleteContent,
                            ),
                        ]}
                    />
                </div>
            </CellMeasurer>
        );
    };

    return (
        <div className={theme.container}>
            {addEdgeDialogVisible && (
                <ModelAddEdgeDialog
                    edgeDefinition={editingEdgeDefinition}
                    availableSymbols={availableSymbols}
                    availableEdgeType={edgeTypes}
                    modelType={modelType}
                    graph={graph}
                    onSave={onAddEdges}
                    onCancel={() => setAddEdgeDialogVisible(false)}
                />
            )}
            {editEdgeDialogVisible && (
                <ModelEditEdgeDialog
                    modelType={modelType}
                    edgeDefinition={editingEdgeDefinition}
                    availableSymbols={availableSymbols}
                    availableEdgeType={edgeTypes}
                    graph={graph}
                    onSave={onEditEdge}
                    onCancel={() => setEditEdgeDialogVisible(false)}
                />
            )}
            <SymbolToImageConverterGraph modelType={modelType} initialized={converterInitialized} />
            <TabHeader
                buttons={[
                    {
                        children: intl.formatMessage(messages.newEdge),
                        disabled: !availableSymbols.length,
                        onClick: () => {
                            setAddEdgeDialogVisible(true);
                            setEditingEdgeDefinition(undefined);
                        },
                    },
                    DeleteSelected.build(
                        () => {
                            onDeleteEdges(
                                modelTypeEdges.filter((edge: ModelEdgeDefinition) =>
                                    selectedRowKeysState.some((key) => key === edge.id),
                                ),
                            );
                            setSelectedRowKeysState([]);
                        },
                        !selectedRowKeysState.length,
                        undefined,
                        intl.formatMessage(messages.deleteEdgesDialogTitle),
                        deleteEdgesMessage,
                    ),
                ]}
                onSearchChange={(searchFilterValue: string) => setSearchFilter(searchFilterValue)}
            />
            <div className={theme.tableWrapper}>
                <div className={dataSource.length ? theme.tableContainer : theme.emptyTableContainer}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                rowClassName={theme.tableRow}
                                headerHeight={35}
                                width={width}
                                height={height}
                                headerClassName={theme.headerRowStyle}
                                rowHeight={cellHeightCache.rowHeight}
                                rowCount={dataSource.length}
                                rowGetter={({ index }) => dataSource[index]}
                                onRowClick={({ index }) => onChangeRowCheckbox(index)}
                            >
                                <Column
                                    disableSort
                                    headerStyle={{ border: 'none' }}
                                    width={30}
                                    minWidth={30}
                                    maxWidth={30}
                                    dataKey="checkbox"
                                    headerRenderer={checkboxHeaderRenderer}
                                    cellRenderer={checkboxCellRenderer}
                                    className={theme.cellContent}
                                    headerClassName={theme.columnHeader}
                                />

                                <Column
                                    disableSort
                                    width={width}
                                    minWidth={120}
                                    label={intl.formatMessage(messages.symbolSrc)}
                                    dataKey="source"
                                    cellRenderer={sourceImageCellRenderer}
                                    className={theme.cellContent}
                                    headerClassName={theme.columnHeader}
                                />
                                <Column
                                    disableSort
                                    width={width}
                                    minWidth={150}
                                    label={intl.formatMessage(messages.connectionType)}
                                    dataKey="edgeTypeName"
                                    cellRenderer={edgeImageCellRenderer}
                                    className={theme.cellContent}
                                    headerClassName={theme.columnHeader}
                                />
                                <Column
                                    disableSort
                                    width={width}
                                    minWidth={120}
                                    label={intl.formatMessage(messages.symbolDest)}
                                    dataKey="destination"
                                    cellRenderer={destinationImageCellRenderer}
                                    className={theme.cellContent}
                                    headerClassName={theme.columnHeader}
                                />
                                <Column
                                    disableSort
                                    width={width}
                                    minWidth={120}
                                    label={intl.formatMessage(messages.waypoint)}
                                    dataKey="waypoint"
                                    cellRenderer={waypointCellRenderer}
                                    className={theme.cellContent}
                                    headerClassName={theme.columnHeader}
                                />
                                <Column
                                    width={100}
                                    minWidth={100}
                                    maxWidth={100}
                                    disableSort
                                    headerStyle={{ border: 'none' }}
                                    dataKey="groupId"
                                    cellRenderer={renderCellActionButtons}
                                    className={theme.cellContent}
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </div>
                {dataSource.length === 0 ? (
                    <div className={theme.NoDataWrapper}>
                        <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                        <div>{intl.formatMessage(messages.noData)}</div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

const withMemo = React.memo(EdgesTab);

export { withMemo as EdgesTab };
