import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { NavigatorPanel } from '../../../Navigator/components/NavigatorPanel/NavigatorPanel.component';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';
import messages from '../../FormatPanel.messages';
import { NodeId } from '../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { formatPanelClose } from '../../../../actions/formatPanel.actions';
import { FontBlock } from '../FontBlock/FontBlock.component';
import { TextStyleAndDirectionBlock } from '../TextStyleAndDirection/TextStyleAndDirection.component';
import { FontColorBlock } from '../Colors/FontColorBlock.component';
import { FillingColorBlock } from '../Colors/FillingColorBlock.component';
import { getSelectedCellIds } from '../../../../selectors/editor.selectors';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { instancesBPMMxGraphMap } from '../../../../mxgraph/bpm-mxgraph-instance-map';
import { ToolbarButtonsBllService } from '../../../../services/bll/ToolbarButtonsBllService';
import { MxCell } from '../../../../mxgraph';
import { NotationHelper } from '../../../../services/utils/NotationHelper';
import { TreeSelectors } from '../../../../selectors/tree.selectors';
import { EdgeTypeBlock } from '../EdgeStyles/EdgeTypeBlock.component';
import { LineTypeBlock } from '../EdgeStyles/LineTypeBlock.scomponent';
import { EdgeArrowBlock } from '../EdgeStyles/EdgeArrowBlock.component';
import {
    PROP_KEY_EDGE_END_ARROW,
    PROP_KEY_EDGE_START_ARROW,
} from '../../../../models/properties/accessible-properties';
import { filterSelectedCells } from '../../utils/format.utils';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';

type TFormatPanelProps = { modelId: NodeId };

export const FormatPanel = (props: TFormatPanelProps) => {
    const { modelId } = props;
    const dispatch = useDispatch();
    const intl = useIntl();
    const selectedCellsIds: string[] = useSelector(getSelectedCellIds);
    const presetId: string = useSelector(TreeSelectors.presetById(modelId));

    const graph: BPMMxGraph | undefined = useMemo(() => instancesBPMMxGraphMap.get(modelId), [modelId]);

    const rawSelectedCells: MxCell[] = useMemo(() => {
        if (!graph) return [];

        return ToolbarButtonsBllService.getCellsWorkspace(
            selectedCellsIds.map((cellId) => graph.getModel().getCell(cellId)).filter(Boolean),
        );
    }, [selectedCellsIds, graph]);
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeById(modelId));
    const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
    const isEntityEditable: boolean = NotationHelper.isEntityEditable(modelId, presetId, selectedCells);
    const countSelectedImages: number = selectedCells.filter((cell) => cell.getValue().imageId).length;
    const countSelectedComments: number = selectedCells.filter(
        (cell) => cell.getValue().type === 'CommentMarker',
    ).length;
    const hasSelectedOnlyComments: boolean = selectedCells.length === countSelectedComments;
    const hasSelectedElement: boolean = !!selectedCells.length;
    const hasSelectedOnlyImages: boolean = selectedCells.length === countSelectedImages;
    const hideStyles: boolean =
        hasSelectedOnlyImages ||
        hasSelectedOnlyComments ||
        countSelectedImages + countSelectedComments === selectedCells.length ||
        !hasSelectedElement;
    const objectEditDisabled: boolean = !isEntityEditable;
    const selectedEdges: MxCell[] = selectedCells.filter((cell) => cell.isEdge());
    const hasSelectedEdges: boolean = !!selectedEdges.length;

    return (
        <NavigatorPanel
            type={TNavigatorTab.Format}
            titleProps={{
                title: intl.formatMessage(messages.styleSetting),
            }}
            onClose={() => {
                dispatch(formatPanelClose(modelId));
            }}
        >
            {hasSelectedEdges && (
                <>
                    <LineTypeBlock
                        selectedEdges={selectedEdges}
                        isEntityEditable={isEntityEditable}
                        isReadMode={isReadMode}
                    />
                    <EdgeArrowBlock
                        selectedEdges={selectedEdges}
                        isEntityEditable={isEntityEditable}
                        arrow={PROP_KEY_EDGE_START_ARROW}
                        isReadMode={isReadMode}
                    />
                    <EdgeArrowBlock
                        selectedEdges={selectedEdges}
                        isEntityEditable={isEntityEditable}
                        arrow={PROP_KEY_EDGE_END_ARROW}
                        isReadMode={isReadMode}
                    />
                    <EdgeTypeBlock
                        selectedEdges={selectedEdges}
                        isEntityEditable={isEntityEditable}
                        isReadMode={isReadMode}
                    />
                </>
            )}
            {!hideStyles && (
                <>
                    <FontColorBlock disabled={objectEditDisabled} modelId={modelId} />
                    <FillingColorBlock selectedCells={selectedCells} disabled={objectEditDisabled} modelId={modelId} />
                    <FontBlock disabled={objectEditDisabled} modelId={modelId} />
                    <TextStyleAndDirectionBlock disabled={objectEditDisabled} modelId={modelId} />
                </>
            )}
        </NavigatorPanel>
    );
};
