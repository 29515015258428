import React, { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { useIntl } from 'react-intl';
import { DatePicker, Form, Select, Table, TimePicker } from 'antd';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import auditMessages from '../../AdminTools/ActionsAudit/audit.messages';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import iconAdd from '../../../resources/icons/ic-add.svg';
import icDelete from '../../../resources/icons/ic-delete.svg';
import editIcon from '../../../resources/icons/edit.svg';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { TValueTypeEnum } from '../../../models/ValueTypeEnum.types';
import {
    AttributeType,
    AttributeValue,
    AttributeValueMultiSelect,
    AttributeValueNode,
    AttributeValuePrincipal,
    AttributeValueString,
    AttributeValueUrl,
    FolderType,
    InternationalString,
    Node,
    NodeId,
    PresetImage,
    AttributeValueQuery,
    AttributeValueQueryMultiSelect,
    AttributeValueQuerySelect,
    UserDTOAccessesEnum,
} from '../../../serverapi/api';
import { IPropertyDescriptor, TPropertyValue } from '../../../models/properties/property-descriptor.types';
import { EditableText } from '../../UIKit/components/EditableText/EditableText.component';
import {
    checkAttributeValues,
    checkBooleanValue,
    getUsersSelectedData,
    getGroupsSelectedData,
    jsonParse,
    storageValueToString,
    formatUserName,
    sortAttributes,
} from './utils';
import {
    dateFormat,
    dateTimeFormat,
    momentDateToTimestamp,
    timestampToMomentDate,
} from '../../../utils/date.time.utils';
import { TPeriodRange } from '../../../utils/date.time.utils.types';
import { AttributesDialog } from '../../AttributesDialog/components/AttributesDialog.component';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { LocalesService } from '../../../services/LocalesService';
import { Locale } from '../../Header/components/Header/header.types';
import { edgeArrowTypeNames } from '../../../models/edge-arrow';
import {
    systemAttributeEdgeStyle,
    systemAttributeFolderType,
    systemAttributeTypeArrow,
} from '../../../models/properties/accessible-properties.constants';
import { edgeStylesNames } from '../../../models/edge-style';
import { folderTypeNames } from '../../../models/folderTypes';
import { useChangeNodeAttributeValue } from '../../../hooks/useChangeNodeAttributeValue';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';
import { TInputTypeEnum } from '../../UIKit/components/EditableText/EditableText.types';
import UserLogin from './PrincipalAttributeType/UserLogin.component';
import GroupName from './PrincipalAttributeType/GroupName.component';
import { TAttribute, TAttributeUrlValues, TAttributesTabProps } from './AttributeTab.types';
import icTreeFolder from '../../../resources/icons/ic-tree-folder.svg';
import { PresetImageSelectors } from '../../../selectors/presetSettings/presetImage.selectors';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { openAttributeLinkAction } from '../../../actions/openAttributeLink.actions';
import { getDefaultAttributeValue } from '../../AdminTools/Methodology/components/Presets/AttributeTypesTab/util/attributeTypeEditorDialog.utils';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import { QuerySelect } from '../../UIKit/components/QuerySelect/QuerySelect.component';
import { getQuerySelectAttributeTypeValues } from '../../../actions/querySelectAttributeTypeValues.actions';
import { QuerySelectSelectors } from '../../../selectors/querySelect.selectors';
import { TQueryAttributeTypeData } from '../../../reducers/querySelect.reducer.types';
import { MultiLangEditableText } from '../../UIKit/components/MultiLangEditableText/MultiLangEditableText.component';
import { AttributeValueType } from '../../FloatingAttributes/components/AttributesEditor/Attribute.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { switchFocusElement, targetIsTextInput } from '@/utils/elementFocus.utils';
import { FocusableElementWrapper } from '@/modules/ObjectPropertiesDialog/components/FocusableElementWrapper.component';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { isUserHasAccess } from '@/selectors/authorization.selectors';
import userAccessRightTypes from '@/models/userAccessRightTypes';

export const AttributesTab: FC<TAttributesTabProps> = (props) => {
    const {
        propertiesData,
        changedAttributes,
        attributeTypes,
        changedProperties,
        removed,
        repositoryId,
        graphId,
        cellId,
        tabType,
        serverId,
        disabled,
        isEntityEditable,
        isEdge,
        locale,
        onDeleteAttribute,
        onChangeAttributeValue,
        onAddAttribute,
        folderTypes = [],
        allNotationFolderTypes = [],
        nodeId,
        principals,
        isModelTypeDeleted,
        isObjectTypeDeleted,
        isFolderTypeDeleted,
        isEdgeTypeDeleted,
        modalFooterContentRef,
        tableRef,
        attributeActionsRef,
        nodeType,
    } = props;

    const intl = useIntl();
    const firstCharacteristic = 'multilingualName';
    const presetId: string | undefined = useSelector(TreeSelectors.presetById(nodeId || graphId));
    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId));
    const allAttributeTypes: AttributeType[] = useSelector(AttributeTypeSelectors.allInPreset(serverId, presetId));
    const querySelectData: TQueryAttributeTypeData = useSelector(QuerySelectSelectors.byPresetId(presetId));
    const isTsPdEditor: boolean = useSelector(
        isUserHasAccess(userAccessRightTypes.TS_PD_EDITOR as UserDTOAccessesEnum),
    );

    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);
    const profile = useSelector(UserProfileSelectors.selectUserProfileByNodeId(nodeId));

    const [selectedAttributeIds, setSelectedAttributeIds] = useState<string[]>([]);
    const [isAttributeDialogOpen, setAttributeDialogOpen] = useState<boolean>(false);
    const [isExistedFolderTypeSelected, setIsExistedFolderTypeSelected] = useState<boolean>(false);

    const handleAddAttribute = (attrTypes: AttributeType[]) => {
        const attributes = attrTypes.map((attrType) => getDefaultAttributeValue(attrType));

        setAttributeDialogOpen(false);
        onAddAttribute([...changedAttributes, ...attributes]);
    };

    const updateAttribute = (changedAttr: AttributeValue, value: any): AttributeValue => {
        if (changedAttr.valueType === 'URL') {
            const attributeValueUrl = changedAttr as AttributeValueUrl;

            const oldUrl: InternationalString | undefined = attributeValueUrl.url;
            const oldName: InternationalString | undefined = attributeValueUrl.name;
            const newUrl = checkAttributeValues(oldUrl, value, currentLocale);
            const { name } = value as AttributeValueUrl;
            const newName = name ? { ...name } : oldName;

            return {
                ...attributeValueUrl,
                value: '',
                url: typeof value === 'string' ? oldUrl : newUrl,
                name: typeof value === 'string' ? checkAttributeValues(oldName, value, currentLocale) : newName,
            } as AttributeValueUrl;
        }

        if (changedAttr.valueType === AttributeValueType.NODE) {
            const { nodeId, multilingualName, type, name } = value as Node;

            return {
                ...changedAttr,
                value: '',
                linkedNodeId: nodeId?.id,
                name: LocalesService.changeLocaleValue(multilingualName, currentLocale, name),
                nodeType: type,
                notFound: false,
            } as AttributeValueNode;
        }

        if (changedAttr.valueType === AttributeValueType.BOOLEAN) {
            return {
                ...changedAttr,
                value,
            } as AttributeValue;
        }

        if (
            changedAttr.valueType === AttributeValueType.STRING ||
            changedAttr.valueType === AttributeValueType.MULTI_STRING
        ) {
            if (typeof value === 'string') {
                return {
                    ...changedAttr,
                    value,
                    str: LocalesService.changeLocaleValue(
                        (changedAttr as AttributeValueString).str,
                        currentLocale,
                        value,
                    ),
                } as AttributeValueString;
            }

            return {
                ...changedAttr,
                value: LocalesService.internationalStringToString(value, currentLocale),
                str: value,
            } as AttributeValueString;
        }

        if (changedAttr.valueType === AttributeValueType.MULTI_SELECT) {
            const valueIds = value as string[];

            return {
                ...changedAttr,
                value: '',
                valueIds,
            } as AttributeValueMultiSelect;
        }

        if (
            [
                AttributeValueType.TIME,
                AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE,
                AttributeValueType.DATE_TIME,
                AttributeValueType.TIME_WITHOUT_TIMEZONE,
                AttributeValueType.DATE,
            ].includes(changedAttr.valueType as AttributeValueType)
        ) {
            const newTime = value as Dayjs;
            const isWithoutTimeZone: boolean = [
                AttributeValueType.TIME_WITHOUT_TIMEZONE,
                AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE,
            ].includes(changedAttr.valueType as AttributeValueType);

            return {
                ...changedAttr,
                value: (momentDateToTimestamp(newTime, isWithoutTimeZone) || '').toString(),
            };
        }

        if (changedAttr.valueType === AttributeValueType.PERIOD) {
            const from = momentDateToTimestamp?.(value?.[0]);
            const to = momentDateToTimestamp?.(value?.[1]);
            const dateObj: TPeriodRange | string =
                from && to
                    ? {
                          from: from.toString(),
                          to: to.toString(),
                      }
                    : '';

            return {
                ...changedAttr,
                value: dateObj && JSON.stringify(dateObj),
            };
        }

        if (changedAttr.valueType === AttributeValueType.PRINCIPAL) {
            return {
                ...changedAttr,
                ...value,
            } as AttributeValuePrincipal;
        }

        if (changedAttr.valueType === AttributeValueType.QUERY_SELECT) {
            return {
                ...changedAttr,
                value: '',
                attributeValueQuery:
                    (value as AttributeValueQuery[]).length === 1
                        ? { ...(value as AttributeValueQuery[])[0] }
                        : undefined,
            } as AttributeValueQuerySelect;
        }
        if (changedAttr.valueType === AttributeValueType.QUERY_MULTI_SELECT) {
            return {
                ...changedAttr,
                value: '',
                attributeValueQuery: value.length ? (value as AttributeValueQuery[]) : undefined,
            } as AttributeValueQueryMultiSelect;
        }

        return {
            ...changedAttr,
            value,
        };
    };

    const handleChangeAttributeValue = (id: string, valueType: TValueTypeEnum, value: any) => {
        const property = changedProperties[id] || propertiesData[id];
        if (property?.descriptor?.system) {
            const newChangedProperties = {
                ...changedProperties,
                [property.descriptor.key]: {
                    value: updateAttribute(property.value, value),
                    descriptor: property.descriptor,
                    graphId: graphId!,
                    cellId: cellId || '',
                },
            };
            onChangeAttributeValue(changedAttributes, newChangedProperties);
        } else {
            const isAttributeChangedAlready: boolean = changedAttributes.some((changedAttr) => changedAttr.id === id);

            // заменяем значение атрибута в changedAttributes
            const newChangedAttributes: AttributeValue[] = changedAttributes.map((changedAttr) => {
                if (changedAttr.id === id) {
                    return updateAttribute(changedAttr, value);
                }

                return changedAttr;
            });
            // если атрибут изменили первый раз после открытия окна то в changedAttributes его нет, добавляем
            // цикл выше оставляем, он копирует массив
            if (!isAttributeChangedAlready) {
                newChangedAttributes.push(updateAttribute(property.value, value));
            }
            onChangeAttributeValue(newChangedAttributes, changedProperties);
        }
    };

    const { setNewNodeId, setNodeAttributeToChange } = useChangeNodeAttributeValue(handleChangeAttributeValue);

    useEffect(() => {
        // Если тип папки отсутствует в методологии (например, она была изменена, либо
        // папка удалена), то заменяем такой тип на 'default'
        const currentFolderType = propertiesData.folderType?.value;
        const isNotationContainCurrentFolderType = allNotationFolderTypes.some(
            (ft) => ft.id === currentFolderType?.value,
        );
        if (currentFolderType && !isNotationContainCurrentFolderType) {
            handleChangeAttributeValue(currentFolderType.id, currentFolderType.valueType, 'default');
        }
    }, []);

    useEffect(() => {
        dispatch(getQuerySelectAttributeTypeValues(presetId, graphId, attributeTypes));
    }, [presetId, graphId]);

    const changeNodeAttribute = (nodeId: NodeId, nodeAttribute: AttributeValueNode): void => {
        setNodeAttributeToChange(nodeAttribute);
        setNewNodeId(nodeId);
    };

    const storageValueToComponent = ({
        record,
        editable,
        system,
        name: attributeName,
    }: {
        record: AttributeValue;
        editable: boolean;
        system: boolean;
        name?: string;
    }) => {
        const { valueType } = record;
        const isUrlType = valueType === AttributeValueType.URL || valueType === AttributeValueType.NODE;
        let inputType: TInputTypeEnum = 'text';
        if (valueType === AttributeValueType.NUMERIC || valueType === AttributeValueType.INTEGER) {
            inputType = 'number';
        } else if (valueType === AttributeValueType.MULTI_STRING) {
            inputType = 'textarea';
        }
        const onClickLink = () => dispatch(openAttributeLinkAction(record, graphId || nodeId));

        switch (valueType) {
            case AttributeValueType.STRING:
            case AttributeValueType.MULTI_STRING:
            case AttributeValueType.NUMERIC:
            case AttributeValueType.INTEGER:
            case AttributeValueType.FILE:
            case AttributeValueType.URL:
            case AttributeValueType.JSON: {
                const currentProperty: TPropertyValue | undefined = propertiesData[record.id];
                const descriptor: IPropertyDescriptor | undefined = currentProperty?.descriptor;
                const text: string | undefined =
                    (changedProperties[descriptor?.key] &&
                        storageValueToString(changedProperties[descriptor?.key]?.value, currentLocale, {
                            system,
                            attributeTypes: allAttributeTypes,
                        })) ??
                    storageValueToString(record, currentLocale, { system, attributeTypes: allAttributeTypes }) ??
                    '';

                const isDeletedElement =
                    (isModelTypeDeleted && currentProperty.value.id === 'modelTypeId') ||
                    (isObjectTypeDeleted && currentProperty.value.id === 'objectType') ||
                    (isEdgeTypeDeleted && currentProperty.value.id === 'edgeType');

                const withoutMultiLangBtn: boolean = ![
                    AttributeValueType.MULTI_STRING,
                    AttributeValueType.STRING,
                    AttributeValueType.URL,
                ].includes(record?.valueType as AttributeValueType);

                return (
                    <div
                        className={cx(theme.editableTextContainer, {
                            [theme.deletedModel]: isDeletedElement,
                        })}
                    >
                        <MultiLangEditableText
                            focusOnChange
                            disabled={disabled}
                            onChange={(value: string | InternationalString | TAttributeUrlValues) =>
                                handleChangeAttributeValue(record.id, valueType, value)
                            }
                            allowEmptyValue={isEdge || !system}
                            isUrlType={isUrlType}
                            inputType={inputType}
                            onClickLink={onClickLink}
                            editable={editable}
                            withoutMultiLang={withoutMultiLangBtn}
                            record={record}
                            clearSelecting={() => setSelectedAttributeIds([])}
                            allowMultiLangEmptyValue={
                                record?.valueType === AttributeValueType.STRING && !!propertiesData.edgeType
                            }
                            text={text}
                            className={(valueType === AttributeValueType.URL && theme.linkEditableTextContainer) || ''}
                            dataTestBtn={`${valueType}_button`}
                            dataTestContainer={`${valueType}_value`}
                        />
                    </div>
                );
            }
            case AttributeValueType.DATE_TIME:
            case AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE:
            case AttributeValueType.DATE: {
                const val = record.value;
                const isDeletedAttributeType = !allAttributeTypes.find((attrType) => attrType.id === record.typeId);
                const isDateTimeFormat: boolean =
                    valueType === AttributeValueType.DATE_TIME ||
                    valueType === AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE;
                const isWithoutTimeZone: boolean = valueType === AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE;
                return (
                    <FocusableElementWrapper className={theme.editableElementMedium}>
                        <DatePickerProvider>
                            <DatePicker
                                disabled={isDeletedAttributeType}
                                data-test="select_attribute_date_input"
                                style={{ width: '100%' }}
                                showTime={isDateTimeFormat}
                                format={isDateTimeFormat ? dateTimeFormat : dateFormat}
                                defaultValue={val ? timestampToMomentDate(+val, isWithoutTimeZone) : undefined}
                                onChange={(date: dayjs.Dayjs) => {
                                    handleChangeAttributeValue(record.id, valueType, date);
                                }}
                            />
                        </DatePickerProvider>
                    </FocusableElementWrapper>
                );
            }
            case AttributeValueType.PERIOD: {
                const periodObj = jsonParse(record.value) as TPeriodRange | null;
                const defaultRange = [
                    periodObj?.from ? timestampToMomentDate(+periodObj.from) : undefined,
                    periodObj?.to ? timestampToMomentDate(+periodObj.to) : undefined,
                ] as [dayjs.Dayjs, dayjs.Dayjs];
                const isDeletedAttributeType = !allAttributeTypes.find((attrType) => attrType.id === record.typeId);

                return (
                    <FocusableElementWrapper className={theme.editableElementLarge}>
                        <DatePickerProvider>
                            <DatePicker.RangePicker
                                disabled={isDeletedAttributeType}
                                data-test="select_attribute_period_input"
                                style={{ width: '100%' }}
                                className={theme.rangePicker}
                                format={dateFormat}
                                defaultValue={defaultRange}
                                ranges={{
                                    [intl.formatMessage(auditMessages.today)]: [dayjs(), dayjs()],
                                    [intl.formatMessage(auditMessages.currentMonth)]: [
                                        dayjs().startOf('month'),
                                        dayjs().endOf('month'),
                                    ],
                                }}
                                onChange={(dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
                                    handleChangeAttributeValue(record.id, valueType, dates);
                                }}
                            />
                        </DatePickerProvider>
                    </FocusableElementWrapper>
                );
            }
            case AttributeValueType.BOOLEAN: {
                const isChecked: boolean = checkBooleanValue(record.value);
                const isDeletedAttributeType =
                    !allAttributeTypes.find((attrType) => attrType.id === record.typeId) && record.typeId !== 'SYSTEM';
                const isDisabled: boolean =
                    (record.id === 'tradeSecret' || record.id === 'personalData') && !isTsPdEditor;

                return (
                    <Checkbox
                        disabled={isDeletedAttributeType || isDisabled}
                        dataTest="attribute-boolean_check-box"
                        status={isChecked}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(value) => handleChangeAttributeValue(record.id, valueType, value)}
                    />
                );
            }
            case AttributeValueType.TIME_WITHOUT_TIMEZONE:
            case AttributeValueType.TIME: {
                const val = record.value;
                const isDeletedAttributeType = !allAttributeTypes.find((attrType) => attrType.id === record.typeId);
                const isWithoutTimeZone: boolean = valueType === AttributeValueType.TIME_WITHOUT_TIMEZONE;

                return (
                    <FocusableElementWrapper className={theme.editableElementMedium}>
                        <DatePickerProvider>
                            <TimePicker
                                disabled={isDeletedAttributeType}
                                style={{ width: '100%' }}
                                data-test="select-time-picker-input"
                                defaultValue={val ? timestampToMomentDate(+val, isWithoutTimeZone) : undefined}
                                onChange={(time: dayjs.Dayjs) => {
                                    handleChangeAttributeValue(record.id, valueType, time);
                                }}
                            />
                        </DatePickerProvider>
                    </FocusableElementWrapper>
                );
            }
            case AttributeValueType.SELECT: {
                const currentProperty: TPropertyValue | undefined = propertiesData[record.id];
                const isDeletedElement =
                    isFolderTypeDeleted && !isExistedFolderTypeSelected && currentProperty.value.id === 'folderType';
                const attributeType = allAttributeTypes.find((attrType) => attrType.id === record.typeId);
                let values = attributeType?.selectPropertyValues;

                const currentValue = storageValueToString(record, locale as Locale, {
                    system: false,
                    attributeTypes: allAttributeTypes,
                    folderTypes: allNotationFolderTypes,
                });

                let defaultValue = currentValue || undefined;
                let allowClearSelect = true;

                // типы стрелок, пришлось затащить отдельно
                if (!values && record.typeId === systemAttributeTypeArrow) {
                    values = edgeArrowTypeNames(currentLocale, intl);
                }
                if (!values && record.typeId === systemAttributeEdgeStyle) {
                    values = edgeStylesNames(currentLocale, intl);
                }
                if (!values && record.typeId === systemAttributeFolderType) {
                    allowClearSelect = false;

                    const shouldAddDefaultFolderType: boolean = record.value === 'default' || currentValue === '';
                    values = folderTypeNames(folderTypes, currentLocale, intl, shouldAddDefaultFolderType);

                    const shouldAddCurrentFolderType =
                        !shouldAddDefaultFolderType && !values.some((val) => val.id === record.value);
                    if (shouldAddCurrentFolderType) {
                        const currentFolderTypeValue: FolderType | undefined = allNotationFolderTypes.find(
                            (type) => type.id === record.value,
                        );
                        if (currentFolderTypeValue) {
                            values.push({
                                id: record.value,
                                value: currentFolderTypeValue.multilingualName,
                            });
                        }
                    }
                    defaultValue = record.value || currentValue || 'default';
                }

                if (
                    !attributeType &&
                    record.typeId !== systemAttributeTypeArrow &&
                    record.typeId !== systemAttributeEdgeStyle &&
                    record.typeId !== systemAttributeFolderType
                ) {
                    return <div className={theme.editableElementMedium}>{record.value}</div>;
                }

                return (
                    <FocusableElementWrapper
                        className={cx(theme.editableElementMedium, {
                            [theme.deletedFolderType]: isDeletedElement,
                        })}
                    >
                        <Select
                            data-test="properties-window_select-attribute_value-select"
                            defaultValue={defaultValue}
                            onChange={(value) => {
                                setIsExistedFolderTypeSelected(!!folderTypes.find((f) => f.id === value));
                                handleChangeAttributeValue(record.id, valueType, value);
                            }}
                            allowClear={allowClearSelect}
                        >
                            {values?.map((v) => {
                                const folderType = folderTypes.find((f) => f.id === v.id);
                                const folderIcon = presetImages.find(
                                    (icon) => icon.id === folderType?.graphical,
                                )?.graphical;

                                return (
                                    <Select.Option
                                        data-test={`attribute-SELECT_select-value_${LocalesService.internationalStringToString(
                                            v.value,
                                            locale as Locale,
                                        )}`}
                                        key={v.id}
                                        value={v.id}
                                    >
                                        {record.id === 'folderType' &&
                                            (folderIcon ? (
                                                <img className={theme.folderIcon} src={folderIcon} alt="" />
                                            ) : (
                                                <Icon className={theme.svgIcon} spriteSymbol={icTreeFolder} />
                                            ))}
                                        {LocalesService.internationalStringToString(v.value, locale as Locale)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </FocusableElementWrapper>
                );
            }
            case AttributeValueType.MULTI_SELECT: {
                const attributeType = allAttributeTypes.find((attrType) => attrType.id === record.typeId);
                const values = attributeType?.selectPropertyValues;
                const currentValueIds = (record as AttributeValueMultiSelect).valueIds || [];
                const currentActualValueIds = currentValueIds.filter((id) => values?.find((v) => v.id === id));

                if (!attributeType) {
                    return <div className={theme.editableElementLarge}>{currentValueIds.join(', ')}</div>;
                }

                return (
                    <FocusableElementWrapper className={theme.editableElementLarge}>
                        <Select
                            data-test="properties-window_multi-select_attribute_value-select"
                            mode="multiple"
                            onChange={(value: string[]) => handleChangeAttributeValue(record.id, valueType, value)}
                            defaultValue={currentActualValueIds}
                        >
                            {values?.map((v) => (
                                <Select.Option
                                    data-test={`attribute-MULTI-SELECT_select-value_${LocalesService.internationalStringToString(
                                        v.value,
                                        locale as Locale,
                                    )}`}
                                    key={v.id}
                                    value={v.id}
                                >
                                    {LocalesService.internationalStringToString(v.value, locale as Locale)}
                                </Select.Option>
                            ))}
                        </Select>
                    </FocusableElementWrapper>
                );
            }
            case AttributeValueType.NODE: {
                const nodeRecord: AttributeValueNode = record as AttributeValueNode;
                const nodePath = storageValueToString(nodeRecord, currentLocale);
                const onClickIcon: MouseEventHandler<HTMLElement> = (e) => {
                    e.stopPropagation();
                    dispatch(
                        openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                            serverId,
                            repositoryId,
                            disableContextMenu: true,
                            isTreeWithClearButton: true,
                            onSubmit: (nodeId: NodeId) => changeNodeAttribute(nodeId, nodeRecord),
                            onClear: () => handleChangeAttributeValue(nodeRecord.id, valueType, ''),
                        }),
                    );
                };

                return (
                    <div className={theme.editableTextContainer}>
                        <div data-test="attribute_link-to-document_value" className={theme.editableElementLarge}>
                            <EditableText
                                className={theme.linkEditableTextContainer}
                                text={nodePath}
                                isEditing={false}
                                disabled={false}
                                allowEmptyValue={isEdge || !system}
                                onClickLink={onClickLink}
                                isUrlType={isUrlType}
                            />
                        </div>
                        {editable && (
                            <Button onClick={onClickIcon} dataTest="attribute-NODE_edit-btn" icon={editIcon} />
                        )}
                    </div>
                );
            }
            case AttributeValueType.PRINCIPAL: {
                const { logins, groupIds } = record as AttributeValuePrincipal;
                const principalAttributeTypeSettings = allAttributeTypes.find(
                    (at) => at.id === record?.typeId,
                )?.principalAttributeTypeSettings;
                const { allSelectedUsers: users } = getUsersSelectedData(principals, logins);
                const { allSelectedGroups: groups } = getGroupsSelectedData(principals, groupIds);

                return (
                    <div className={theme.editableTextContainer}>
                        <div data-test="attribute-PRINCIPAL_value" className={theme.editableElementLarge}>
                            <div className={theme.principalAttributeContainer}>
                                {users?.map((user) => (
                                    <UserLogin
                                        text={formatUserName(principalAttributeTypeSettings?.format)(user)}
                                        user={user}
                                        key={`principal-user_${user.login}`}
                                    />
                                ))}
                                {groups?.map((group) => (
                                    <GroupName text={group.login} group={group} key={`principal-group_${group.id}`} />
                                ))}
                            </div>
                        </div>
                        {editable && (
                            <Button
                                dataTest="attribute-PRINCIPAL_edit-btn"
                                onClick={() => {
                                    dispatch(
                                        openDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG, {
                                            serverId,
                                            attributeName,
                                            attributeValue: record,
                                            onSubmit: (value: AttributeValuePrincipal) =>
                                                handleChangeAttributeValue(record.id, valueType, value),
                                        }),
                                    );
                                }}
                                icon={editIcon}
                            />
                        )}
                    </div>
                );
            }
            /*

            case 'ENUM': {
                const numberValue = +record.value;
                const enumValue = {
                    value: isNaN(numberValue) ? record.value : numberValue,
                    descriptor: record.descriptor,
                };

                return (
                    <EnumPropertySelect
                        propValue={enumValue as TPropertyValue}
                        onChange={(value) => {
                            handleChangeAttributeValue(record.id, valueType, value);
                        }}
                        style={{ width: '100%' }}
                    />
                );
            }
            */
            case AttributeValueType.QUERY_SELECT: {
                const data: AttributeValueQuery[] = querySelectData[record.typeId] || [];
                const initialValue: AttributeValueQuery | undefined = (record as AttributeValueQuerySelect)
                    .attributeValueQuery;

                return (
                    <FocusableElementWrapper>
                        <QuerySelect
                            data={data}
                            initialValue={initialValue ? [initialValue] : undefined}
                            onChange={(value: AttributeValueQuery[]) =>
                                handleChangeAttributeValue(record.id, valueType, value)
                            }
                        />
                    </FocusableElementWrapper>
                );
            }
            case AttributeValueType.QUERY_MULTI_SELECT: {
                const data: AttributeValueQuery[] = querySelectData[record.typeId] || [];
                const initialValue: AttributeValueQuery[] | undefined = (record as AttributeValueQueryMultiSelect)
                    .attributeValueQuery;

                return (
                    <FocusableElementWrapper>
                        <QuerySelect
                            data={data}
                            onChange={(value: AttributeValueQuery[]) =>
                                handleChangeAttributeValue(record.id, valueType, value)
                            }
                            isMultiSelect
                            initialValue={initialValue}
                        />
                    </FocusableElementWrapper>
                );
            }

            default:
                return <span>Unsupported value type</span>;
        }
    };

    const onSubmitAttributesDialog = (attrTypes: AttributeType[]) => {
        handleAddAttribute(attrTypes);
        setAttributeDialogOpen(false);
    };

    const handleAttributeClickItem = (selectedId: string) => {
        const value = propertiesData[selectedId];
        if (value && !value.descriptor.dynamic) {
            return;
        }

        if (selectedAttributeIds.includes(selectedId)) {
            setSelectedAttributeIds(selectedAttributeIds.filter((id) => id !== selectedId));
        } else {
            setSelectedAttributeIds([...selectedAttributeIds, selectedId]);
        }
    };

    const handleClickDeleteAttribute = () => {
        const newChangedAttributes = changedAttributes.filter((i) => !selectedAttributeIds.includes(i.id));
        setSelectedAttributeIds([]);
        onDeleteAttribute([...removed, ...selectedAttributeIds], newChangedAttributes);
    };

    const columns = [
        {
            title: intl.formatMessage(messages.attributeName),
            dataIndex: 'name',
            key: 'name',
            width: 210,
        },
        {
            title: intl.formatMessage(messages.attribute),
            dataIndex: 'value',
            key: 'value',
        },
    ];

    const addAccessFieldsToAttribute = (attribute: Partial<TAttribute>): Partial<TAttribute> => {
        const { editable, deletable, id } = attribute;
        if (id) {
            const grantedToUpdate = profile?.attributeTypeAcls?.[id]?.update ?? true;
            const grantedToDelete = profile?.attributeTypeAcls?.[id]?.delete ?? true;

            return {
                ...attribute,
                editable: editable !== undefined ? editable && grantedToUpdate : grantedToUpdate,
                deletable: deletable !== undefined ? deletable && grantedToDelete : grantedToDelete,
            };
        }

        return attribute;
    };

    const properties: TAttribute[] =
        propertiesData &&
        Object.keys(propertiesData)
            .filter((key) => !removed.includes(key) && key !== firstCharacteristic)
            .map((key) => {
                const propertyValue: TPropertyValue = propertiesData[key];
                let attributeValue: AttributeValue = propertyValue.value;

                // если свойство изменено то подменяем значение
                if (changedProperties[key]) {
                    attributeValue = changedProperties[key].value;
                }
                // если атрибут изменен то подменяем значение
                const changesAttributeValue = changedAttributes.find((a) => a.id === key);
                if (changesAttributeValue !== undefined) {
                    attributeValue = changesAttributeValue;
                }

                const attributeType: AttributeType | undefined = attributeTypes.find(
                    (attrType) => attrType.id === attributeValue.typeId,
                );
                const isReadOnly: boolean = !!attributeType?.readOnly;
                const editable = propertyValue.descriptor.editable && !disabled && isEntityEditable;
                const deletable = !propertyValue.descriptor.system && !disabled;

                const attribute = addAccessFieldsToAttribute({
                    key,
                    name: propertyValue.descriptor.getTitle(nodeType),
                    id: propertyValue.descriptor.typeId,
                    editable,
                    deletable,
                    isReadOnly,
                });

                attribute.value = storageValueToComponent({
                    record: attributeValue,
                    name: propertyValue.descriptor.getTitle(nodeType),
                    editable: !!attribute.editable && !isReadOnly,
                    system: propertyValue.descriptor.system,
                });

                return attribute as TAttribute;
            });

    const newAttributes: TAttribute[] = changedAttributes
        .map((chAttr: AttributeValue) => {
            const attributeType: AttributeType | undefined = attributeTypes.find(
                (attrType) => attrType.id === chAttr.typeId,
            );
            const editable = !!attributeType && !disabled && isEntityEditable;

            const attribute = addAccessFieldsToAttribute({
                key: chAttr.id,
                name: attributeType?.name || intl.formatMessage(messages.unknownAttribute),
                id: chAttr.typeId,
                editable,
                deletable: true,
                isReadOnly: !!attributeType?.readOnly,
            });

            attribute.value = storageValueToComponent({
                record: chAttr,
                name: attributeType?.name || intl.formatMessage(messages.unknownAttribute),
                editable: !!attribute.editable && !attributeType?.readOnly,
                system: false,
            });

            return attribute as TAttribute;
        })
        // если атрибут есть в properties значит он изменен а не обавлен, значит не добавляем к новым атрибутам
        .filter((chAttr) => properties.filter((p) => p.key === chAttr.key).length === 0);

    const attributes: TAttribute[] = [...properties, ...newAttributes];

    if (tabType === 'nodeAttributes' && propertiesData) {
        const changedProps = changedProperties[firstCharacteristic];
        const { descriptor } = propertiesData[firstCharacteristic];
        const attribute = addAccessFieldsToAttribute({
            key: descriptor.key,
            name: descriptor.getTitle(nodeType),
            editable: !disabled && isEntityEditable,
            deletable: !descriptor.system,
            id: descriptor.typeId,
        });

        attribute.value = storageValueToComponent({
            record: changedProps ? changedProps.value : propertiesData[firstCharacteristic].value,
            name: descriptor.getTitle(nodeType),
            editable: !!attribute.editable,
            system: descriptor.system,
        });
        attributes.unshift(attribute as TAttribute);
    }

    const attributesKeyMap = attributes.reduce(
        (acc, attribute) => ({
            ...acc,
            [attribute.key]: attribute,
        }),
        {},
    );

    const notExistAttributes: AttributeType[] = useMemo(
        () =>
            attributeTypes?.filter(
                (aType: AttributeType) => !attributes.find((attr) => attr.id === aType.id) && !aType.readOnly,
            ) || [],
        [attributes, attributeTypes],
    );

    const grantedToCreateAttributes = useMemo(
        () =>
            notExistAttributes.filter((aType: AttributeType) => profile?.attributeTypeAcls?.[aType.id]?.create ?? true),
        [notExistAttributes, profile],
    );

    const shouldAttributesDialogVisible = isAttributeDialogOpen && !!grantedToCreateAttributes.length;

    const deleteButtonDisabled = useMemo(() => {
        return (
            !selectedAttributeIds.length ||
            attributes.filter((attr) => selectedAttributeIds.includes(attr.key)).some((attr) => !attr.deletable)
        );
    }, [selectedAttributeIds, attributes]);

    const onKeyDownAtributes = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Tab' && tableRef.current) {
            if (e.shiftKey) {
                switchFocusElement(tableRef.current);
            } else {
                switchFocusElement(document.body);
            }
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <FormGroup className={theme.formGroup}>
            <Form.Item>
                <Table
                    ref={tableRef}
                    data-test="element-properties_table"
                    // сортировка атрибутов в окне свойства выполнена:
                    // 1. Имя объекта/связи/папки ...
                    // 2. Тип линии/папки - только для связи/папки
                    // 3. Начало связи -  только для связи
                    // 4. Конец связи -  только для связи
                    // 5. Неизвестные атрибуты
                    // 6. Известные атрибуты
                    // 7. ID
                    // 8. Тип объекта / тип связи / тип элемента
                    // 9. Изменил
                    // 10. Дата изменения
                    // 11. Создал
                    // 12. Дата создания
                    // 13. Конфиденциально
                    dataSource={sortAttributes(attributes)} // сначала редактируемые
                    className={theme.table}
                    onRow={(row, index) => ({
                        'aria-selected': 'true',
                        tabIndex: index,
                        onKeyUp: (e) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        },
                        onKeyDown: (e) => {
                            if (index === undefined) {
                                return;
                            }

                            const target = e.target as HTMLElement;
                            const rowNode = target.closest('tr');

                            switch (e.key) {
                                case ' ':
                                    if (target.tagName === 'TR') {
                                        handleAttributeClickItem(row.key);
                                        e.preventDefault();
                                    }
                                    break;

                                case 'Tab':
                                    if (!e.shiftKey) {
                                        const buttons: NodeListOf<HTMLButtonElement> | undefined =
                                            attributeActionsRef.current?.querySelectorAll('button');
                                        const allButtonsDisabled: boolean | undefined =
                                            buttons &&
                                            Array.from(buttons).every(
                                                (button) => button.getAttribute('tabindex') === '-1',
                                            );

                                        if (allButtonsDisabled) {
                                            switchFocusElement(modalFooterContentRef.current);
                                        } else if (attributeActionsRef.current) {
                                            switchFocusElement(attributeActionsRef.current);
                                        }
                                        e.preventDefault();
                                    }
                                    break;

                                case 'ArrowUp':
                                    if (index > 0) {
                                        (target.previousSibling as HTMLElement)?.focus();
                                        e.stopPropagation();
                                    }
                                    if (!targetIsTextInput(target.tagName)) {
                                        e.preventDefault();
                                    }
                                    break;

                                case 'ArrowDown':
                                    if (index < attributes.length) {
                                        (target.nextSibling as HTMLElement)?.focus();
                                        e.stopPropagation();
                                    }
                                    if (!targetIsTextInput(target.tagName)) {
                                        e.preventDefault();
                                    }
                                    break;

                                case 'ArrowRight':
                                    if (!targetIsTextInput(target.tagName)) {
                                        switchFocusElement(rowNode);
                                    }
                                    break;

                                case 'ArrowLeft':
                                    if (
                                        rowNode !== target &&
                                        (!targetIsTextInput(target.tagName) ||
                                            (target as HTMLInputElement).type === 'checkbox')
                                    ) {
                                        const switchedNode = switchFocusElement(rowNode, true);
                                        if (switchedNode === target) {
                                            rowNode?.focus();
                                        }
                                    }
                                    break;

                                case 'Escape':
                                    rowNode?.focus();
                                    e.stopPropagation();
                                    break;
                            }
                        },
                        onClick: () => {
                            handleAttributeClickItem(row.key);
                        },
                    })}
                    rowClassName={(row: { key: string }) =>
                        cx(theme.attribute, {
                            [theme.deletedType]: attributesKeyMap[row.key].id === 'unknown',
                            [theme.attribute_selected]: selectedAttributeIds.indexOf(row.key) !== -1,
                            [theme.attribute_disabled]:
                                (!attributesKeyMap[row.key].editable && !attributesKeyMap[row.key].deletable) ||
                                !!attributesKeyMap[row.key].isReadOnly,
                        })
                    }
                    columns={columns}
                    size="middle"
                    bordered
                    pagination={false}
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                />
                <div ref={attributeActionsRef} className={theme.attributeActions}>
                    <Button
                        size="large"
                        onKeyDown={onKeyDownAtributes}
                        onClick={() => setAttributeDialogOpen(true)}
                        disabled={!grantedToCreateAttributes.length || disabled || !isEntityEditable}
                        dataTest="add-attribute-button"
                        icon={iconAdd}
                    >
                        {intl.formatMessage(messages.addButton)}
                    </Button>
                    <Button
                        dataTest="properties-panel_delete-attribute-button"
                        size="large"
                        onClick={handleClickDeleteAttribute}
                        disabled={deleteButtonDisabled}
                        icon={icDelete}
                        onKeyDown={(e) => {
                            if (e.key === 'Tab' && e.shiftKey) {
                                const firstButton: HTMLButtonElement | null | undefined =
                                    attributeActionsRef.current?.querySelector('button');
                                const isFirstButtonDisabled: boolean = firstButton?.getAttribute('tabindex') === '-1';

                                if (isFirstButtonDisabled) {
                                    switchFocusElement(tableRef.current);
                                } else if (attributeActionsRef.current) {
                                    switchFocusElement(attributeActionsRef.current, true);
                                }
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                    >
                        {intl.formatMessage(messages.deleteButton)}
                    </Button>
                </div>
            </Form.Item>
            <AttributesDialog
                open={shouldAttributesDialogVisible}
                onSubmit={onSubmitAttributesDialog}
                onCancel={() => setAttributeDialogOpen(false)}
                attributes={grantedToCreateAttributes}
            />
        </FormGroup>
    );
};
