// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterCheckbox__container___osPv,.FilterCheckbox__containerCompact__c1zpE{margin:0 8px 0 15px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:32px}.FilterCheckbox__container___osPv>:last-child,.FilterCheckbox__containerCompact__c1zpE>:last-child{margin-left:23px}.FilterCheckbox__containerCompact__c1zpE{margin:0 0px 0 15px}.FilterCheckbox__containerCompact__c1zpE>:last-child{margin-left:15px}.FilterCheckbox__buttonWrapper___O38_ button,.FilterCheckbox__buttonWrapperActive___P0_g button{width:46px}.FilterCheckbox__buttonWrapperActive___P0_g{background-color:var(--white-UIKit)}.FilterCheckbox__checkboxTitle__WPN4_{font-size:12px}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/FilterCheckbox/FilterCheckbox.scss"],"names":[],"mappings":"AAAA,2EACI,mBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CAEA,mGACI,gBAAA,CAIR,yCACI,mBAAA,CAEA,qDACI,gBAAA,CAKJ,gGACI,UAAA,CAIR,4CACI,mCAAA,CAGJ,sCACI,cAAA","sourcesContent":[".container, .containerCompact {\r\n    margin: 0 8px 0 15px;\r\n    display: flex;\r\n    align-items: center;\r\n    height: 32px;\r\n\r\n    > :last-child {\r\n        margin-left: 23px;\r\n    }\r\n}\r\n\r\n.containerCompact {\r\n    margin: 0 0px 0 15px;\r\n\r\n    > :last-child {\r\n        margin-left: 15px;\r\n    }\r\n}\r\n\r\n.buttonWrapper, .buttonWrapperActive {\r\n    button {\r\n        width: 46px;\r\n    }\r\n}\r\n\r\n.buttonWrapperActive {\r\n    background-color: var(--white-UIKit);\r\n}\r\n\r\n.checkboxTitle {\r\n    font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FilterCheckbox__container___osPv",
	"containerCompact": "FilterCheckbox__containerCompact__c1zpE",
	"buttonWrapper": "FilterCheckbox__buttonWrapper___O38_",
	"buttonWrapperActive": "FilterCheckbox__buttonWrapperActive___P0_g",
	"checkboxTitle": "FilterCheckbox__checkboxTitle__WPN4_"
};
export default ___CSS_LOADER_EXPORT___;
