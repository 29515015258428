import { useDispatch, useSelector } from 'react-redux';
import {
    AttributeType,
    AttributeValue,
    AttributeValuePrincipal,
    AttributeValueString,
    AttributeValueUrl,
    InternationalString,
    PrincipalAttributeTypeSettings,
    PrincipalDescriptor,
} from '../../../../../../serverapi/api';
import { LocalesService } from '../../../../../../services/LocalesService';
import { TInputTypeEnum } from '../../../../../UIKit/components/EditableText/EditableText.types';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import {
    checkBooleanValue,
    formatUserName,
    getGroupsSelectedData,
    getUsersSelectedData,
    jsonParse,
} from '../../../../../ObjectPropertiesDialog/components/utils';
import React, { useState, MouseEvent } from 'react';
import { EditableText } from '../../../../../UIKit/components/EditableText/EditableText.component';
import { DatePicker, TimePicker } from 'antd';
import { MultiLangStringDialogComponent } from '../../../../../ObjectPropertiesDialog/components/MultiLangStringDialogComponent';
import { MultiLangUrlDialogComponent } from '../../../../../ObjectPropertiesDialog/components/MultiLangUrlDialogComponent';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { TPeriodRange } from '../../../../../../utils/date.time.utils.types';
import theme from './StorageDefaultValueToComponent.scss';
import { dateFormat, momentDateToTimestamp, timestampToMomentDate } from '../../../../../../utils/date.time.utils';
import auditMessages from '../../../../ActionsAudit/audit.messages';
import GroupName from '../../../../../ObjectPropertiesDialog/components/PrincipalAttributeType/GroupName.component';
import UserLogin from '../../../../../ObjectPropertiesDialog/components/PrincipalAttributeType/UserLogin.component';
import { PrincipalsSelectors } from '../../../../../../selectors/principals.selectors';
import { openDialog } from '../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../DialogRoot/DialogRoot.constants';
import { ServerSelectors } from '../../../../../../selectors/entities/server.selectors';
import DeleteButton from '../../../../Button/DeleteButton';
import messages from '../AttributeType/AttributeType.messages';
import attributeTypeMessages from '../../../messages/AttributeType.messages';
import { DatePickerComponent } from './DatePickerComponent.component';
import { MultiSelectComponent } from './MultiSelectComponent.component.component';
import { SelectComponent } from './SelectComponent.component';
import { Locale } from '../../../../../Header/components/Header/header.types';
import { getAttrbuteValue } from './util/attributeTypeEditorDialog.utils';
import { Checkbox } from '../../../../../UIKit/components/Checkbox/Checkbox.component';
import { AttributeValueType } from '../../../../../FloatingAttributes/components/AttributesEditor/Attribute.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';
import icEdit from '@/resources/icons/edit.svg';
import { TCheckboxStatus } from '@/modules/UIKit/components/Checkbox/Checkbox.types';

export const StorageDefaultValueToComponent = ({
    attributeType,
    onChangeDefaultValue,
}: {
    attributeType: AttributeType;
    onChangeDefaultValue: (attributeValue: AttributeValue | undefined) => void;
}) => {
    const { valueType } = attributeType;

    const serverId: string = useSelector(ServerSelectors.serverId);
    const currentLocale: Locale = useSelector(getCurrentLocale);
    const dispatch = useDispatch();
    const intl = useIntl();
    const [editingField, setEditingField] = useState<string>('');
    const principals: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getAll);

    const curLocale: string = LocalesService.convertToString(currentLocale).toLowerCase();

    let inputType: TInputTypeEnum = 'text';
    if (valueType === AttributeValueType.NUMERIC || valueType === AttributeValueType.INTEGER) {
        inputType = 'number';
    } else if (valueType === AttributeValueType.MULTI_STRING) {
        inputType = 'textarea';
    }

    const handleChangeAttributeValue = (attributeType: AttributeType, formValues: InternationalString) => {
        onChangeDefaultValue({
            ...getAttrbuteValue(attributeType),
            str: formValues,
        } as AttributeValueString);
    };

    const onActivateEditable = (fieldName: string) => () => {
        setEditingField(fieldName);
    };
    const handleEditAttrBtn = (id: string) => (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (editingField !== id) {
            onActivateEditable(id)();
        }
    };
    const handlDeleteDefaultValue = () => {
        onChangeDefaultValue({} as AttributeValue);
    };

    switch (valueType) {
        case AttributeValueType.STRING:
        case AttributeValueType.MULTI_STRING: {
            const multiLingualStr: string =
                LocalesService.internationalStringToString(
                    (attributeType?.attributeDefaultValue as AttributeValueString)?.str,
                ) || '';
            const isEditing: boolean = attributeType.id === editingField;

            return (
                <>
                    <div className={theme.label}>
                        {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    </div>
                    <div
                        className={valueType === 'STRING' ? theme.editableStringContainer : theme.editableTextContainer}
                    >
                        <div className={valueType === 'STRING' ? theme.stringContainer : theme.textContainer}>
                            <EditableText
                                text={multiLingualStr}
                                isEditing={isEditing}
                                disabled={false}
                                onActivateEditable={onActivateEditable(attributeType.id)}
                                onDeactivateEditable={() => setEditingField('')}
                                onChange={(value: string) => {
                                    onChangeDefaultValue({
                                        ...getAttrbuteValue(attributeType),
                                        str: {
                                            ...(attributeType.attributeDefaultValue as AttributeValueString)?.str,
                                            [currentLocale]: value,
                                        },
                                    } as AttributeValueString);
                                }}
                                allowEmptyValue
                                isUrlType={false}
                                inputType={inputType}
                            />
                        </div>
                        <div className={theme.multiLangStringWrapper}>
                            <MultiLangStringDialogComponent
                                handleOk={(formValues: InternationalString) =>
                                    handleChangeAttributeValue(attributeType, formValues)
                                }
                                record={attributeType?.attributeDefaultValue as AttributeValueString}
                                allowEmptyValue
                                curLocale={curLocale}
                            />
                        </div>
                        <Button
                            dataTest={`${valueType}_button`}
                            onClick={handleEditAttrBtn(attributeType.id)}
                            tooltip={intl.formatMessage(messages.edit)}
                            visualStyle="text"
                            icon={icEdit}
                            size="small"
                        />
                        <DeleteButton
                            disabled={!multiLingualStr}
                            onDelete={handlDeleteDefaultValue}
                            deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                        />
                    </div>
                </>
            );
        }
        case AttributeValueType.JSON:
        case AttributeValueType.NUMERIC:
        case AttributeValueType.INTEGER: {
            const isEditing: boolean = attributeType.id === editingField;
            const text: string = attributeType.attributeDefaultValue?.value || '';

            return (
                <>
                    <div className={theme.label}>
                        {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    </div>
                    <div className={theme.editableStringContainer}>
                        <div className={theme.stringContainer}>
                            <EditableText
                                text={text}
                                isEditing={isEditing}
                                disabled={false}
                                onActivateEditable={onActivateEditable(attributeType.id)}
                                onDeactivateEditable={() => setEditingField('')}
                                onChange={(value: number | string) => {
                                    onChangeDefaultValue({
                                        ...getAttrbuteValue(attributeType),
                                        value: `${value}`,
                                    });
                                }}
                                allowEmptyValue
                                inputType={inputType}
                            />
                        </div>
                        <>
                            <Button
                                dataTest={`${valueType}_button`}
                                onClick={handleEditAttrBtn(attributeType.id)}
                                tooltip={intl.formatMessage(messages.edit)}
                                visualStyle="text"
                                icon={icEdit}
                                size="small"
                            />
                            <DeleteButton
                                disabled={!text}
                                onDelete={handlDeleteDefaultValue}
                                deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                            />
                        </>
                    </div>
                </>
            );
        }
        case AttributeValueType.URL: {
            const urlText: string =
                LocalesService.internationalStringToString(
                    (attributeType.attributeDefaultValue as AttributeValueUrl)?.name,
                ) || '';
            const isEditing: boolean = attributeType.id === editingField;

            return (
                <>
                    <div className={theme.label}>
                        {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    </div>
                    <div className={theme.editableStringContainer}>
                        <div className={theme.urlContainer}>
                            <EditableText
                                text={urlText}
                                isEditing={isEditing}
                                disabled={false}
                                onActivateEditable={onActivateEditable(attributeType.id)}
                                onDeactivateEditable={() => setEditingField('')}
                                onChange={(value: string) => {
                                    onChangeDefaultValue({
                                        ...getAttrbuteValue(attributeType),
                                        url: (attributeType.attributeDefaultValue as AttributeValueUrl)?.url,
                                        name: {
                                            ...(attributeType.attributeDefaultValue as AttributeValueUrl)?.name,
                                            [currentLocale]: value,
                                        },
                                    } as AttributeValueUrl);
                                }}
                                allowEmptyValue
                                isUrlType
                                inputType={inputType}
                            />
                        </div>
                        <>
                            <div className={theme.multiLangStringWrapper}>
                                <MultiLangUrlDialogComponent
                                    handleOk={(changedValues) => {
                                        onChangeDefaultValue({
                                            ...getAttrbuteValue(attributeType),
                                            name: changedValues.name,
                                            url: changedValues.url,
                                        } as AttributeValueUrl);
                                    }}
                                    record={attributeType.attributeDefaultValue as AttributeValueUrl}
                                />
                            </div>
                            <Button
                                data-test={`${valueType}_button`}
                                onClick={handleEditAttrBtn(attributeType.id)}
                                tooltip={intl.formatMessage(messages.edit)}
                                visualStyle="text"
                                icon={icEdit}
                                size="small"
                            />
                            <div>
                                <DeleteButton
                                    disabled={!urlText}
                                    onDelete={handlDeleteDefaultValue}
                                    deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                                />
                            </div>
                        </>
                    </div>
                </>
            );
        }
        case AttributeValueType.DATE_TIME:
        case AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE:
        case AttributeValueType.DATE: {
            return (
                <>
                    <div className={theme.label}>
                        {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    </div>
                    <DatePickerComponent onChangeDefaultValue={onChangeDefaultValue} attributeType={attributeType} />
                </>
            );
        }
        case AttributeValueType.PERIOD: {
            const periodObj: TPeriodRange | null = jsonParse(
                attributeType.attributeDefaultValue?.value,
            ) as TPeriodRange | null;
            const defaultRange: [dayjs.Dayjs, dayjs.Dayjs] = [
                periodObj?.from ? timestampToMomentDate(+periodObj.from) : undefined,
                periodObj?.to ? timestampToMomentDate(+periodObj.to) : undefined,
            ] as [dayjs.Dayjs, dayjs.Dayjs];

            return (
                <>
                    <div className={theme.label}>
                        {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    </div>
                    <div className={theme.dateContainer}>
                        <DatePickerProvider>
                            <DatePicker.RangePicker
                                data-test="select_attribute_period_input"
                                style={{ width: '100%' }}
                                className={theme.rangePicker}
                                format={dateFormat}
                                defaultValue={defaultRange}
                                ranges={{
                                    [intl.formatMessage(auditMessages.today)]: [dayjs(), dayjs()],
                                    [intl.formatMessage(auditMessages.currentMonth)]: [
                                        dayjs().startOf('month'),
                                        dayjs().endOf('month'),
                                    ],
                                }}
                                onChange={(dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
                                    const from = momentDateToTimestamp?.(dates?.[0]);
                                    const to = momentDateToTimestamp?.(dates?.[1]);
                                    const dateObj: TPeriodRange | string =
                                        from && to
                                            ? {
                                                  from: from.toString(),
                                                  to: to.toString(),
                                              }
                                            : '';
                                    onChangeDefaultValue({
                                        ...getAttrbuteValue(attributeType),
                                        value: dateObj && JSON.stringify(dateObj),
                                    });
                                }}
                            />
                        </DatePickerProvider>
                    </div>
                </>
            );
        }
        case AttributeValueType.BOOLEAN: {
            const isChecked: boolean = checkBooleanValue(attributeType.attributeDefaultValue?.value);

            return (
                <Checkbox
                    dataTest="attribute-boolean_check-box"
                    status={isChecked}
                    onChange={(status: TCheckboxStatus) => {
                        onChangeDefaultValue({
                            ...getAttrbuteValue(attributeType),
                            value: `${status}`,
                        });
                    }}
                >
                    {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                </Checkbox>
            );
        }
        case AttributeValueType.TIME_WITHOUT_TIMEZONE:
        case AttributeValueType.TIME: {
            const val: string | undefined = attributeType.attributeDefaultValue?.value;
            const isWithoutTimeZone: boolean = valueType === AttributeValueType.TIME_WITHOUT_TIMEZONE;

            return (
                <>
                    <div className={theme.label}>
                        {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    </div>
                    <div className={theme.dateContainer}>
                        <DatePickerProvider>
                            <TimePicker
                                style={{ width: '100%' }}
                                data-test="select-time-picker-input"
                                defaultValue={val ? timestampToMomentDate(+val, isWithoutTimeZone) : undefined}
                                onChange={(time: dayjs.Dayjs) => {
                                    onChangeDefaultValue({
                                        ...getAttrbuteValue(attributeType),
                                        value: `${momentDateToTimestamp(time, isWithoutTimeZone)}`,
                                    });
                                }}
                            />
                        </DatePickerProvider>
                    </div>
                </>
            );
        }
        case AttributeValueType.SELECT: {
            return (
                <SelectComponent
                    label={intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    attributeType={attributeType}
                    onChangeDefaultValue={onChangeDefaultValue}
                />
            );
        }
        case AttributeValueType.MULTI_SELECT: {
            return (
                <MultiSelectComponent
                    label={intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    attributeType={attributeType}
                    onChangeDefaultValue={onChangeDefaultValue}
                />
            );
        }
        case AttributeValueType.PRINCIPAL: {
            const { logins, groupIds } = (attributeType?.attributeDefaultValue as AttributeValuePrincipal) || {};
            const principalAttributeTypeSettings: PrincipalAttributeTypeSettings | undefined =
                attributeType?.principalAttributeTypeSettings;

            const { allSelectedUsers: users } = getUsersSelectedData(principals, logins);
            const { allSelectedGroups: groups } = getGroupsSelectedData(principals, groupIds);

            return (
                <>
                    <div className={theme.label}>
                        {intl.formatMessage(attributeTypeMessages.DEFAULT_ATTRIBUE_VALUE)}
                    </div>
                    <div className={theme.editableTextContainer}>
                        <div data-test="attribute-PRINCIPAL_value" className={theme.textContainer}>
                            <div className={theme.principalAttributeContainer}>
                                {users.map((user) => (
                                    <UserLogin
                                        text={formatUserName(principalAttributeTypeSettings?.format)(user)}
                                        user={user as PrincipalDescriptor}
                                        key={`principal-user_${user.login}`}
                                    />
                                ))}
                                {groups.map((group) => (
                                    <GroupName text={group.login} group={group} key={`principal-group_${group.id}`} />
                                ))}
                            </div>
                        </div>
                        <Button
                            dataTest="attribute-PRINCIPAL_edit-btn"
                            tooltip={intl.formatMessage(messages.edit)}
                            visualStyle="text"
                            onClick={() => {
                                dispatch(
                                    openDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG, {
                                        serverId,
                                        attributeName: attributeType.name,
                                        attributeValue: attributeType.attributeDefaultValue,
                                        onSubmit: (value: AttributeValuePrincipal) => {
                                            return onChangeDefaultValue({
                                                ...getAttrbuteValue(attributeType),
                                                logins: value.logins,
                                                groupIds: value.groupIds,
                                            } as AttributeValuePrincipal);
                                        },
                                    }),
                                );
                            }}
                            icon={icEdit}
                            size="small"
                        />
                        <DeleteButton
                            disabled={!users.length && !groups.length}
                            onDelete={handlDeleteDefaultValue}
                            deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                        />
                    </div>
                </>
            );
        }

        default:
            return null;
    }
};
