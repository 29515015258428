import { Modal } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import infoDialogMessages from '../../../messages/OperationInfoDialog.messages';
import messages from './TabHeader.messages';
import theme from './TabHeader.scss';
// import { TBtnProperties } from './TabHeader.types';
import { showDeleteConfirmation } from '../../../../Button/DeleteButton';
import { LocalesService } from '../../../../../../services/LocalesService';
import { SearchInput } from '../../../../../UIKit/components/Select/SearchInput.component';
import { TImportedImg } from '../../../../../../utils/types';
import { Button } from '../../../../../UIKit/components/Button/Button.component';
import { TButtonProps } from '../../../../../UIKit/components/Button/Button.types';
import { TSpriteSymbol } from '../../../../../../models/spriteSymbol.types';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { TCheckboxStatus } from '@/modules/UIKit/components/Checkbox/Checkbox.types';

type TTabHeaderButton = TButtonProps & {
    isCheckbox?: boolean;
    checked?: boolean;
    icon?: TSpriteSymbol;
    // для чекбокса
    onChange?: (status: TCheckboxStatus) => void;
    // для остальных кнопок
    onClick?: (value) => void;
};

type TTabHeaderProps = {
    buttons?: Array<TTabHeaderButton>;
    inputDataTest?: string;
    customButtons?: JSX.Element;
    onSearchChange?: (value: string) => void;
};

type TTabHeaderFullProps = TTabHeaderProps & WrappedComponentProps;

enum ButtonKey {
    DELETE = 'DELETE',
    MOVE = 'MOVE',
    EXPORT = 'EXPORT',
    IMPORT = 'IMPORT',
    ADD = 'ADD',
}

export class ExportButton {
    static build(onAction: () => void, name: any, disabled?: boolean, blocked?: boolean): TTabHeaderButton {
        const intl = LocalesService.useIntl();

        return {
            key: ButtonKey.EXPORT + uuid(),
            onClick: () => {
                if (blocked) {
                    Modal.info({ title: intl.formatMessage(infoDialogMessages.deny) });
                } else {
                    onAction();
                }
            },
            disabled,
            children: name,
        };
    }
}

export class ImportButton {
    static build(onAction: () => void, name: any, disabled?: boolean, blocked?: boolean, icon?: TImportedImg) {
        const intl = LocalesService.useIntl();

        return {
            key: ButtonKey.IMPORT + uuid(),
            onAction: () => {
                if (blocked) {
                    Modal.info({ title: intl.formatMessage(infoDialogMessages.deny) });
                } else {
                    onAction();
                }
            },
            disabled,
            name,
            icon,
        };
    }
}

export class MoveSelected {
    static build(onClick: () => void, disabled?: boolean, key?: string): TTabHeaderButton {
        const intl = LocalesService.useIntl();

        return {
            key: key || ButtonKey.MOVE + uuid(),
            onClick,
            disabled,
            children: intl.formatMessage(messages.moveSelected),
        };
    }
}

export class DeleteSelected {
    static build(
        onAction: () => void,
        disabled?: boolean,
        key?: string,
        title?: string,
        dialogContent?: React.ReactNode,
        dataTest?: string,
    ): TTabHeaderButton {
        const intl = LocalesService.useIntl();

        return {
            key: key || ButtonKey.DELETE + uuid(),
            onClick: () =>
                showDeleteConfirmation({
                    onDelete: onAction,
                    deleteQuestion: title,
                    dialogContent,
                }),
            disabled,
            size: 'small',
            children: intl.formatMessage(messages.deleteSelected),
            dataTest,
        };
    }
}

export class AddModel {
    static build(onClick: () => void, disabled?: boolean, key?: string): TTabHeaderButton {
        const intl = LocalesService.useIntl();

        return {
            key: key || ButtonKey.ADD + uuid(),
            onClick,
            disabled,
            children: intl.formatMessage(messages.addModelType),
        };
    }
}

const TabHeader = (props: TTabHeaderFullProps) => {
    const { buttons: allButtons = [], inputDataTest, customButtons, onSearchChange } = props;
    const checkbox = allButtons.filter((button) => button.isCheckbox);
    const buttons = allButtons.filter((button) => !button.isCheckbox);
    const [searchValue, setSearchValue] = useState<string>('');

    return (
        <div className={theme.searchDiv}>
            <div className={theme.searchInput} data-test={inputDataTest}>
                {onSearchChange && (
                    <SearchInput
                        originalTheme
                        showSearch={!!onSearchChange}
                        searchValue={searchValue}
                        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onSearchChange(e.target.value);
                            setSearchValue(e.target.value);
                        }}
                    />
                )}
            </div>
            {checkbox.map((button) => (
                <Checkbox key={button.key} status={button.checked} onChange={button.onChange}>
                    {button.children}
                </Checkbox>
            ))}
            <div className={theme.actionButtons}>
                {buttons.map((button) => (
                    <Button
                        key={button.key}
                        danger={button.danger}
                        onClick={button.onClick}
                        disabled={button.disabled}
                        dataTest={button.dataTest}
                    >
                        <div className={theme.buttonAnnotation} data-test={button.dataTest}>
                            {button.icon && <Icon spriteSymbol={button.icon} disabled={button.disabled} />}
                            {button.children}
                        </div>
                    </Button>
                ))}
                {customButtons}
            </div>
        </div>
    );
};

const TabHeaderIntl = injectIntl(TabHeader);

export { TabHeaderIntl as TabHeader };
