import { useIntl } from 'react-intl';
import messages from '../../FormatPanel.messages';
import { useDispatch, useSelector } from 'react-redux';
import theme from './FontAndFillingColor.scss';
import { ColorResult } from 'react-color';
import { changeFontColor } from '../../../../actions/editor.actions';
import { ColorPicker } from '../../../UIKit/components/ColorPicker/ColorPicker.component';
import { ButtonEditLabelState } from '../../../../models/buttonEditLabelState';
import { getGeneralMenuButtonsState } from '../../../../selectors/generalMenu.selectors';
import React from 'react';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../serverapi/api';

type TFontColor = { disabled: boolean; modelId: NodeId };

export const FontColorBlock = (props: TFontColor) => {
    const { disabled, modelId } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeById(modelId));
    const buttonEditLabelState: ButtonEditLabelState = useSelector(getGeneralMenuButtonsState);

    const handleFontColorChange = (color: ColorResult) => {
        dispatch(changeFontColor(color.hex));
    };

    const currentColor: string | undefined = buttonEditLabelState.fontColor;

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.fontColor)}</div>
            <ColorPicker
                currentColor={currentColor}
                onChange={handleFontColorChange}
                disabled={disabled || isReadMode}
                dataTest={'object-toolbar_font-text-color'}
                tooltip={isReadMode ? intl.formatMessage(messages.isReadMode) : ''}
            />
        </div>
    );
};
