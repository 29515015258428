import type { IExtendedImageOptions, TImageAttributes } from '../../extensions/image.types';
import type { NodeViewProps } from '@tiptap/core';
import { Resizable } from 're-resizable';
import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { useResizeHandlers } from './useResizeImage';
import theme from './imageRenderer.scss';
import { TextAlign } from '@/utils/configuration';

const mapTextAlign = (textAlign: TextAlign): TextAlign => {
    if (textAlign === TextAlign.JUSTIFY) {
        return TextAlign.LEFT;
    }

    return textAlign;
};

export const ImageRenderer = (props: NodeViewProps) => {
    const { node, extension, updateAttributes } = props;
    const { id, src, width, height, alt, title } = node.attrs as TImageAttributes;
    const { linkMapper } = extension.options as IExtendedImageOptions;
    const imageSrc = linkMapper(id, src);

    const { onResizeStart, onResizeStop, lockAspectRatio } = useResizeHandlers({ width, height, updateAttributes });

    return (
        <NodeViewWrapper>
            <div style={{ display: 'flex', justifyContent: mapTextAlign(node.attrs.textAlign) }}>
                <Resizable
                    size={{ width, height }}
                    onResizeStart={onResizeStart}
                    onResizeStop={onResizeStop}
                    lockAspectRatio={lockAspectRatio}
                >
                    <img alt={alt} title={title} src={imageSrc} className={theme.image} />
                </Resizable>
            </div>
        </NodeViewWrapper>
    );
};
