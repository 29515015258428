import React, { useEffect, useState } from 'react';
import { NodeId } from '@/serverapi/api';
import { Input } from 'antd';
import theme from './SelectTreeItemApprovalDialog.component.scss';
import { useIntl } from 'react-intl';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TreeSelectors, filterTreeExcludeTypes } from '@/selectors/tree.selectors';
import messages from './SelectTreeItemApprovalDialog.messages';
import { TreeNode } from '@/models/tree.types';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '@/config/config';
import { Tree } from '@/modules/Tree/components/Tree/Tree.component';
import { copyApproval } from '@/actions/approval.actions';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { Icon } from '@/modules/UIKit';
import icSearch from '../../../../resources/icons/icSearch.svg';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { setFoundNodeIds, setSearchString } from '@/actions/navigatorTreeSearch.actions';
import { NavigatorTreeSearchSelector } from '@/selectors/navigatorTreeSearch.selectors';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemsClearSelection } from '@/actions/tree.actions';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSelectTreeItemApprovalDialogProps = {
    copiedApprovalId: NodeId;
};

export const SelectTreeItemApprovalDialog = (props: TSelectTreeItemApprovalDialogProps) => {
    const { copiedApprovalId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [excludedTypes, setExcludedTypes] = useState<TreeItemType[]>([TreeItemType.ObjectDefinition]);
    const searchStr: string = useSelector(NavigatorTreeSearchSelector.getSearchString);
    const treeStructure = useSelector(TreeSelectors.treeStructure);
    const selectedNode: TreeNode | undefined = useSelector(
        SelectedNodesSelector.getNode(DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG),
    );
    const allRepos = treeStructure?.find((node) => node.nodeId.serverId === copiedApprovalId.serverId)?.children || [];
    const reposWithoutAdminTools = filterTreeExcludeTypes(allRepos, [TreeItemType.AdminTool]);
    const treeLocation = filterTreeExcludeTypes(reposWithoutAdminTools, [...excludedTypes]);
    const selectedNodeId = selectedNode?.nodeId || { id: '', repositoryId: '', serverId: '' };

    useEffect(() => {
        return () => {
            dispatch(setSearchString(''));
            dispatch(setFoundNodeIds([]));
        };
    }, []);

    const handleSubmit = () => {
        dispatch(copyApproval({ replicatedApprovalId: copiedApprovalId, approvedNodeId: selectedNodeId }));
        dispatch(treeItemsClearSelection());
    };

    const handleCloseDialog = () => {
        dispatch(closeDialog(DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG));
        dispatch(treeItemsClearSelection());
    };

    const onFilterTypeChange = (type: TreeItemType) => {
        let changedFilterTypes: TreeItemType[];

        switch (type) {
            case TreeItemType.Model: {
                changedFilterTypes = [
                    TreeItemType.Model,
                    TreeItemType.Wiki,
                    TreeItemType.Matrix,
                    TreeItemType.SimulationModeling,
                    TreeItemType.Spreadsheet,
                ];
                break;
            }
            case TreeItemType.ObjectDefinition: {
                changedFilterTypes = [TreeItemType.ObjectDefinition];
                break;
            }
            default: {
                changedFilterTypes = [];
            }
        }

        if (excludedTypes.includes(type)) {
            setExcludedTypes(excludedTypes.filter((filterType) => !changedFilterTypes.includes(filterType)));
        } else {
            setExcludedTypes([...excludedTypes, ...changedFilterTypes]);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchString(e.target.value));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCloseDialog,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.confirm),
                    visualStyle: 'primary',
                    dataTest: 'copy-approval_next-btn',
                    disabled: !selectedNodeId.id,
                },
            ]}
        />
    );

    const header = (
        <div>
            <span className={theme.title}>{intl.formatMessage(messages.dialogTitle)}</span>
            <div className={theme.filterContainer}>
                <Checkbox
                    onChange={() => onFilterTypeChange(TreeItemType.Model)}
                    status={!excludedTypes.includes(TreeItemType.Model)}
                >
                    {intl.formatMessage(messages.models)}
                </Checkbox>
                <Checkbox
                    onChange={() => onFilterTypeChange(TreeItemType.ObjectDefinition)}
                    status={!excludedTypes.includes(TreeItemType.ObjectDefinition)}
                >
                    {intl.formatMessage(messages.objects)}
                </Checkbox>
            </div>
            <Input
                className={theme.search}
                prefix={<Icon spriteSymbol={icSearch} />}
                onChange={handleSearchChange}
                value={searchStr}
            />
            <div className={theme.label}>{intl.formatMessage(messages.name)}</div>
        </div>
    );

    return (
        <Dialog
            data-test="copy-approval-tab"
            footer={footer}
            className={theme.modal}
            onOk={handleSubmit}
            onCancel={handleCloseDialog}
            title={header}
            open
            width={DEFAULT_DIALOG_WIDTH}
        >
            <Tree treeName={DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG} data={treeLocation} disableContextMenu />
        </Dialog>
    );
};
