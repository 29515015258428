import { CellMeasurerCache, Column, SortDirectionType } from 'react-virtualized';
import { TColumn, TTableData } from '../TableUIKit.types';
import React from 'react';
import { ColumnHeader } from '../ColumnHeader.component';
import { ColumnCell } from '../ColumnCell.component';
import { ColumnHeaderWithCheckBox } from '../ColumnHeaderWithCheckBox.component';
import { ColumnCellWithCheckBox } from '../ColumnCellWithCheckBox.component';
import theme from '../TableUIKitComponent.scss';
import { TCheckboxStatus } from '../../Checkbox/Checkbox.types';

type TGetColumnsProps = {
    withCkeckBox?: boolean;
    columns: TColumn[];
    width: number;
    minWidth?: number;
    cache: CellMeasurerCache;
    sortedList: TTableData[];
    checkRows?: (rows: TTableData[], checked: boolean) => void;
};

export const getColumns = (props: TGetColumnsProps): JSX.Element[] => {
    const { withCkeckBox, columns, width, cache, sortedList, checkRows } = props;

    const cols: JSX.Element[] = columns.map((column, index) => {
        const { title, withoutSorter, dataKey, width: columnWidth, minWidth } = column;

        return (
            <Column
                width={columnWidth || width}
                flexGrow={columnWidth ? 1 : undefined}
                maxWidth={columnWidth}
                minWidth={minWidth || columnWidth}
                dataKey={dataKey}
                headerRenderer={(data) => {
                    if (data.columnData?.headerRenderer) {
                        return data.columnData.headerRenderer();
                    }
                    return <ColumnHeader label={title} withoutSorter={withoutSorter} data={data} />;
                }}
                cellRenderer={(data) => {
                    if (data.columnData?.cellRender) {
                        return data.columnData.cellRender({ data, index, cache, sortedList });
                    }

                    return (
                        <ColumnCell
                            index={index}
                            cellProps={data}
                            cache={cache}
                            value={sortedList[data.rowIndex][dataKey] ?? ''}
                        />
                    );
                }}
                key={column.dataKey}
                className={theme.cellContainer}
                disableSort={withoutSorter}
                columnData={column}
            />
        );
    });
    if (withCkeckBox)
        cols.unshift(
            <Column
                flexGrow={1}
                maxWidth={40}
                minWidth={40}
                width={40}
                dataKey="checkbox"
                headerRenderer={() => {
                    const isChecked = sortedList.length ? !!sortedList.every((item) => item?.checked) : false;
                    const isIndeterminate = !isChecked && sortedList.some((item) => item?.checked);

                    return (
                        <ColumnHeaderWithCheckBox
                            isChecked={isChecked}
                            isIndeterminate={isIndeterminate}
                            handleChange={() =>
                                checkRows ? checkRows(sortedList, !(isChecked || isIndeterminate)) : undefined
                            }
                        />
                    );
                }}
                cellRenderer={(data) => (
                    <ColumnCellWithCheckBox
                        cache={cache}
                        checked={!!sortedList[data.rowIndex].checked}
                        disabled={!!sortedList[data.rowIndex].disabled}
                        handleChange={(status: TCheckboxStatus) =>
                            checkRows ? checkRows([sortedList[data.rowIndex]], status === true) : undefined
                        }
                        cellProps={data}
                    />
                )}
                key="checkbox"
                className={theme.checkboxCellContainer}
                disableSort
            />,
        );

    return cols;
};

export const getInitialSortDirection = (columns: TColumn[]): SortDirectionType => {
    return (
        columns.find((column) => column.sortFunction && column.defaultSort && !column?.withoutSorter)?.defaultSort ||
        'ASC'
    );
};

export const getInitialSortState = (columns: TColumn[]): string => {
    return columns.find((column) => column.sortFunction && column.defaultSort && !column?.withoutSorter)?.dataKey || '';
};

export const getRowClassName = (index: number, list: TTableData[], scrollToIndex?: number): string[] => {
    let classNames: string[] = ['row'];

    if (list[index]?.checked && index !== -1) classNames.push('checkedRow');

    if (list[index]?.selected && index !== -1) classNames.push('selectedRow');

    if (list[index]?.found && index !== -1) classNames.push('foundRow');

    if (scrollToIndex === index) classNames.push('selectedFoundRow');

    return classNames;
};
