import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import icLeft from 'icons/toolbar/controls/ic-text-align-left.svg';
import icCenter from 'icons/toolbar/controls/ic-text-align-center.svg';
import icRight from 'icons/toolbar/controls/ic-text-align-right.svg';
import { useIntl } from 'react-intl';
import icAlignFill from 'icons/toolbar/controls/ic-text-align-fill.svg';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import messages from '../CommonToolbar.messages';
import { Dropdown } from '../../../Toolbar/Dropdown.component';
import { TextAlign } from '@/utils/configuration';

const getFocusedBlock = (editor: Editor) =>
    (editor.isActive({ textAlign: TextAlign.LEFT }) && TextAlign.LEFT) ||
    (editor.isActive({ textAlign: TextAlign.RIGHT }) && TextAlign.RIGHT) ||
    (editor.isActive({ textAlign: TextAlign.CENTER }) && TextAlign.CENTER) ||
    (editor.isActive({ textAlign: TextAlign.JUSTIFY }) && TextAlign.JUSTIFY) ||
    '';

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setTextAlign(value).run();

export const TextAlignComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const intl = useIntl();

    const onChange = (value: string) => {
        setCurrentState(value);
    };

    const menuItems = [
        {
            value: TextAlign.LEFT,
            tooltip: intl.formatMessage(messages.textLeft),
            spriteSymbol: icLeft,
            selected: value === TextAlign.LEFT,
            dataTest: 'wiki-toolbar-group_font-align-button_group_left',
        },
        {
            value: TextAlign.CENTER,
            tooltip: intl.formatMessage(messages.textCenter),
            spriteSymbol: icCenter,
            selected: value === TextAlign.CENTER,
            dataTest: 'wiki-toolbar-group_font-align-button_group_center',
        },
        {
            value: TextAlign.RIGHT,
            tooltip: intl.formatMessage(messages.textRight),
            spriteSymbol: icRight,
            selected: value === TextAlign.RIGHT,
            dataTest: 'wiki-toolbar-group_font-align-button_group_right',
        },
        {
            value: TextAlign.JUSTIFY,
            tooltip: intl.formatMessage(messages.textJustify),
            spriteSymbol: icAlignFill,
            selected: value === TextAlign.JUSTIFY,
            dataTest: 'wiki-toolbar-group_font-align-button_group_justify',
        },
    ];

    return (
        <Dropdown<string>
            value={value}
            items={menuItems}
            title={intl.formatMessage(messages.textAlign)}
            compact
            defaultSprite={icLeft}
            onSelect={onChange}
            dataTest="wiki-toolbar-group_font-align-button_group_popover"
        />
    );
};
