import React from 'react';
import openFormat from '../../../../../../resources/icons/formatPanel.svg';
import { useDispatch, useSelector } from 'react-redux';
import { formatPanelClose, formatPanelOpen } from '../../../../../../actions/formatPanel.actions';
import { NodeId } from '../../../../../../serverapi/api';
import { FormatPanelSelectors } from '../../../../../../selectors/formatPanel.selectors';
import { ButtonGroup } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { closeCommentsPanel } from '../../../../../../actions/comments.actions';

type TOpenFormatPanelBtn = { modelId: NodeId; disabled?: boolean };

export const OpenFormatPanelBtn = (props: TOpenFormatPanelBtn) => {
    const { modelId, disabled } = props;
    const dispatch = useDispatch();
    const isFormatPanelOpen: boolean = useSelector(FormatPanelSelectors.isFormatPanelOpenByModelId(modelId));

    const handleClick = () => {
        if (isFormatPanelOpen) {
            dispatch(formatPanelClose(modelId));
        } else {
            dispatch(formatPanelOpen(modelId));
            dispatch(closeCommentsPanel({ modelId }));
        }
    };
    return (
        <ButtonGroup
            isMainMenuBtn
            buttons={[
                {
                    icon: openFormat,
                    disabled: disabled,
                    onClick: handleClick,
                    isActive: isFormatPanelOpen,
                    dataTest: 'header_tab_FORMAT',
                },
            ]}
        />
    );
};
