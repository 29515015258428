import { defineMessages } from 'react-intl';

export default defineMessages({
    styleSetting: {
        id: 'FormatPanel.styleSetting',
        defaultMessage: 'Настройка стилей',
    },
    font: {
        id: 'FormatPanel.font',
        defaultMessage: 'Шрифт',
    },
    textStyleAndDirection: {
        id: 'FormatPanel.textStyleAndDirection',
        defaultMessage: 'Формат и направление',
    },
    fontColor: {
        id: 'FormatPanel.fontColor',
        defaultMessage: 'Цвет шрифта',
    },
    fillingColor: {
        id: 'FormatPanel.fillingColor',
        defaultMessage: 'Цвет заливки',
    },
    edgeType: {
        id: 'FormatPanel.edgeType',
        defaultMessage: 'Тип связи',
    },
    lineType: {
        id: 'FormatPanel.lineType',
        defaultMessage: 'Тип линии',
    },
    selectEdge: {
        id: 'FormatPanel.selectEdge',
        defaultMessage: 'Выберите связь',
    },
    startArrow: {
        id: 'FormatPanel.startArrow',
        defaultMessage: 'Начало линии',
    },
    endArrow: {
        id: 'FormatPanel.endArrow',
        defaultMessage: 'Конец линии',
    },
    edgeDefinitionStartArrowWarning: {
        id: 'FormatPanel.edgeDefinitionStartArrowWarning',
        defaultMessage: 'Смена начала линии невозможна, для связи есть определение',
    },
    edgeDefinitionEndArrowWarning: {
        id: 'FormatPanel.edgeDefinitionEndArrowWarning',
        defaultMessage: 'Смена конца линии невозможна, для связи есть определение',
    },
    textLeft: {
        id: 'FormatPanel.textLeft',
        defaultMessage: 'Текст слева',
    },
    textCenter: {
        id: 'FormatPanel.textCenter',
        defaultMessage: 'Текст по центру',
    },
    textRight: {
        id: 'FormatPanel.textRight',
        defaultMessage: 'Текст справа',
    },
    textBold: {
        id: 'FormatPanel.textBold',
        defaultMessage: 'Жирный текст',
    },
    textItalic: {
        id: 'FormatPanel.textItalic',
        defaultMessage: 'Наклонный текст',
    },
    textUnderline: {
        id: 'FormatPanel.textUnderline',
        defaultMessage: 'Подчеркнутый текст',
    },
    horizontalText: {
        id: 'FormatPanel.horizontalText',
        defaultMessage: 'Горизонтальный текст',
    },
    verticalText: {
        id: 'FormatPanel.verticalText',
        defaultMessage: 'Вертикальный текст',
    },
    selectEdgeOrObject: {
        id: 'FormatPanel.selectEdgeOrObject',
        defaultMessage: 'Выберите объект или связь',
    },
    fontSize: {
        id: 'FormatPanel.fontSize',
        defaultMessage: 'Размер шрифта',
    },
    isReadMode: {
        id: 'FormatPanel.isReadMode',
        defaultMessage: 'Перейдите в режим редактирования',
    },
});
