import { createSelector } from 'reselect';
import { ObjectTypeSelectors } from '../../../selectors/objectType.selectors';
import { ModelTypeSelectors } from '../../../selectors/modelType.selectors';
import { FolderTypeSelectors } from '../../../selectors/folderType.selectors';
import { EdgeTypeSelectors } from '../../../selectors/edgeType.selectors';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { LocalesService } from '../../../services/LocalesService';
import { TreeItemType } from '../../Tree/models/tree';
import { TRootState } from '../../../reducers/root.reducer.types';
import { EdgeType, FolderType, MatrixType, ModelType, NodeId, ObjectType } from 'src/serverapi/api';
import { Locale } from '../../Header/components/Header/header.types';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { getStore } from '../../../store';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import propertiesMessages from '../../../models/accessible-properties.messages';
import { MatrixModelTypeSelectors } from '@/selectors/matrixModelType.selectors';
import { ReportModelTypeSelectors } from '@/selectors/reportModelType.selectors';

export namespace SearchElementTypeSelectors {
    const selectModelType = (serverId: string, presetId: string, elementTypeId: string) =>
        createSelector<TRootState, ModelType, Locale, string>(
            ModelTypeSelectors.byId(
                {
                    modelTypeId: elementTypeId,
                    serverId,
                },
                presetId,
            ),
            getCurrentLocale,
            (modelType, locale) => LocalesService.internationalStringToString(modelType?.multilingualName, locale),
        );

    const selectObjectType = (serverId: string, presetId: string, elementTypeId: string) =>
        createSelector<TRootState, ObjectType | undefined, Locale, string>(
            ObjectTypeSelectors.byId({
                objectTypeId: elementTypeId,
                serverId,
                presetId,
            }),
            getCurrentLocale,
            (objectType, locale) => LocalesService.internationalStringToString(objectType?.multilingualName, locale),
        );

    const selectFolderType = (serverId: string, presetId: string, elementTypeId: string) =>
        createSelector<TRootState, FolderType, Locale, string>(
            FolderTypeSelectors.byId({
                folderTypeId: elementTypeId,
                serverId,
                presetId,
            }),
            getCurrentLocale,
            (folderType, locale) => {
                const intl = LocalesService.useIntl();

                return (
                    LocalesService.internationalStringToString(folderType?.multilingualName, locale) ||
                    intl.formatMessage(propertiesMessages.defaultFolderType)
                );
            },
        );

    const selectEdgeType = (serverId: string, presetId: string, elementTypeId: string) =>
        createSelector<TRootState, EdgeType, Locale, string>(
            EdgeTypeSelectors.byId({
                edgeTypeId: elementTypeId,
                serverId,
                presetId,
            }),
            getCurrentLocale,
            (edgeType, locale) => LocalesService.internationalStringToString(edgeType?.multilingualName, locale),
        );

    const selectMatrixModelType = (presetId: string, elementTypeId: string) =>
        createSelector<TRootState, MatrixType, Locale, string>(
            MatrixModelTypeSelectors.byId(elementTypeId, presetId),
            getCurrentLocale,
            (modelType, locale) => LocalesService.internationalStringToString(modelType?.multilingualName, locale),
        );

    const selectReportModelType = (presetId: string, elementTypeId: string) =>
        createSelector<TRootState, MatrixType, Locale, string>(
            ReportModelTypeSelectors.byId(elementTypeId, presetId),
            getCurrentLocale,
            (modelType, locale) => {
                if (elementTypeId === 'report') return elementTypeId;

                return LocalesService.internationalStringToString(modelType?.multilingualName, locale);
            },
        );

    export const getElementTypeName = (
        serverId: string,
        presetId: string,
        elementTypeId: string,
        type: TreeItemType,
    ) => {
        switch (type) {
            case 'MODEL':
                return selectModelType(serverId, presetId, elementTypeId);
            case 'OBJECT':
                return selectObjectType(serverId, presetId, elementTypeId);
            case 'FOLDER':
                return selectFolderType(serverId, presetId, elementTypeId);
            case 'EDGE':
                return selectEdgeType(serverId, presetId, elementTypeId);
            case 'MATRIX':
                return selectMatrixModelType(presetId, elementTypeId);
            case 'REPORT':
                return selectReportModelType(presetId, elementTypeId);
            case 'WIKI':
                return () => 'wiki';
            case 'DASHBOARD':
                return () => 'dashboard';
            default:
                return () => '';
        }
    };

    export const getSearchResult = (searchNodeId: NodeId, originalNodeId: NodeId, rootSearchNodeIds?: NodeId[]) => {
        return createSelector<TRootState, TSearchDataListItem[], string | undefined, TSearchDataListItem[]>(
            SearchSelectors.getFilterSearchResult(searchNodeId, rootSearchNodeIds),
            TreeSelectors.presetById(originalNodeId),
            (res, presetId: string) => {
                return res?.map((item) => {
                    const state = getStore().getState();

                    return {
                        ...item,
                        itemTypeName: getElementTypeName(
                            originalNodeId.serverId,
                            presetId || '',
                            item.elementType || '',
                            item.type as TreeItemType,
                        )(state),
                    } as TSearchDataListItem;
                });
            },
        );
    };
}
