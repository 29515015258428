import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../../../messages/CommonToolbar.messages';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import { NodeId } from '../../../../../../serverapi/api';
import arrowLeft from '../../../../../../resources/icons/arrow-left.svg';
import arrowRight from '../../../../../../resources/icons/arrow-right.svg';
import theme from './FilterCheckbox.scss';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TTooltipeSettings } from '@/modules/UIKit/components/Tooltip/Tooltip.types';

interface IFilterCheckboxProps {
    disabled: boolean | undefined;
    compact: boolean | undefined;
    isActiveToolbar: boolean | undefined;
    nodeFilterInput: boolean | undefined;
    nodeFilterOutput: boolean | undefined;
    tabNodeId: NodeId | undefined;
}

export const FilterCheckbox: FC<IFilterCheckboxProps> = (props) => {
    const { disabled, isActiveToolbar, nodeFilterInput, nodeFilterOutput, compact, tabNodeId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const handleNodeFilterInputChange = () => {
        if (tabNodeId) dispatch(workspaceTabSetParams(tabNodeId, { nodeFilterInput: !nodeFilterInput }));
    };
    const handleNodeFilterOutputChange = () => {
        if (tabNodeId) dispatch(workspaceTabSetParams(tabNodeId, { nodeFilterOutput: !nodeFilterOutput }));
    };
    const filterInputTooltip: TTooltipeSettings = {
        title: disabled ? intl.formatMessage(messages.enableFiltering) : intl.formatMessage(messages.nodeFilterInput),
        offset: 10,
    };
    const filterOutputTooltip: TTooltipeSettings = {
        title: disabled ? intl.formatMessage(messages.enableFiltering) : intl.formatMessage(messages.nodeFilterOutput),
        offset: 10,
    };

    return (
        <div className={compact ? theme.containerCompact : theme.container}>
            {compact ? (
                <div className={nodeFilterInput ? theme.buttonWrapperActive : theme.buttonWrapper}>
                    <Button
                        visualStyle="text"
                        icon={arrowLeft}
                        disabled={disabled || !isActiveToolbar}
                        tooltip={filterInputTooltip}
                        dataTest="main-panel_checkbox-inbox"
                        onClick={handleNodeFilterInputChange}
                    />
                </div>
            ) : (
                <Checkbox
                    disabled={disabled || !isActiveToolbar}
                    onChange={handleNodeFilterInputChange}
                    status={nodeFilterInput}
                    tooltip={filterInputTooltip}
                    dataTest="main-panel_checkbox-inbox"
                >
                    <span className={theme.checkboxTitle}>{intl.formatMessage(messages.nodeFilterInput)}</span>
                </Checkbox>
            )}
            {compact ? (
                <div className={nodeFilterOutput ? theme.buttonWrapperActive : theme.buttonWrapper}>
                    <Button
                        visualStyle="text"
                        icon={arrowRight}
                        disabled={disabled || !isActiveToolbar}
                        tooltip={filterOutputTooltip}
                        dataTest="main-panel_checkbox-outgoing"
                        onClick={handleNodeFilterOutputChange}
                    />
                </div>
                
            ) : (
                <Checkbox
                    disabled={disabled || !isActiveToolbar}
                    onChange={handleNodeFilterOutputChange}
                    status={nodeFilterOutput}
                    tooltip={filterOutputTooltip}
                    dataTest="main-panel_checkbox-outgoing"
                >
                    <span className={theme.checkboxTitle}>{intl.formatMessage(messages.nodeFilterOutput)}</span>
                </Checkbox>
            )}
        </div>
    );
};
