import type { TFloatingAttributesDialogContentRef } from '../../../modules/FloatingAttributes/components/FloatingAttributesPanel.types';
import type { TChangedProperties, TChangedProperty } from '../../../actions/changedProperties.types';
import type { TNavigatorPropertiesData } from '../../../models/navigatorPropertiesSelectorState.types';
import type { TObjectPropertiesDialogProps } from './ObjectPropertiesDialog.types';
import type { EdgeInstanceImpl, ObjectInstanceImpl, StyledAttributeType } from '@/models/bpm/bpm-model-impl';
import type {
    AttributeValue,
    ModelAssignment,
    ModelNode,
    NodeId,
    Node,
    ObjectDefinitionNode,
    AttributeValueString,
    EdgeDefinitionNode,
    RepositoryNode,
    EventDescriptorMacros,
    SymbolAttributeTypeStyleDTO,
    EdgeTypeAttributeTypeStyleDTO,
    ModelType,
    ScriptNode,
    DiagramElementAttributeStyle,
    AttributeType,
    ParentModelOfEdgeDefinition,
} from '../../../serverapi/api';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { ObjectPropertiesDialogActiveTab } from '../../../models/objectPropertiesDialog';
import { buildPropDescriptorByAttrType } from '../../../models/properties/property-descriptor';
import objectIcon from '../../../resources/icons/ic-tree-object-org-chart.svg';
import icSubscribe from '../../../resources/icons/ic-subscribe.svg';
import icUnSubscribe from '../../../resources/icons/ic-unsubscribe.svg';
import { TreeItemType } from '../../Tree/models/tree';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { ParentObjects } from './ParentObjects.component';
import { DecompositionTab } from './DecompositionTab.component';
import { ObjectInstances } from './ObjectInstances.component';
import { AttributesTab } from './AttributesTab.component';
import { SymbolFloatingAttributesPanel } from '../../FloatingAttributes/components/SymbolFloatingAttributesPanel.component';
import { EdgeFloatingAttributesPanel } from '../../FloatingAttributes/components/EdgeFloatingAttributesPanel.component';
import { getElementMessageType, trimStringValue } from './utils';
import { ModelSettings } from './ModelSettings/ModelSettings.component';
import { MethodologyChangeTab } from './MethodologyChangeTab.component';
import { convertToNumber } from '../../../utils/ConvertToNumber.utils';
import { ClassPropertiesTab } from './ClassPropertiesTab.component';
import { ClassMethodsTab } from './ClassMethodsTab.component';
import { ClassReceptionsTab } from './ClassReceptionsTab.component';
import { UML_ID_SYMBOL, UML_OBJECT_TYPE } from '../../../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { memoize } from 'lodash-es';
import { ScriptContext } from './ScriptContext/ScriptContext.component';
import { MxCell } from '../../../mxgraph/mxgraph';
import { TObjectStyle } from '../../FloatingAttributes/FloatingAttributes.types';
import { ApprovalsTab } from './ApprovalsTab/ApprovalsTab.component';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';
import { useIntl } from 'react-intl';
import { MULTILINGUAL_NAME, defaultAttributeIds, maxFileSizeLimit } from '../ObjectPropertiesDialog.constants';
import { PropertySettings } from './PropertySettings/PropertySettings.component';
import { IPropertyDescriptor } from '@/models/properties/property-descriptor.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { SettingsTabs } from '@/modules/UIKit/components/SettingsTabs/SettingsTabs.component';
import { getFocusableElements, switchFocusElement } from '@/utils/elementFocus.utils';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { StatisticsTab } from './Statistics/StatisticsTab.component';
import { ObjectPath } from '../components/ObjectPath/ObjectPath.component';
import { useDispatch } from 'react-redux';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { moveToDirectAction } from '@/actions/editor.actions';
import { openNode } from '@/actions/openNode.actions';
import { TObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';
import { TreeNode } from '@/models/tree.types';
import { toClipboardText } from '@/utils/clipboardUtils';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Links } from '@/sagas/utils.types';
import { ObjectConnections } from './ObjectConnections/ObjectConnections.component';
import { ConnectedObjects } from './ConnectedObjects.component';
import { ConnectedModels } from './ConnectedModels.component';

const isNodeNotOpenable = (type: TreeItemType): boolean => {
    return [
        TreeItemType.EdgeDefinition,
        TreeItemType.ObjectDefinition,
        TreeItemType.File,
        TreeItemType.Folder,
        TreeItemType.Repository,
        TreeItemType.FileFolder,
        TreeItemType.ScriptFolder,
    ].includes(type);
};

// кэширование происходит тольео по первому атрибуту функции (реализация lodash.memoize())
// так как symbolAttributeStyles меняется только после перезагрузки приложения при изменении методологии,
// то в качестве ключа используем symbolId (первый аргумент функции)
const getPresetSymbolStyles = memoize(
    (symbolId?: string, symbolAttributeStyles?: SymbolAttributeTypeStyleDTO[]) =>
        symbolAttributeStyles?.filter((a) => a.symbolId === symbolId) || [],
);

export const ObjectPropertiesDialog: FC<TObjectPropertiesDialogProps> = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        ignorePresetStyles: initIgnorePresetStyles,
        node: initNode,
        classPropertyObjects: initClassPropertyObjects,
        classMethodObjects: initClassMethodObjects,
        classMethodParameterObjects: initClassMethodParameterObjects,
        classReceptionObjects: initClassReceptionObjects,
        repositoryId,
        serverId,
        edgeInstance,
        tab,
        elementId,
        open,
        symbol,
        attributeTypes,
        graphId,
        isEdge,
        path,
        propertiesData,
        settingsData,
        diagramElementAttributeTypes,
        graph,
        cellId,
        modelLocked,
        instancePropertiesData,
        isEntityEditable,
        locale,
        folderTypes,
        allNotationFolderTypes,
        principals,
        isModelEditor,
        hasDefinitionOfEdgeInstance,
        isAllowedApprovals,
        isModelTypeDeleted,
        isObjectTypeDeleted,
        isFolderTypeDeleted,
        isEdgeTypeDeleted,
        isSubscribedToNode,
        userModelTypeAttributes,
        allAttributeTypes,
        edgeEntries,
        onCancel,
        onSubmit,
        onSubscribe,
        onUnsubscribe,
        saveModelEvents,
        onSubmitUmlObjects,
        onSubmitEdgeInstance,
        onSubmitDiagramElement,
        onSubmitFloatingAttributes,
        onChangeFloatingAttributes,
        syncGraphOnAttributeChange,
        onCopyModelLink,
        onPublish,
        onUnpublish,
    } = props;

    const modalContentRef = useRef<HTMLDivElement>(null);
    const modalFooterContentRef = useRef<HTMLDivElement>(null);
    const attributeActionsRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const dialogContentNode = modalContentRef.current;
        if (dialogContentNode) {
            const dialogNode = dialogContentNode.closest('.ant-modal-content') as HTMLElement;
            const focusable = getFocusableElements(dialogNode);

            const onKeyDown = (e: KeyboardEvent) => {
                if (e.key === 'Tab' && !e.shiftKey && focusable.at(-1) === document.activeElement) {
                    e.preventDefault();
                }
            };

            dialogNode.addEventListener('keydown', onKeyDown);

            return () => dialogNode.removeEventListener('keydown', onKeyDown);
        }
        return;
    }, [modalContentRef.current]);

    const [ignorePresetStyles, setIgnorePresetStyles] = useState<boolean>(initIgnorePresetStyles);
    const [node, setNode] = useState<
        ModelNode | ObjectDefinitionNode | EdgeDefinitionNode | RepositoryNode | undefined
    >(initNode);
    const [changedProperties, setChangedProperties] = useState<TChangedProperties>({});
    const [allowUnauthorizedAccessIsChecked, setAllowUnauthorizedAccessIsChecked] = useState<boolean>(
        !!node?.publishedBy,
    );
    const [settingsChangedProperties, setSettingsChangedProperties] = useState<TChangedProperties>({});
    const [diagramElementChangedProperties, setDiagramElementChangedProperties] = useState<TChangedProperties>({});
    const [changedAttributes, setChangedAttributes] = useState<AttributeValue[]>([]);
    const [diagramElementChangedAttributes, setDiagramElementChangedAttributes] = useState<AttributeValue[]>([]);
    const [removedAttributes, setRemovedAttributes] = useState<string[]>([]);
    const [removedDiagramElementAttributes, setRemovedDiagramElementAttributes] = useState<string[]>([]);
    const [rulesIsValid, setRulesIsValid] = useState<boolean>(true);
    const [classPropertyObjects, setClassPropertyObjects] = useState<ObjectDefinitionNode[]>(initClassPropertyObjects);
    const [classMethodObjects, setClassMethodObjects] = useState<ObjectDefinitionNode[]>(initClassMethodObjects);
    const [classMethodParameterObjects, setClassMethodParameterObjects] = useState<ObjectDefinitionNode[]>(
        initClassMethodParameterObjects,
    );
    const [classReceptionObjects, setClassReceptionObjects] =
        useState<ObjectDefinitionNode[]>(initClassReceptionObjects);
    const [deletedClassObjectNodeIds, setDeletedClassObjectNodeIds] = useState<NodeId[]>([]);
    const [modelEvents, setModelEvents] = useState<EventDescriptorMacros[]>([]);

    const floatingAttributesRef = useRef<TFloatingAttributesDialogContentRef>(null);

    const toNodeId = (id: string): NodeId => {
        return node ? { ...node.nodeId, id } : { id, serverId, repositoryId };
    };

    const handleChangeFloatingAttributes = (styledAttributeTypes: StyledAttributeType[]) => {
        const cell: MxCell | undefined = graph?.getModel().getCell(cellId || '');
        const attributeStyles: DiagramElementAttributeStyle[] = styledAttributeTypes.map(
            ({ id: typeId, styles, attributeDiscriminator }) => ({
                typeId,
                styles,
                attributeDiscriminator,
            }),
        );
        const value = cell?.getValue();

        if (cell && value) {
            value.attributeStyles = attributeStyles;
            value.ignorePresetStyles = ignorePresetStyles;

            if (!initNode) return;

            onChangeFloatingAttributes(initNode.nodeId);
        }
    };

    const handleClickSubmit = () => {
        if (!rulesIsValid) return;

        if (node) {
            // Публикация моделей и снятие с публикации
            if (!node.publishedBy && allowUnauthorizedAccessIsChecked) {
                onPublish(node.nodeId);
            } else if (node.publishedBy && !allowUnauthorizedAccessIsChecked) {
                onUnpublish(node.nodeId);
            }

            // сохранить узел
            const newValue = { ...node } as Node;

            // замена старых атрибутов на новые, можно было бы проще написать, но необходимо чтобы при переоткрытии свойств объекта порядок актрибутов сохранялся
            // подменяем старое значение атрибута на новое
            newValue.attributes = (node.attributes || [])
                .map((attr) => {
                    const changedValue = changedAttributes.find((changeAttr) => changeAttr.id === attr.id);

                    return changedValue || attr;
                })
                .filter((attr) => !removedAttributes.find((rmId) => attr.id === rmId));

            // добавляем новые значения
            // если атрибут был сначала добавлен а потом удален то он может сохранится
            changedAttributes
                .filter((changeAttr) => !newValue.attributes?.find((attr) => changeAttr.id === attr.id))
                .forEach((changeAttr) => newValue.attributes?.push(changeAttr));

            Object.values({ ...changedProperties, ...settingsChangedProperties }).forEach(
                (nodeProperty: TChangedProperty) => {
                    if (defaultAttributeIds.some((id) => nodeProperty.descriptor.key === id)) {
                        if (nodeProperty.descriptor.key === MULTILINGUAL_NAME) {
                            newValue.multilingualName = (nodeProperty.value as AttributeValueString).str;
                            newValue.name = (nodeProperty.value as AttributeValueString).value;
                        } else {
                            newValue[nodeProperty.descriptor.key] = nodeProperty.value?.value;
                        }
                    }
                },
            );

            syncGraphOnAttributeChange(diagramElementChangedAttributes, graphId, cellId);

            if (
                node.type === TreeItemType.ObjectDefinition &&
                (node as ObjectDefinitionNode).objectTypeId === UML_OBJECT_TYPE.CLASS
            ) {
                const newMethods = classMethodObjects.filter(
                    (method) => !initClassMethodObjects.find((obj) => obj.nodeId.id === method.nodeId.id),
                );

                const classMethodsWithChildrenIdOrder: (ObjectDefinitionNode & { childrenIdOrder: string[] })[] =
                    classMethodObjects.map((item) => {
                        const childrenIdOrder: string[] = classMethodParameterObjects
                            .filter((param) => param.parentNodeId?.id === item.nodeId.id)
                            .map((param) => param.nodeId.id);

                        return { ...item, childrenIdOrder };
                    });

                const classParametres: ObjectDefinitionNode[] = [
                    ...classPropertyObjects,
                    ...classMethodsWithChildrenIdOrder,
                    ...classReceptionObjects,
                ];

                newValue.childrenIdOrder = classParametres.map((item) => item.nodeId.id);

                onSubmitUmlObjects(
                    [newValue, ...classParametres, ...classMethodParameterObjects],
                    deletedClassObjectNodeIds,
                    newMethods,
                    serverId,
                    node,
                );
            }

            if (node.type === TreeItemType.Model) {
                saveModelEvents(node.nodeId, modelEvents);
            }

            onSubmit(newValue, node, changedProperties);
        }

        if (graphId && cellId) {
            floatingAttributesRef.current?.submit();

            const cell: MxCell | undefined = graph?.getModel().getCell(cellId || '');

            if (cell) {
                onSubmitFloatingAttributes();
            }

            // сохранить элемент диаграммы
            if (allAttributeTypes.length) {
                diagramElementChangedAttributes.forEach((attr) => {
                    const attrType: AttributeType | undefined = allAttributeTypes.find((at) => at.id === attr.typeId);

                    if (attrType) {
                        const descriptor: IPropertyDescriptor = buildPropDescriptorByAttrType(attrType, attr.id);

                        diagramElementChangedProperties[descriptor.key] = {
                            descriptor,
                            value: attr,
                            graphId,
                            cellId,
                        };
                    }
                });
            }

            // для связи имя необходимо установить не только в определении но и в экземпляре (определения может небыть)
            if (edgeInstance && changedProperties[MULTILINGUAL_NAME]) {
                diagramElementChangedProperties[MULTILINGUAL_NAME] = { ...changedProperties[MULTILINGUAL_NAME] };
            }

            if (Object.values(diagramElementChangedProperties).length || removedDiagramElementAttributes?.length) {
                onSubmitDiagramElement(
                    graphId,
                    cellId,
                    diagramElementChangedProperties as TNavigatorPropertiesData,
                    removedDiagramElementAttributes,
                );
            }

            if (!node && edgeInstance && graph) {
                onSubmitEdgeInstance(graph);
            }
        }
    };

    const setStateAssignments = (modelAssignments: ModelAssignment[]) => {
        if (node) {
            const newNode = { ...node, modelAssignments };
            setNode(newNode);
        }
    };

    const onChangePresetId = (presetId: string) => {
        setNode((prevNode) => {
            if (prevNode) return { ...prevNode, presetId };

            return undefined;
        });
    };

    const onChangeFileSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value }: { value: string } = e.target;

        if (value === '0') return;

        const maxFileSize: number = Number(convertToNumber(value));

        setNode((prevNode) => {
            if (prevNode) return { ...prevNode, maxFileSize: Math.min(maxFileSize, maxFileSizeLimit) };

            return undefined;
        });
    };

    const isEdgeDefinition: boolean = initNode?.type === TreeItemType.EdgeDefinition;
    const name: string = changedProperties.name?.value?.value || node?.name || edgeInstance?.name || '';

    const messageType = getElementMessageType(node?.type, isEdge, isEdgeDefinition);
    const typeMessage: string = intl.formatMessage(messages[messageType]);
    const isObject: boolean = node?.type === TreeItemType.ObjectDefinition;
    const isScript: boolean = node?.type === TreeItemType.Script;
    const isFile: boolean = node?.type === TreeItemType.File;
    const hasErrors = () => !(isEdge || !changedProperties.name || changedProperties.name?.value);
    const isUmlClassObject: boolean =
        initNode?.type === TreeItemType.ObjectDefinition &&
        (initNode as ObjectDefinitionNode)?.idSymbol === UML_ID_SYMBOL.CLASS;
    const isInvisibleEdge: boolean = !!edgeInstance?.invisible;
    const hasStatistics: boolean = node?.type === TreeItemType.Repository || node?.type === TreeItemType.Folder;

    const onClickObjectPath = () => {
        if (node) {
            const treeNode: TreeNode = {
                hasChildren: false,
                nodeId: node.nodeId,
                name: '',
                type: node?.type as TreeItemType,
                countChildren: 0,
            };

            dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));

            if (isNodeNotOpenable(node?.type as TreeItemType)) return;
            dispatch(openNode({ nodeId: node.nodeId, type: node?.type as TObjectTypesForLink }));
        }
    };

    const onCopyObjectPath = () => {
        const result: string | undefined = toClipboardText(path);
        if (result) {
            dispatch(showNotificationByType(NotificationType.COPY_PATH_SUCCESS));
        } else {
            dispatch(showNotificationByType(NotificationType.COPY_PATH_FAIL));
            dispatch(openDialog(DialogType.COPY_ITEM_DIALOG, { content: [Links.Path, path] }));
        }
    };

    const dialogTitle: JSX.Element = (
        <div className={theme.header}>
            <div className={theme.title}>
                <div className={theme.entityOfProperty}>
                    {intl.formatMessage(messages.title, { type: typeMessage })}
                </div>
                <div className={theme.subTitle}>
                    {symbol && <img alt="" src={symbol.icon} className={theme.subTitleIcon} />}
                    {!symbol && <Icon className={theme.subTitleIcon} spriteSymbol={objectIcon} />}
                    <span className={theme.name}>{trimStringValue(name)}</span>
                </div>
                {node &&
                node.type !== TreeItemType.FileFolder &&
                ExperimentalFeatures.isNotificationsSubscriptionEnabled() ? (
                    <div className={theme.subscribeBtn}>
                        <Button
                            visualStyle={{ type: 'text' }}
                            onClick={() => (isSubscribedToNode ? onUnsubscribe(node.nodeId) : onSubscribe(node.nodeId))}
                            icon={isSubscribedToNode ? icUnSubscribe : icSubscribe}
                            dataTest={isSubscribedToNode ? 'icon-unsubscribe' : 'icon-subscribe'}
                        />
                    </div>
                ) : null}
            </div>
            {path && <ObjectPath onClick={onClickObjectPath} onCopy={onCopyObjectPath} path={path} />}
        </div>
    );

    const footer = (
        <DialogFooterButtons
            reverse
            buttons={[
                {
                    key: 'ok',
                    onClick: handleClickSubmit,
                    onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => {
                        switch (e.key) {
                            case 'Tab':
                                if (e.shiftKey) {
                                    const buttons: NodeListOf<HTMLButtonElement> | undefined =
                                        attributeActionsRef.current?.querySelectorAll('button');
                                    const hasAvailableButton: boolean | undefined =
                                        buttons &&
                                        Array.from(buttons).some((button) => button.getAttribute('tabindex') !== '-1');
                                    if (hasAvailableButton) {
                                        return;
                                    }

                                    switchFocusElement(tableRef.current);
                                    e.preventDefault();
                                }
                                break;
                        }
                    },
                    value: intl.formatMessage(messages.okButton),
                    visualStyle: 'primary',
                    dataTest: 'properties-window_ok-button',
                    disabled: hasErrors() || !isModelEditor,
                    tooltip: isModelEditor ? '' : intl.formatMessage(messages.needLicense),
                },
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancelButton),
                    dataTest: 'properties-window_cancel-button',
                },
            ]}
        />
    );

    const cell: MxCell | undefined = graph?.getModel().getCell(cellId || '');
    const cellValue: EdgeInstanceImpl | ObjectInstanceImpl | undefined = cell?.getValue();
    const modelType: ModelType | undefined = graph?.modelType;
    const presetSymbolStyles: SymbolAttributeTypeStyleDTO[] = getPresetSymbolStyles(
        symbol?.id,
        modelType?.symbolAttributeStyles,
    );
    const presetEdgeStyles: EdgeTypeAttributeTypeStyleDTO[] | undefined = modelType?.edgeAttributeStyles;
    const edgeTypeId: string | undefined = (cellValue as EdgeInstanceImpl | undefined)?.edgeTypeId;
    const presetStylesByEdgeId: EdgeTypeAttributeTypeStyleDTO[] =
        presetEdgeStyles?.filter((style) => edgeTypeId && style.edgeTypeId === edgeTypeId) || [];
    const objectStyles: TObjectStyle[] | undefined = (cellValue as ObjectInstanceImpl | undefined)
        ?.attributeStyles as TObjectStyle[];
    const width = isUmlClassObject ? 1200 : 870;
    const edgeEntriesData: ParentModelOfEdgeDefinition[] | undefined = node
        ? (node as EdgeDefinitionNode).edgeEntries
        : edgeEntries;

    const tabs: {
        label: string;
        key: ObjectPropertiesDialogActiveTab;
        children: JSX.Element;
    }[] = [
        {
            label: intl.formatMessage(messages.nodeAttributesTabTitle),
            key: ObjectPropertiesDialogActiveTab.NameAndAttributes,
            children: (
                <AttributesTab
                    attributeActionsRef={attributeActionsRef}
                    tableRef={tableRef}
                    modalFooterContentRef={modalFooterContentRef}
                    isEntityEditable={isEntityEditable}
                    disabled={(isEdge && modelLocked && !hasDefinitionOfEdgeInstance) || !!node?.userEditDisabled}
                    locale={locale}
                    tabType="nodeAttributes"
                    cellId={cellId}
                    graphId={graphId || (node?.type === 'MODEL' ? node?.nodeId : undefined)}
                    nodeId={node?.nodeId}
                    repositoryId={repositoryId}
                    propertiesData={propertiesData}
                    removed={removedAttributes}
                    attributeTypes={attributeTypes}
                    serverId={serverId}
                    changedProperties={changedProperties}
                    changedAttributes={changedAttributes}
                    onAddAttribute={setChangedAttributes}
                    onDeleteAttribute={(newRemovedAttributes, newChangedAttributes) => {
                        setRemovedAttributes(newRemovedAttributes);
                        setChangedAttributes(newChangedAttributes);
                    }}
                    onChangeAttributeValue={(newChangedAttributes, newChangedProperties) => {
                        setChangedAttributes(newChangedAttributes);
                        setChangedProperties(newChangedProperties);
                    }}
                    isEdge={isEdge}
                    isEdgeDefinition={isEdgeDefinition}
                    folderTypes={folderTypes}
                    allNotationFolderTypes={allNotationFolderTypes}
                    principals={principals}
                    isModelTypeDeleted={isModelTypeDeleted}
                    isObjectTypeDeleted={isObjectTypeDeleted}
                    isFolderTypeDeleted={isFolderTypeDeleted}
                    isEdgeTypeDeleted={isEdgeTypeDeleted}
                    nodeType={isEdge ? 'EDGE' : node?.type}
                />
            ),
        },
    ];

    if (node?.type === 'DB') {
        tabs.push(
            {
                label: intl.formatMessage(messages.methodologySelectionTabTitle),
                key: ObjectPropertiesDialogActiveTab.MethodologySelection,
                children: (
                    <MethodologyChangeTab
                        presetId={(node as RepositoryNode)?.presetId}
                        onChangePresetId={onChangePresetId}
                    />
                ),
            },
            {
                label: intl.formatMessage(messages.additionalTabTitle),
                key: ObjectPropertiesDialogActiveTab.Additional,
                children: (
                    <>
                        <div className={theme.sizeFileContainer}>
                            {intl.formatMessage(messages.maxSizeFile)}
                            <Input
                                className={theme.sizeFileInput}
                                value={(node as RepositoryNode)?.maxFileSize || ''}
                                onChange={onChangeFileSize}
                            />
                        </div>
                    </>
                ),
            },
        );
    }

    if (cellId) {
        tabs.push({
            label: intl.formatMessage(messages.objectInstanceAttributesTabTitle),
            key: ObjectPropertiesDialogActiveTab.ObjectInstanceAttributes,
            children: (
                <AttributesTab
                    attributeActionsRef={attributeActionsRef}
                    tableRef={tableRef}
                    modalFooterContentRef={modalFooterContentRef}
                    isEntityEditable={isEntityEditable}
                    disabled={modelLocked}
                    locale={locale}
                    propertiesData={instancePropertiesData}
                    tabType="objectInstanceAttributes"
                    removed={removedDiagramElementAttributes}
                    attributeTypes={diagramElementAttributeTypes || []}
                    serverId={serverId}
                    changedProperties={diagramElementChangedProperties}
                    changedAttributes={diagramElementChangedAttributes}
                    cellId={cellId}
                    graphId={graphId}
                    nodeId={node?.nodeId}
                    repositoryId={repositoryId}
                    onAddAttribute={setDiagramElementChangedAttributes}
                    onChangeAttributeValue={(
                        newDiagramElementChangedAttributes,
                        newDiagramElementChangedProperties,
                    ) => {
                        setDiagramElementChangedAttributes(newDiagramElementChangedAttributes);
                        setDiagramElementChangedProperties(newDiagramElementChangedProperties);
                    }}
                    onDeleteAttribute={(newRemovedDiagramElementAttributes, newDiagramElementChangedAttributes) => {
                        setRemovedDiagramElementAttributes(newRemovedDiagramElementAttributes);
                        setDiagramElementChangedAttributes(newDiagramElementChangedAttributes);
                    }}
                    principals={principals}
                    isModelTypeDeleted={isModelTypeDeleted}
                    isObjectTypeDeleted={isObjectTypeDeleted}
                    isFolderTypeDeleted={isFolderTypeDeleted}
                    isEdgeTypeDeleted={isEdgeTypeDeleted}
                />
            ),
        });
    }

    if (isEdgeDefinition || (hasDefinitionOfEdgeInstance && isEdge)) {
        tabs.push({
            label: intl.formatMessage(messages.edgeInstances),
            key: ObjectPropertiesDialogActiveTab.EdgeInstances,
            children: <ObjectInstances objectEntries={edgeEntriesData} serverId={serverId} toNodeId={toNodeId} />,
        });
    }

    if (isObject) {
        tabs.push({
            label: intl.formatMessage(messages.objectInstances),
            key: ObjectPropertiesDialogActiveTab.ObjectInstances,
            children: (
                <ObjectInstances
                    objectEntries={(node as ObjectDefinitionNode).objectEntries}
                    serverId={serverId}
                    toNodeId={toNodeId}
                />
            ),
        });
    }

    if (isObject || isEdgeDefinition || (hasDefinitionOfEdgeInstance && isEdge)) {
        tabs.push({
            label: intl.formatMessage(messages.detailingTabTitle),
            key: ObjectPropertiesDialogActiveTab.Detailing,
            children: (
                <DecompositionTab
                    objectNode={node}
                    toNodeId={toNodeId}
                    setStateAssignments={setStateAssignments}
                    isEntityEditable={isEntityEditable}
                    isModelEditor={isModelEditor}
                    graphId={{ id: graph?.id.id || '', serverId, repositoryId }}
                />
            ),
        });
    }

    if (isObject && isUmlClassObject) {
        tabs.push(
            {
                label: intl.formatMessage(messages.classProperties),
                key: ObjectPropertiesDialogActiveTab.ClassProperties,
                children: (
                    <ClassPropertiesTab
                        nodeId={initNode?.nodeId!}
                        classPropertyObjects={classPropertyObjects}
                        onChangeClassPropertyObjects={setClassPropertyObjects}
                        deletedClassObjectNodeIds={deletedClassObjectNodeIds}
                        onDeleteClassObjectNodeIds={setDeletedClassObjectNodeIds}
                    />
                ),
            },
            {
                label: intl.formatMessage(messages.classMethods),
                key: ObjectPropertiesDialogActiveTab.ClassMethods,
                children: (
                    <ClassMethodsTab
                        nodeId={initNode?.nodeId!}
                        classMethodObjects={classMethodObjects}
                        onChangeClassMethodObjects={setClassMethodObjects}
                        classMethodParameterObjects={classMethodParameterObjects}
                        onChangeClassMethodParameterObjects={setClassMethodParameterObjects}
                        deletedClassObjectNodeIds={deletedClassObjectNodeIds}
                        onDeleteClassObjectNodeIds={setDeletedClassObjectNodeIds}
                    />
                ),
            },
            {
                label: intl.formatMessage(messages.classReceptions),
                key: ObjectPropertiesDialogActiveTab.ClassReceptions,
                children: (
                    <ClassReceptionsTab
                        nodeId={initNode?.nodeId!}
                        classReceptionObjects={classReceptionObjects}
                        onChangeClassReceptionObjects={setClassReceptionObjects}
                        deletedClassObjectNodeIds={deletedClassObjectNodeIds}
                        onDeleteClassObjectNodeIds={setDeletedClassObjectNodeIds}
                    />
                ),
            },
        );
    }

    if (isObject && cell) {
        tabs.push({
            label: intl.formatMessage(messages.floatingAttributes),
            key: ObjectPropertiesDialogActiveTab.FloatingAttributes,
            children: (
                <SymbolFloatingAttributesPanel
                    ref={floatingAttributesRef}
                    presetStyles={presetSymbolStyles}
                    objectStyles={objectStyles}
                    definitionAttributeTypes={attributeTypes}
                    instanceAttributeTypes={diagramElementAttributeTypes}
                    userModelTypeAttributes={userModelTypeAttributes}
                    ignorePresetStyles={ignorePresetStyles}
                    modelLocked={modelLocked}
                    onToggleIgnorePresetStyles={setIgnorePresetStyles}
                    onChange={handleChangeFloatingAttributes}
                    setRulesValidity={setRulesIsValid}
                />
            ),
        });
    }

    if (isEdge && cell && !isInvisibleEdge) {
        tabs.push({
            label: intl.formatMessage(messages.floatingEdgeAttributes),
            key: ObjectPropertiesDialogActiveTab.FloatingAttributes,
            children: (
                <EdgeFloatingAttributesPanel
                    ref={floatingAttributesRef}
                    presetStyles={presetStylesByEdgeId}
                    objectStyles={objectStyles}
                    definitionAttributeTypes={attributeTypes}
                    instanceAttributeTypes={diagramElementAttributeTypes}
                    userModelTypeAttributes={userModelTypeAttributes}
                    ignorePresetStyles={ignorePresetStyles}
                    modelLocked={modelLocked}
                    onToggleIgnorePresetStyles={setIgnorePresetStyles}
                    onChange={handleChangeFloatingAttributes}
                    setRulesValidity={setRulesIsValid}
                />
            ),
        });
    }

    if (node?.type === TreeItemType.Model || node?.type === TreeItemType.Matrix) {
        tabs.push({
            label: intl.formatMessage(messages.parents),
            key: ObjectPropertiesDialogActiveTab.ParentObjects,
            children: (
                <ParentObjects toNodeId={toNodeId} parentObjectsInfo={(node as ModelNode).parentNodesInfo || []} />
            ),
        });
    }

    if (node?.type === TreeItemType.Model) {
        tabs.push({
            label: intl.formatMessage(messages.settings),
            key: ObjectPropertiesDialogActiveTab.Settings,
            children: (
                <ModelSettings
                    publishedBy={node.publishedBy}
                    publishedAt={node.publishedAt}
                    onCopyModelLink={onCopyModelLink}
                    setAllowUnauthorizedAccessIsChecked={setAllowUnauthorizedAccessIsChecked}
                    allowUnauthorizedAccessIsChecked={allowUnauthorizedAccessIsChecked}
                    nodeId={node.nodeId}
                    changedProperties={settingsChangedProperties}
                    propertiesData={settingsData}
                    onChangeProperties={setSettingsChangedProperties}
                    modelEvents={modelEvents}
                    setModelEvents={setModelEvents}
                />
            ),
        });
        tabs.push({
            label: intl.formatMessage(messages.connectedObjects),
            key: ObjectPropertiesDialogActiveTab.ConnectedObjects,
            children: (
                <ConnectedObjects toNodeId={toNodeId} parentObjectsInfo={(node as ModelNode).parentNodesInfo || []} />
            ),
        });
        tabs.push({
            label: intl.formatMessage(messages.connectedModels),
            key: ObjectPropertiesDialogActiveTab.ConnectedModels,
            children: (
                <ConnectedModels
                    toNodeId={toNodeId}
                    nodeId={node.nodeId}
                    parentObjectsInfo={(node as ModelNode).parentNodesInfo || []}
                />
            ),
        });
    }

    if (isObject || isEdgeDefinition || (hasDefinitionOfEdgeInstance && isEdge) || isFile) {
        tabs.push({
            label: intl.formatMessage(messages.settings),
            key: ObjectPropertiesDialogActiveTab.Settings,
            children: (
                <PropertySettings
                    changedProperties={settingsChangedProperties}
                    propertiesData={settingsData}
                    onChangeProperties={setSettingsChangedProperties}
                />
            ),
        });
    }

    if (isObject) {
        tabs.push({
            label: intl.formatMessage(messages.objectConnections),
            key: ObjectPropertiesDialogActiveTab.ObjectConnections,
            children: node?.nodeId ? <ObjectConnections objectNodeId={node?.nodeId} /> : <></>,
        });
    }

    if (isScript) {
        const script: ScriptNode = initNode as ScriptNode;

        tabs.push({
            label: intl.formatMessage(messages.scriptRunningContext),
            key: ObjectPropertiesDialogActiveTab.ScriptRunningContext,
            children: <ScriptContext script={script} />,
        });
    }

    if (isAllowedApprovals) {
        tabs.push({
            label: intl.formatMessage(messages.approvals),
            key: ObjectPropertiesDialogActiveTab.Approvals,
            children: <ApprovalsTab approvedItemNodeId={initNode?.nodeId!} toggledApprovalId={elementId} />,
        });
    }

    if (hasStatistics) {
        tabs.push({
            label: intl.formatMessage(messages.statistics),
            key: ObjectPropertiesDialogActiveTab.Statistics,
            children: <StatisticsTab nodeId={initNode?.nodeId!} />,
        });
    }

    return (
        <div
            onKeyDown={(event) => {
                event.stopPropagation();
            }}
        >
            <Dialog
                open={open}
                className={theme.dialog}
                onCancel={onCancel}
                onOk={handleClickSubmit}
                closable
                width={width}
                maskClosable
                footer={<div ref={modalFooterContentRef}>{footer}</div>}
                title={dialogTitle}
            >
                <div
                    ref={modalContentRef}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter' || e.key === 'Escape') {
                            if (modalContentRef.current?.contains(document.activeElement)) {
                                e.stopPropagation();
                            }
                        }
                    }}
                    className={theme.contentWrapper}
                >
                    <SettingsTabs
                        focusOnMount
                        defaultKey={tab || ObjectPropertiesDialogActiveTab.NameAndAttributes}
                        items={tabs}
                        nodeId={initNode?.nodeId}
                    />
                </div>
            </Dialog>
        </div>
    );
};
