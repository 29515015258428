export enum WorkSpaceTabTypes {
    HOME_PAGE_TAB = 'HomePageTab',
    CONTENT_LOADING_PAGE_TAB = 'ContentLoadingPageTab',
    EDITOR = 'Editor',
    SCRIPT_EDITOR = 'ScriptEditor',
    SCRIPT_DASHBOARD = 'ScriptDashboard',
    SCRIPT_SCHEDULER = 'ScriptScheduler',
    WIKI_EDITOR = 'WikiEditor',
    MARTIX_EDITOR = 'MatrixEditor',
    REPORT_EDITOR = 'ReportEditor',
    DB_SEARCH = 'DB_SEARCH',
    DB_SEARCH_DELETED_ELEMENTS = 'DB_SEARCH_DELETED_ELEMENTS',
    IMPORT_VISIO = 'IMPORT_VISIO',
    IMPORT_ARIS = 'IMPORT_ARIS',
    SPREADSHEET = 'Spreadsheet',
    KANBAN = 'Kanban',
    DASHBOARD = 'Dashboard',
    COMPARE_MODELS = 'CompareModels',
    GRAPHICAL_COMPARISON = 'GRAPHICAL_COMPARISON',
    USER_ACCOUNT = 'UserAccount',
    TXT_FILE_EDITOR = 'TxtFileEditor',
    IMAGE_FILE_VIEWER = 'ImageFileViewer',

    /* Admin panel */
    ADMINTOOLS_TAB = 'AdminToolsTab',
    USERDATA_EDITING_TAB = 'user_data_editing_tab',
    SAFETY = 'safety-id',
    LICENSES = 'licenses-id',
    SERVER_SETTINGS = 'server-settings-id',
    USER_MANAGMENT = 'user-management-id',
    GROUPDATA_EDITING_TAB = 'group_data_editing_tab',
    LICENSEOWNERS_EDITING_TAB = 'license_owners_editing_tab',
    ACCESS_PERMISSION_OWNERS_EDITING_TAB = 'accesspermisson_owners_editing_tab',
    DBACCESS_OWNERS_EDITING_TAB = 'dbaccess_owners_editing_tab',
    MONITORING_TAB = 'AdminTools.monitoring.tabName',
    NOTIFICATIONS = 'notifications',
    PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB = 'principal_server_profile_owners_editing_tab',
    EVENT_ACCESS = 'event-access-id',
    APPROVALS_TAB = 'approvals-id',
    PUBLISHED_MODELS = 'published-models',

    /* Methodology settings */
    METHODOLOGY_SETTINGS = 'METHODOLOGY_SETTINGS',
    ADD_PRESET_TAB = 'ADD_PRESET_TAB',
    COPY_PRESET_TAB = 'COPY_PRESET_TAB',
    EDIT_MODEL_TYPE_TAB = 'EDIT_MODEL_TYPE_TAB',
    EDIT_MATRIX_MODEL_TYPE_TAB = 'EDIT_MATRIX_MODEL_TYPE_TAB',
    EDIT_REPORT_MODEL_TYPE_TAB = 'EDIT_REPORT_MODEL_TYPE_TAB',
    EDIT_OBJECT_TYPE_TAB = 'EDIT_OBJECT_TYPE_TAB',
    EDIT_EDGE_TYPE_TAB = 'EDIT_EDGE_TYPE_TAB',
    EDIT_SYMBOL_TAB = 'EDIT_SYMBOL_TAB',
    EDIT_PRESET_PROFILE_TAB = 'EDIT_PRESET_PROFILE_TAB',
    EDIT_FOLDER_TYPE_TAB = 'EDIT_FOLDER_TYPE_TAB',
    EDIT_MODEL_TYPE_TEMPLATE_TAB = 'EDIT_MODEL_TYPE_TEMPLATE_TAB',

    SIMULATION_MODELING = 'SIMULATION_MODELING',
    EDIT_KANBAN_MODEL_TYPE_TAB = 'EDIT_KANBAN_MODEL_TYPE_TAB',
    EDIT_KANBAN_CARD_TYPE_TAB = 'EDIT_KANBAN_CARD_TYPE_TAB',

    CHANGE_ENTITY_TYPE = 'CHANGE_ENTITY_TYPE',
    FIND_DUPLICATES = 'FIND_DUPLICATES',

    MY_APPROVALS = 'MY_APPROVALS',
}
