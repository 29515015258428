import { Popover, Tooltip } from 'antd';
import React, { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import theme from './ColorPicker.scss';
import cx from 'classnames';

type TColorPickerProps = {
    currentColor?: string;
    onChange: (color: ColorResult) => void;
    disabled?: boolean;
    dataTest?: string;
    tooltip?: string;
};

export const ColorPicker = (props: TColorPickerProps) => {
    const { currentColor, onChange, disabled, dataTest, tooltip } = props;
    const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);

    return (
        <Popover
            content={<SketchPicker color={currentColor} onChange={onChange} disableAlpha />}
            trigger={disabled ? [] : openColorPicker ? ['click', 'hover'] : ['click']}
            onOpenChange={(open) => setOpenColorPicker(open)}
        >
            <Tooltip title={tooltip}>
                <div className={cx(theme.colorCodeContainer, disabled && theme.containerDisabled)} data-test={dataTest}>
                    <div
                        className={cx(theme.changeColorBlock, disabled && theme.colorBlockDisabled)}
                        style={{ backgroundColor: `${currentColor || 'white'}` }}
                    />
                    <div className={theme.colorCode}>{currentColor}</div>
                </div>
            </Tooltip>
        </Popover>
    );
};
