import { useIntl } from 'react-intl';
import React from 'react';
import messages from '../../FormatPanel.messages';
import { useDispatch, useSelector } from 'react-redux';
import theme from './FontAndFillingColor.scss';
import { ColorResult } from 'react-color';
import { changeEdgeColor, editorCellColorPickedAction } from '../../../../actions/editor.actions';
import { MxCell } from '../../../../mxgraph';
import { ColorPicker } from '../../../UIKit/components/ColorPicker/ColorPicker.component';
import { ButtonEditLabelState } from '../../../../models/buttonEditLabelState';
import { getGeneralMenuButtonsState } from '../../../../selectors/generalMenu.selectors';
import { NodeId } from '../../../../serverapi/api';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';

type TFillingColorBlock = { disabled: boolean; selectedCells: MxCell[]; modelId: NodeId };

export const FillingColorBlock = (props: TFillingColorBlock) => {
    const { selectedCells, disabled, modelId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const buttonEditLabelState: ButtonEditLabelState = useSelector(getGeneralMenuButtonsState);
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeById(modelId));

    const hasSelectedEdge: boolean = selectedCells.some((cell) => cell.isEdge());
    const hasSelectedObject: boolean = selectedCells.some((cell) => cell.isVertex());
    const currentColor: string | undefined = buttonEditLabelState.fillColor;

    const handleFontColorChange = (color: ColorResult) => {
        if (hasSelectedEdge) dispatch(changeEdgeColor(color.hex));
        if (hasSelectedObject) dispatch(editorCellColorPickedAction(color.hex));
    };

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.fillingColor)}</div>
            <ColorPicker
                currentColor={currentColor}
                onChange={handleFontColorChange}
                disabled={disabled || isReadMode}
                dataTest={'object-toolbar_font-text-format'}
                tooltip={isReadMode ? intl.formatMessage(messages.isReadMode) : ''}
            />
        </div>
    );
};
