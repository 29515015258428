import React from 'react';
import { Menu } from 'antd';
import { MainMenuItems } from '../../../../models/mainMenu';
import { useIntl } from 'react-intl';
import theme from './MainMenu.scss';
import messages from '../../messages/MainMenu.messages';
import { useDispatch, useSelector } from 'react-redux';
import { generalMenuClick, generalMenuClose, generalMenuOpen } from '../../../../actions/generalMenu.actions';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { getActiveSection, getIsOpenedGeneralMenuFlag } from '../../../../selectors/mainMenu.selectors';
import { WorkSpaceTabTypes } from '../../../Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../../../../models/editorMode';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MainMenuTab } from './MainMenuTab';

export const MainMenu = () => {
    const tab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const type: string = tab ? tab.type : '';
    const selectedKey: MainMenuItems = useSelector(getActiveSection);
    const isOpened: boolean | undefined = useSelector(getIsOpenedGeneralMenuFlag);
    const isEditor: boolean = tab?.type === WorkSpaceTabTypes.EDITOR && tab.mode === EditorMode.Edit;

    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClick = (clickParam: MenuInfo) => {
        dispatch(generalMenuClick(MainMenuItems[clickParam.key]));

        if (isOpened && tab?.activeMenu === clickParam.key) {
            dispatch(generalMenuClose());
        } else dispatch(generalMenuOpen());
    };

    return (
        <menu className={theme.container}>
            <Menu
                mode="horizontal"
                selectedKeys={[selectedKey]}
                onClick={(clickParam: MenuInfo) => handleClick(clickParam)}
            >
                <Menu.Item data-test="header_tab_MAIN" key={MainMenuItems.GENERAL}>
                    <MainMenuTab title={intl.formatMessage(messages.main)} />
                </Menu.Item>
                {/* <Menu.Item
                    data-test="header_tab_OBJECT"
                    key={MainMenuItems.OBJECT}
                    disabled={!isEditor || type === WorkSpaceTabTypes.MARTIX_EDITOR}
                >
                    <MainMenuTab title={intl.formatMessage(messages.format)} />
                </Menu.Item> */}
                <Menu.Item
                    data-test="header_tab_PASTE"
                    key={MainMenuItems.PASTE}
                    disabled={!isEditor || type === WorkSpaceTabTypes.MARTIX_EDITOR}
                >
                    <MainMenuTab title={intl.formatMessage(messages.paste)} />
                </Menu.Item>
            </Menu>
        </menu>
    );
};
