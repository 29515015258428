import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Menu, Modal, Select, Upload } from 'antd';
import { useIntl } from 'react-intl';
import { FormGroup } from '../../../../../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { ObjectParametersSelector } from './ObjectParametersSelector.component';
import theme from './SymbolGeneratorDialog.scss';
import svgFolder from '../../../../../../../../resources/icons/svgFolder.svg';
import svgFolderActive from '../../../../../../../../resources/icons/svgFolderActive.svg';
import singleLine from '../../../../../../../../resources/icons/singleLine.svg';
import dashLine from '../../../../../../../../resources/icons/dashLine.svg';
import folder from '../../../../../../../../resources/icons/folder.svg';
import messages from './SymbolGenerator.messages';
import { PresetImageSelectors } from '../../../../../../../../selectors/presetSettings/presetImage.selectors';
import {
    NodeId,
    PresetImage,
    Symbol,
    SymbolSettings,
    SymbolSettingsTypeEnum,
} from '../../../../../../../../serverapi/api';
import { GeneratorImageSelector } from './GeneratorImageSelector.component';
import { TSymbolsSettings, TTmpIcons } from './SymbolGenerator.types';
import { PreviewShape } from './PreviewShape/PreviewShape.component';
import { getIcons } from './utils/getIcons.utils';
import { getDefaultIconSvg, getDefaultSymbolSettings, getSymbolAmountOfBorders } from './utils/defaultSymbolSettings';
import { SymbolGeneratorColorPicker } from './SymbolGeneratorColorPicker/SymbolGeneratorColorPicker.component';
import { SymbolGeneratorBllService } from './SymbolGeneratorService/SymbolGeneratorBllService';
import { SymbolBordersSelector } from './SymbolBordersSelector.component';
import { Icon } from '../../../../../../../UIKit/components/Icon/Icon.component';
import { indentsCalc, checkIndentActive, setIndentStyles } from './utils/indentsCalc';
import { showNotification } from '../../../../../../../../actions/notification.actions';
import { NotificationType } from '../../../../../../../../models/notificationType';
import { noop } from 'lodash-es';
import { ColorResult } from 'react-color';
import { iconChangeParametersHandler } from './utils/iconChangeParametersHandler';
import { DialogFooterButtons } from '../../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { isSvgImage } from '@/utils/image.utils';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { TCheckboxStatus } from '@/modules/UIKit/components/Checkbox/Checkbox.types';

type TSymbolGeneratorDialogComponentProps = {
    onSubmit: (graphical: string, currentStyles: SymbolSettings) => void;
    onClose: () => void;
    nodeId: NodeId;
    presetId: string;
    tmpSymbol: Symbol;
};

export const SymbolGeneratorDialog = (props: TSymbolGeneratorDialogComponentProps) => {
    const { onClose, onSubmit, nodeId, presetId, tmpSymbol } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [symbolType, setSymbolType] = useState<SymbolSettingsTypeEnum>('RECTANGLE_FIXED_ICON');
    const [svgModalVisible, setSvgModalVisible] = useState<boolean>(false);
    const [graphical, setGraphical] = useState<string>('');
    const [dropdownRoundingMenuVisible, setDropdownRoundingMenuVisible] = useState(false);
    const [currentStyles, setCurrentStyles] = useState<TSymbolsSettings>({} as TSymbolsSettings);
    const dataSource: Symbol[] = getDefaultSymbolSettings();
    const defaultSymbolSettings: SymbolSettings | undefined = dataSource.find(
        (symbol) => symbol?.symbolSettings?.type === symbolType,
    )?.symbolSettings;
    const shapeSettings: SymbolSettings = tmpSymbol?.symbolSettings ? tmpSymbol?.symbolSettings : {};
    const savedShapeSettings: SymbolSettings =
        symbolType === shapeSettings?.type ? shapeSettings : ({} as SymbolSettings);
    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(nodeId.serverId, presetId));
    const [errorBorderActive, setErrorBorderActive] = useState<boolean>(false);
    const allIndentsActive: boolean =
        checkIndentActive(0, currentStyles, symbolType) &&
        checkIndentActive(1, currentStyles, symbolType) &&
        checkIndentActive(2, currentStyles, symbolType) &&
        checkIndentActive(3, currentStyles, symbolType);
    const oppositeIndentsTopBottomActive: boolean =
        checkIndentActive(0, currentStyles, symbolType) && checkIndentActive(1, currentStyles, symbolType);
    const oppositeIndentsLeftRightActive: boolean =
        checkIndentActive(2, currentStyles, symbolType) && checkIndentActive(3, currentStyles, symbolType);
    const calcWidthBordersIndent: number =
        (currentStyles?.[symbolType]?.symbolSettings?.width || 0) -
        (currentStyles?.[symbolType]?.iconSettings?.iconWidth || 0);
    const calcHeightBordersIndent: number =
        (currentStyles?.[symbolType]?.symbolSettings?.height || 0) -
        (currentStyles?.[symbolType]?.iconSettings?.iconHeight || 0);
    const [tmpGraphical, setTmpGraphical] = useState<TTmpIcons>({} as TTmpIcons);
    const setGraphicalHandler = (shape: string) => {
        setGraphical(shape);
    };

    useEffect(() => {
        setCurrentStyles({
            ...currentStyles,
            [symbolType]: {
                ...defaultSymbolSettings,
                ...savedShapeSettings,
                ...currentStyles[symbolType],
            },
        });
        setErrorBorderActive(false);
    }, [symbolType]);

    useEffect(() => {
        setSymbolType(shapeSettings?.type || symbolType);
    }, []);

    const uploadSvgIcon = (e) => {
        if (isSvgImage(e.file)) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            reader.onload = () => {
                setErrorBorderActive(false);
                setCurrentStyles((prev) =>
                    iconChangeParametersHandler(currentStyles, prev, symbolType, {
                        ...e.file,
                        graphical: reader.result,
                    }),
                );
                setTmpGraphical((prev) => SymbolGeneratorBllService.setTmpIcon(prev, reader.result, symbolType));
            };
        } else {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.ERROR_INVALID_ICON_FORMAT,
                }),
            );
        }
    };

    const modalVisibleChange = () => {
        setSvgModalVisible(false);
    };

    const saveSelectedIconToSymbolGenerator = (icon: PresetImage) => {
        setErrorBorderActive(false);
        setCurrentStyles((prev) => iconChangeParametersHandler(currentStyles, prev, symbolType, icon));
        setTmpGraphical((prev) => SymbolGeneratorBllService.setTmpIcon(prev, icon?.graphical, symbolType));
    };

    const handleDropdownRoundingMenuToggle = () => {
        setDropdownRoundingMenuVisible(!dropdownRoundingMenuVisible);
    };

    const getSymbolBorderMenu = (bordersCount: number, svgIcons) => {
        const handleBorderStyleCheckBox = (status: TCheckboxStatus, index: number) => {
            if (status) {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            strokeWidthVisibility: true,
                            strokeWidth: 1,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            } else {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            strokeWidthVisibility: false,
                            strokeWidth: 0,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            }
        };

        const handleStrokeWidthChange = (val: number, index: number) => {
            setCurrentStyles((prev) =>
                SymbolGeneratorBllService.changeBorderStyle(
                    prev,
                    {
                        strokeWidth: val,
                    },
                    'borders',
                    symbolType,
                    index,
                ),
            );
            if (val > 0) {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            strokeWidthVisibility: true,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            } else {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            strokeWidthVisibility: false,
                            strokeWidth: 0,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            }
        };

        return (
            <Menu>
                <div>
                    {Array.from(new Array(bordersCount), (_, index) => (
                        <Menu.Item
                            key={index}
                            className={
                                currentStyles?.[symbolType]?.borders?.[index]?.strokeWidthVisibility
                                    ? theme.selected
                                    : ''
                            }
                        >
                            <div className={theme.bordersSettingWrapper}>
                                <Checkbox
                                    status={currentStyles?.[symbolType]?.borders?.[index]?.strokeWidthVisibility}
                                    onChange={(status: TCheckboxStatus) => handleBorderStyleCheckBox(status, index)}
                                >
                                    <div className={theme.iconContainer}>
                                        <Icon className={theme.svgIcon} spriteSymbol={svgIcons?.[index + 1]} />
                                    </div>
                                </Checkbox>
                                <ObjectParametersSelector
                                    editingAllowed={currentStyles?.[symbolType]?.symbolSettings?.objectBorderActive}
                                    value={currentStyles?.[symbolType]?.borders?.[index]?.strokeWidth}
                                    onChange={(val) => handleStrokeWidthChange(val, index)}
                                    maxValue={50}
                                />
                            </div>
                        </Menu.Item>
                    ))}
                </div>
            </Menu>
        );
    };

    const getSymbolRoundingMenu = (bordersCount: number, svgIcons) => {
        const handleBorderStyleCheckBox = (status: TCheckboxStatus, index: number) => {
            if (status) {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            roundingVisibility: true,
                            rounding: 1,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            } else {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            roundingVisibility: false,
                            rounding: 0,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            }
        };

        const handleStrokeRoundChange = (val: number, index: number) => {
            setCurrentStyles((prev) =>
                SymbolGeneratorBllService.changeBorderStyle(
                    prev,
                    {
                        rounding: val,
                    },
                    'borders',
                    symbolType,
                    index,
                ),
            );
            if (val > 0) {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            roundingVisibility: true,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            } else {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            roundingVisibility: false,
                            rounding: 0,
                        },
                        'borders',
                        symbolType,
                        index,
                    ),
                );
            }
        };

        return (
            <Menu>
                <div>
                    {Array.from(new Array(bordersCount), (_, index) => (
                        <Menu.Item
                            key={index}
                            className={
                                currentStyles?.[symbolType]?.borders?.[index]?.roundingVisibility ? theme.selected : ''
                            }
                        >
                            <div className={theme.bordersSettingWrapper}>
                                <Checkbox
                                    status={currentStyles?.[symbolType]?.borders?.[index]?.roundingVisibility}
                                    onChange={(status: TCheckboxStatus) => handleBorderStyleCheckBox(status, index)}
                                >
                                    <div className={theme.iconContainer}>
                                        <Icon className={theme.svgIcon} spriteSymbol={svgIcons?.[index + 1]} />
                                    </div>
                                </Checkbox>
                                <ObjectParametersSelector
                                    editingAllowed={currentStyles?.[symbolType]?.symbolSettings?.objectBorderActive}
                                    value={currentStyles?.[symbolType]?.borders?.[index]?.rounding}
                                    onChange={(val) => handleStrokeRoundChange(val, index)}
                                    maxValue={30}
                                    onlyInteger
                                />
                            </div>
                        </Menu.Item>
                    ))}
                </div>
            </Menu>
        );
    };

    const borderMenu: ReactElement<any, string> = (
        <div className={theme.borderMenuWrapper}>
            {getSymbolBorderMenu(getSymbolAmountOfBorders(symbolType), getIcons(symbolType, 'borders'))}
        </div>
    );

    const roundingMenu: ReactElement<any, string> = (
        <div className={theme.borderMenuWrapper}>
            {getSymbolRoundingMenu(getSymbolAmountOfBorders(symbolType), getIcons(symbolType, 'rounding'))}
        </div>
    );

    const handleBordersCheckBox = (status: TCheckboxStatus) => {
        if (status) {
            currentStyles?.[symbolType]?.borders?.forEach((_, i) => {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            strokeWidth: 1,
                            strokeWidthVisibility: true,
                        },
                        'borders',
                        symbolType,
                        i,
                    ),
                );
            });
        } else {
            currentStyles?.[symbolType]?.borders?.forEach((_, i) => {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            strokeWidth: 0,
                            rounding: 0,
                            roundingVisibility: false,
                            strokeWidthVisibility: false,
                        },
                        'borders',
                        symbolType,
                        i,
                    ),
                );
            });
        }
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                { objectBorderActive: !!status },
                'symbolSettings',
                symbolType,
            ),
        );
    };

    const handleIconCheckBox = (status: TCheckboxStatus) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                { iconEditActive: !!status },
                'iconSettings',
                symbolType,
            ),
        );
        if (!status) {
            setCurrentStyles((prev) =>
                SymbolGeneratorBllService.changeParametersHandler(
                    prev,
                    { svgIcon: { graphical: '' } as PresetImage },
                    'iconSettings',
                    symbolType,
                ),
            );
        }
        if (status) {
            const currentGraphical =
                tmpGraphical?.[symbolType]?.graphical || savedShapeSettings?.iconSettings?.svgIcon?.graphical;
            setCurrentStyles((prev) =>
                SymbolGeneratorBllService.changeParametersHandler(
                    prev,
                    {
                        svgIcon: {
                            graphical:
                                currentGraphical || symbolType === 'RECTANGLE_FIXED_ICON'
                                    ? currentGraphical || getDefaultIconSvg()
                                    : '',
                        } as PresetImage,
                    },
                    'iconSettings',
                    symbolType,
                ),
            );
        }
    };

    const handleIconScale = (val: string) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(prev, { scale: val }, 'iconSettings', symbolType),
        );
    };

    const handleChangeWidth = (val: number) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                {
                    width: val,
                },
                'symbolSettings',
                symbolType,
            ),
        );

        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                {
                    indentFromLeft: indentsCalc(2, currentStyles, symbolType, {
                        width: val,
                    }),
                    indentFromRight: indentsCalc(3, currentStyles, symbolType, {
                        width: val,
                    }),
                },
                'iconSettings',
                symbolType,
            ),
        );
    };

    const handleChangeHeight = (val: number) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                {
                    height: val,
                },
                'symbolSettings',
                symbolType,
            ),
        );

        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                {
                    indentFromTop: indentsCalc(1, currentStyles, symbolType, {
                        height: val,
                    }),
                    indentFromBottom: indentsCalc(0, currentStyles, symbolType, {
                        height: val,
                    }),
                },
                'iconSettings',
                symbolType,
            ),
        );
    };

    const handleChangeBGColor = (color: ColorResult) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                { objectColor: color.hex },
                'symbolSettings',
                symbolType,
            ),
        );
    };

    const handleChangeBorderColor = (color: ColorResult) => {
        currentStyles?.[symbolType]?.borders?.forEach((_, i) => {
            setCurrentStyles((prev) =>
                SymbolGeneratorBllService.changeBorderStyle(
                    prev,
                    {
                        color: color.hex,
                    },
                    'borders',
                    symbolType,
                    i,
                ),
            );
        });
    };
    const handleChangeBorderStyle = (val) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeBorderStyle(
                prev,
                {
                    type: val,
                },
                'borders',
                symbolType,
                0,
            ),
        );
    };

    const handleChangeIconWidth = (val: number) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                {
                    iconWidth: val,
                    indentFromLeft: indentsCalc(2, currentStyles, symbolType, {
                        iconWidth: val,
                    }),
                    indentFromRight: indentsCalc(3, currentStyles, symbolType, {
                        iconWidth: val,
                    }),
                },
                'iconSettings',
                symbolType,
            ),
        );
    };

    const handleChangeIconHeight = (val: number) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                {
                    iconHeight: val,
                    indentFromTop: indentsCalc(1, currentStyles, symbolType, {
                        iconHeight: val,
                    }),
                    indentFromBottom: indentsCalc(0, currentStyles, symbolType, {
                        iconHeight: val,
                    }),
                },
                'iconSettings',
                symbolType,
            ),
        );
    };

    const handleChangeIconPosition = (val: string) => {
        setCurrentStyles((prev) =>
            SymbolGeneratorBllService.changeParametersHandler(
                prev,
                {
                    iconPosition: val,
                    indentFromTop: indentsCalc(1, currentStyles, symbolType, {
                        iconPosition: val,
                    }),
                    indentFromBottom: indentsCalc(0, currentStyles, symbolType, {
                        iconPosition: val,
                    }),
                    indentFromLeft: indentsCalc(2, currentStyles, symbolType, {
                        iconPosition: val,
                    }),
                    indentFromRight: indentsCalc(3, currentStyles, symbolType, {
                        iconPosition: val,
                    }),
                },
                'iconSettings',
                symbolType,
            ),
        );
    };

    const onOkHandler = () => {
        if (
            (currentStyles[symbolType]?.iconSettings?.iconEditActive &&
                currentStyles[symbolType]?.iconSettings?.svgIcon?.graphical) ||
            !currentStyles[symbolType]?.iconSettings?.iconEditActive
        ) {
            onSubmit(graphical, currentStyles[symbolType]);
        } else {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.CHOOSE_ICON_ERROR,
                }),
            );
            setErrorBorderActive(true);
        }
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onOkHandler,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                    dataTest: 'symbol-generator_save-btn',
                },
            ]}
        />
    );

    return (
        <div>
            <Modal
                width={850}
                open
                bodyStyle={{ height: '500px', paddingRight: '10px', paddingLeft: '10px' }}
                onCancel={onClose}
                onOk={onOkHandler}
                footer={footer}
            >
                <FormGroup className={theme.formGroup} dataTest="symbol-generator-window">
                    <div className={theme.leftSideContainer}>
                        <div className={theme.symbolFormText}>{intl.formatMessage(messages.symbolForm)}</div>
                        <div className={theme.symbolForm}>
                            <Menu
                                defaultSelectedKeys={[symbolType]}
                                selectedKeys={[symbolType]}
                                mode="inline"
                                data-test="symbol-generator_form-list"
                                onSelect={(row) => {
                                    setSymbolType(
                                        dataSource.find((symbol) => symbol?.symbolSettings?.type === row.key)
                                            ?.symbolSettings?.type || 'RECTANGLE_FIXED_ICON',
                                    );
                                }}
                            >
                                {dataSource.map((item) => (
                                    <Menu.Item key={item.id}>{item.name}</Menu.Item>
                                ))}
                            </Menu>
                        </div>
                        <div className={theme.presentation}>
                            <PreviewShape
                                objectDefinitionId={nodeId.id}
                                symbolType={symbolType}
                                currentStyles={currentStyles[symbolType]}
                                setGraphical={setGraphicalHandler}
                            />
                        </div>
                    </div>
                    <div className={theme.rightSideContainer}>
                        <div className={theme.symbolParameters}>
                            <div className={theme.objectParameterText}>
                                {intl.formatMessage(messages.objectParameters)}
                            </div>
                            <div className={theme.objectParametersContainer}>
                                <ObjectParametersSelector
                                    editingAllowed
                                    value={currentStyles?.[symbolType]?.symbolSettings?.width}
                                    parameterName={intl.formatMessage(messages.width)}
                                    onChange={handleChangeWidth}
                                    maxValue={240}
                                    minValue={10}
                                    dataTest="symbol-generator_symbol-width"
                                />
                                <ObjectParametersSelector
                                    editingAllowed
                                    value={currentStyles?.[symbolType]?.symbolSettings?.height}
                                    parameterName={intl.formatMessage(messages.height)}
                                    onChange={handleChangeHeight}
                                    maxValue={165}
                                    minValue={10}
                                    dataTest="symbol-generator_symbol-hight"
                                />
                                <div
                                    className={theme.fontColorPickerButtonWrapper}
                                    data-test="symbol-generator_symbol-color"
                                >
                                    {intl.formatMessage(messages.color)}
                                    <SymbolGeneratorColorPicker
                                        color={currentStyles?.[symbolType]?.symbolSettings?.objectColor}
                                        style={{
                                            width: '70px',
                                            backgroundColor:
                                                currentStyles?.[symbolType]?.symbolSettings?.objectColor || '#fff',
                                        }}
                                        onChange={handleChangeBGColor}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={theme.symbolParameters}>
                            <div>
                                <Checkbox
                                    status={currentStyles?.[symbolType]?.symbolSettings?.objectBorderActive}
                                    onChange={handleBordersCheckBox}
                                    dataTest="symbol-generator_border-checkbox"
                                >
                                    {intl.formatMessage(messages.borders)}
                                </Checkbox>
                            </div>
                            <div className={theme.objectBorderContainer}>
                                <div className={theme.objectInnerBorderContainer}>
                                    <div> {intl.formatMessage(messages.borderThickness)}</div>
                                    <div> {intl.formatMessage(messages.cornerRounding)}</div>
                                </div>
                                <div className={theme.borderObjectWrapper}>
                                    <SymbolBordersSelector
                                        symbolType={symbolType}
                                        disabled={!currentStyles?.[symbolType]?.symbolSettings?.objectBorderActive}
                                        toggleMenu={noop}
                                        open={false}
                                        menu={borderMenu}
                                        bordersVisibility="strokeWidthVisibility"
                                        bordersType="strokeWidth"
                                        currentStyles={currentStyles}
                                        setStyles={setCurrentStyles}
                                        dataTest="symbol-generator_border-width"
                                    />
                                    <SymbolBordersSelector
                                        symbolType={symbolType}
                                        disabled={
                                            !currentStyles?.[symbolType]?.symbolSettings?.objectBorderActive ||
                                            symbolType === 'CIRCLE'
                                        }
                                        toggleMenu={handleDropdownRoundingMenuToggle}
                                        open={dropdownRoundingMenuVisible}
                                        menu={roundingMenu}
                                        bordersVisibility="roundingVisibility"
                                        bordersType="rounding"
                                        currentStyles={currentStyles}
                                        setStyles={setCurrentStyles}
                                        onlyInteger
                                        maxValue={30}
                                        dataTest="symbol-generator_edge-rounding"
                                    />
                                </div>
                                <div className={theme.borderTypeAndColorWrapper}>
                                    <div className={theme.borderTypeWrapper} data-test="symbol-generator_border-color">
                                        {intl.formatMessage(messages.borderColor)}
                                        <SymbolGeneratorColorPicker
                                            color={currentStyles?.[symbolType]?.borders?.[0]?.color}
                                            style={{
                                                backgroundColor:
                                                    currentStyles?.[symbolType]?.borders?.[0]?.color || '#fff',
                                            }}
                                            onChange={handleChangeBorderColor}
                                        />
                                    </div>
                                    <div className={theme.borderTypeWrapper} data-test="symbol-generator_border-type">
                                        {intl.formatMessage(messages.borderType)}
                                        <Select
                                            disabled={!currentStyles?.[symbolType]?.symbolSettings?.objectBorderActive}
                                            value={currentStyles?.[symbolType]?.borders?.[0]?.type}
                                            onChange={handleChangeBorderStyle}
                                            style={{ marginLeft: '5px' }}
                                        >
                                            <Select.Option value="singleLine">
                                                <Icon className={theme.svgIconBorderTypes} spriteSymbol={singleLine} />
                                            </Select.Option>
                                            <Select.Option value="dashed">
                                                <Icon className={theme.svgIconBorderTypes} spriteSymbol={dashLine} />
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={theme.iconsWrapper}>
                            <Checkbox
                                status={currentStyles?.[symbolType]?.iconSettings?.iconEditActive}
                                onChange={handleIconCheckBox}
                                dataTest="symbol-generator_icon-checkbox"
                            >
                                {intl.formatMessage(messages.iconSvg)}
                            </Checkbox>
                            {currentStyles?.[symbolType]?.iconSettings?.iconEditActive ? (
                                <Upload.Dragger
                                    name="file"
                                    multiple={false}
                                    showUploadList={false}
                                    customRequest={uploadSvgIcon}
                                >
                                    <Icon className={theme.svgUploadActive} spriteSymbol={svgFolderActive} />
                                </Upload.Dragger>
                            ) : (
                                <Icon className={theme.svgUpload} spriteSymbol={svgFolder} />
                            )}
                            <div
                                style={{
                                    height: '30px',
                                    cursor: currentStyles?.[symbolType]?.iconSettings?.iconEditActive
                                        ? 'pointer'
                                        : 'unset',
                                }}
                                onClick={() => {
                                    if (currentStyles?.[symbolType]?.iconSettings?.iconEditActive) {
                                        setSvgModalVisible(true);
                                    }
                                }}
                                data-test="symbol-generator_select-icon-btn"
                            >
                                <Icon className={theme.iconLibBtn} spriteSymbol={folder} />
                            </div>
                        </div>
                        <div className={errorBorderActive ? theme.errorIconContainer : theme.mainIconContainer}>
                            <div className={theme.iconsParametersInnerContainer}>
                                <ObjectParametersSelector
                                    editingAllowed={currentStyles?.[symbolType]?.iconSettings?.iconEditActive}
                                    value={currentStyles?.[symbolType]?.iconSettings?.iconWidth}
                                    parameterName={intl.formatMessage(messages.iconWidth)}
                                    onChange={handleChangeIconWidth}
                                    maxValue={currentStyles?.[symbolType]?.symbolSettings?.width}
                                    dataTest="symbol-generator_icon-width"
                                />
                                <ObjectParametersSelector
                                    editingAllowed={currentStyles?.[symbolType]?.iconSettings?.iconEditActive}
                                    value={currentStyles?.[symbolType]?.iconSettings?.iconHeight}
                                    parameterName={intl.formatMessage(messages.iconHeight)}
                                    onChange={handleChangeIconHeight}
                                    maxValue={currentStyles?.[symbolType]?.symbolSettings?.height}
                                    dataTest="symbol-generator_icon-height"
                                />
                            </div>
                            <div className={theme.iconPosition} data-test="symbol-generator_icon-position">
                                <div className={theme.iconPositionText}>
                                    {intl.formatMessage(messages.iconPosition)}
                                </div>
                                <Select
                                    disabled={!currentStyles?.[symbolType]?.iconSettings?.iconEditActive}
                                    defaultValue="right top"
                                    value={currentStyles?.[symbolType]?.iconSettings?.iconPosition}
                                    onChange={handleChangeIconPosition}
                                    style={{ marginLeft: '5px' }}
                                >
                                    <Select.Option value="right top">
                                        {intl.formatMessage(messages.rightTop)}
                                    </Select.Option>
                                    <Select.Option value="center">
                                        {intl.formatMessage(messages.centerTop)}
                                    </Select.Option>
                                    <Select.Option value="left top">
                                        {intl.formatMessage(messages.leftTop)}
                                    </Select.Option>
                                    <Select.Option value="middle right">
                                        {intl.formatMessage(messages.rightCenter)}
                                    </Select.Option>
                                    <Select.Option value="middle center">
                                        {intl.formatMessage(messages.center)}
                                    </Select.Option>
                                    <Select.Option value="left middle">
                                        {intl.formatMessage(messages.leftCenter)}
                                    </Select.Option>
                                    <Select.Option value="right bottom">
                                        {intl.formatMessage(messages.rightBottom)}
                                    </Select.Option>
                                    <Select.Option value="center bottom">
                                        {intl.formatMessage(messages.centerBottom)}
                                    </Select.Option>
                                    <Select.Option value="left bottom">
                                        {intl.formatMessage(messages.leftBottom)}
                                    </Select.Option>
                                </Select>
                            </div>
                            <div className={theme.iconPositionPadding}>
                                <div className={theme.iconPositionPaddingText}>
                                    {intl.formatMessage(messages.iconPadding)}
                                </div>
                                <div className={theme.iconPositionPaddingSettings}>
                                    <div className={theme.bottomBorderButton} data-disabled={!checkIndentActive(0, currentStyles, symbolType)}></div>
                                    <ObjectParametersSelector
                                        editingAllowed={
                                            checkIndentActive(0, currentStyles, symbolType) &&
                                            currentStyles?.[symbolType]?.iconSettings?.iconEditActive
                                        }
                                        parameterName=""
                                        value={currentStyles?.[symbolType]?.iconSettings?.indentFromBottom}
                                        onChange={(val) =>
                                            setIndentStyles(0, val, currentStyles, symbolType, setCurrentStyles)
                                        }
                                        maxValue={
                                            allIndentsActive || oppositeIndentsTopBottomActive
                                                ? Math.ceil(calcHeightBordersIndent / 2)
                                                : calcHeightBordersIndent
                                        }
                                        dataTest="symbol-generator_icon-bottom-offset"
                                    />
                                    <div className={theme.topBorderButton} data-disabled={!checkIndentActive(1, currentStyles, symbolType)}></div>
                                    <ObjectParametersSelector
                                        editingAllowed={
                                            checkIndentActive(1, currentStyles, symbolType) &&
                                            currentStyles?.[symbolType]?.iconSettings?.iconEditActive
                                        }
                                        parameterName=""
                                        value={currentStyles?.[symbolType]?.iconSettings?.indentFromTop}
                                        onChange={(val) =>
                                            setIndentStyles(1, val, currentStyles, symbolType, setCurrentStyles)
                                        }
                                        maxValue={
                                            allIndentsActive || oppositeIndentsTopBottomActive
                                                ? Math.ceil(calcHeightBordersIndent / 2)
                                                : calcHeightBordersIndent
                                        }
                                        dataTest="symbol-generator_icon-top-offset"
                                    />
                                    <div className={theme.leftBorderButton} data-disabled={!checkIndentActive(2, currentStyles, symbolType)}></div>
                                    <ObjectParametersSelector
                                        editingAllowed={
                                            checkIndentActive(2, currentStyles, symbolType) &&
                                            currentStyles?.[symbolType]?.iconSettings?.iconEditActive
                                        }
                                        parameterName=""
                                        value={currentStyles?.[symbolType]?.iconSettings?.indentFromLeft}
                                        onChange={(val) =>
                                            setIndentStyles(2, val, currentStyles, symbolType, setCurrentStyles)
                                        }
                                        maxValue={
                                            allIndentsActive || oppositeIndentsLeftRightActive
                                                ? Math.ceil(calcWidthBordersIndent / 2)
                                                : calcWidthBordersIndent
                                        }
                                        dataTest="symbol-generator_icon-left-offset"
                                    />
                                    <div className={theme.rightBorderButton} data-disabled={!checkIndentActive(3, currentStyles, symbolType)}></div>
                                    <ObjectParametersSelector
                                        editingAllowed={
                                            checkIndentActive(3, currentStyles, symbolType) &&
                                            currentStyles?.[symbolType]?.iconSettings?.iconEditActive
                                        }
                                        parameterName=""
                                        value={currentStyles?.[symbolType]?.iconSettings?.indentFromRight}
                                        onChange={(val) =>
                                            setIndentStyles(3, val, currentStyles, symbolType, setCurrentStyles)
                                        }
                                        maxValue={
                                            allIndentsActive || oppositeIndentsLeftRightActive
                                                ? Math.ceil(calcWidthBordersIndent / 2)
                                                : calcWidthBordersIndent
                                        }
                                        dataTest="symbol-generator_icon-right-offset"
                                    />
                                </div>
                            </div>
                            <div className={theme.iconScale} data-test="symbol-generator_icon-proportions">
                                <div className={theme.iconScaleText}>{intl.formatMessage(messages.iconScale)}</div>
                                <Select
                                    disabled={!currentStyles?.[symbolType]?.iconSettings?.iconEditActive}
                                    defaultValue="none"
                                    value={currentStyles?.[symbolType]?.iconSettings?.scale}
                                    onChange={handleIconScale}
                                >
                                    <Select.Option value="none">{intl.formatMessage(messages.none)}</Select.Option>
                                    <Select.Option value="relative">
                                        {intl.formatMessage(messages.relative)}
                                    </Select.Option>
                                    <Select.Option value="fixed">{intl.formatMessage(messages.fixed)}</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </FormGroup>
                {svgModalVisible && (
                    <GeneratorImageSelector
                        onSvgModalVisibleChange={modalVisibleChange}
                        onSaveSelectedIconToSymbolGenerator={saveSelectedIconToSymbolGenerator}
                        presetImages={presetImages}
                        presetId={presetId}
                        serverId={nodeId.serverId}
                    />
                )}
            </Modal>
        </div>
    );
};
