import { useIntl } from 'react-intl';
import { MxCell } from '../../../../mxgraph';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../FormatPanel.messages';
import typeMessages from '@/modules/MainMenu/messages/EdgeTypesName.messages';
import theme from './edgeStyles.scss';
import React, { useMemo } from 'react';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { TEdgeTypeSelectorState } from '../../../../models/edgeTypeSelectorState.types';
import {
    getGeneralMenuButtonsState,
    getGeneralMenuEdgeTypeSelectorState,
} from '../../../../selectors/generalMenu.selectors';
import { SequenceEdgeTypesId } from '../../../../mxgraph/SequenceGraph/SequenceConstants';
import { initialEdgeTypeSelectorState } from '../../../../models/edgeTypeSelectorState';
import { sortByEdgeTypeName } from '../../../AdminTools/Methodology/components/Presets/ModelType/utils/modelTypes.utils';
import icRibbonLineType from '@/resources/icons/toolbar/ic-edge-type.svg';
import {
    ICompactableSelectItemValue,
    TCompactableSelectItemType,
} from '../../../MainMenu/components/CompactableSelect/CompactableSelect.types';
import { changeEdgeType } from '../../../../actions/editor.actions';
import { EdgesForChangeSelectors } from '../../../../selectors/edgesForChangeType.selectors';
import { EdgeInstanceImpl } from '../../../../models/bpm/bpm-model-impl';
import { EditorMode } from '../../../../models/editorMode';
import { Icon } from '../../../UIKit';
import { ButtonEditLabelState } from '../../../../models/buttonEditLabelState';
import { EdgeType } from '../../../../serverapi/api';

type TEdgeTypeBlock = { isEntityEditable: boolean; selectedEdges: MxCell[]; isReadMode: boolean };

export const EdgeTypeBlock = (props: TEdgeTypeBlock) => {
    const { selectedEdges, isEntityEditable, isReadMode } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const edgeTypeSelectorState: TEdgeTypeSelectorState = useSelector(getGeneralMenuEdgeTypeSelectorState);
    const selectedEdgeInstances: EdgeInstanceImpl[] = selectedEdges.map((edge) => edge.getValue());
    const buttonEditLabelState: ButtonEditLabelState = useSelector(getGeneralMenuButtonsState);
    const hasAvailableTypes: boolean = edgeTypeSelectorState.availableTypes.length >= 1;
    const tooltipMessage: string = useSelector(
        EdgesForChangeSelectors.getTooltipMessage(selectedEdgeInstances, hasAvailableTypes, EditorMode.Edit),
    );
    const isEdgesBlocked: boolean = !!tooltipMessage;
    const edgeEditDisabled: boolean = !isEntityEditable;
    const visibleEdgeTypeSelectorState: TEdgeTypeSelectorState = useMemo(() => {
        const availableTypes = edgeTypeSelectorState.availableTypes.filter(
            (type) => type !== null && type.id !== SequenceEdgeTypesId.RECURSIVE_MESSAGE,
        );

        return {
            ...initialEdgeTypeSelectorState,
            ...edgeTypeSelectorState,
            availableTypes,
        };
    }, [edgeTypeSelectorState]);

    const createSelectItemsForEdgeTypes = (): ICompactableSelectItemValue<string>[] => {
        return sortByEdgeTypeName(visibleEdgeTypeSelectorState.availableTypes).reduce((acc, item) => {
            if (!item) return acc;

            let label;
            if (item.localizableName && typeMessages[item.localizableName]) {
                label = intl.formatMessage(typeMessages[item.localizableName]);
            } else {
                label = item.localizableName;
            }

            return [
                ...acc,
                {
                    type: TCompactableSelectItemType.Value,
                    value: item.id,
                    label,
                    disabled: false,
                },
            ];
        }, []);
    };

    const handleChangeEdgeType = (type: string) => {
        dispatch(changeEdgeType(type));
    };

    const disabled: boolean = edgeEditDisabled || isEdgesBlocked || !hasAvailableTypes || isReadMode;
    const edgeType: EdgeType | undefined = edgeTypeSelectorState.availableTypes.find(
        (type) => type.id === buttonEditLabelState.edgeTypeId,
    );
    const edgeTypeValue: JSX.Element = (
        <div className={theme.valueContainer}>
            <Icon spriteSymbol={icRibbonLineType} />
            <span className={theme.typeContainer}>{edgeType?.localizableName}</span>
        </div>
    );

    const getTooltip = (selectedEdgesLength: number, isReadMode: boolean, tooltipMessage: string): string => {
        if (isReadMode) return intl.formatMessage(messages.isReadMode);

        return selectedEdgesLength ? tooltipMessage : intl.formatMessage(messages.selectEdge);
    };

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.edgeType)}</div>
            <div data-test="toolbar_edge-type">
                <Select
                    onChange={handleChangeEdgeType}
                    value={edgeTypeValue}
                    disabled={disabled}
                    placeholder=""
                    originalTheme
                    tooltip={getTooltip(selectedEdges.length, isReadMode, tooltipMessage)}
                    data-test="format-toolbar_edge-type"
                >
                    {createSelectItemsForEdgeTypes().map((type) => {
                        return <Select.Option value={type.value} label={type.label} />;
                    })}
                </Select>
            </div>
        </div>
    );
};
